.componentAgenda {
    width: 100%;
    padding: 20px 0;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
}

/* CABEÇALHO */

.componentAgenda__header {
    width: 100%;
    margin-bottom: 16px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}
.componentAgenda__title {
    font-weight: 600;
    font-size: 15px;
    color: #373F47;
    display: block;
}
.componentAgenda__titleMore {
    font-weight: 700;
    font-size: 10px;
    color: #4F71A6;
    display: block;

    &:hover { color: #4F71A6; }
}
.componentAgenda__boxAgenda {
    width: 100%;
  // min-height: 259px;
    height: 289px;
    max-height: 289px;
    overflow-y: auto;
}

@media screen and (max-width: 991px) {
    .componentAgenda__boxAgenda {
        height: auto;
        max-height: none;
    }
}
.boxAgenda__list {
    padding: 0 30px 0 15px;

    li {
        width: 100%;
        padding: 9px 10px 8px;
        position: relative;

        & + li {
            margin-top: 18px;

            &:after {
                content: "";
                width: 100%;
                height: 1px;
                display: block;
                position: absolute;
                top: -10px;
                left: 0;
                background-color: #EAECEE;
            }
        }

        a {
            display: flex;
            align-items: flex-start;

            &:hover { text-decoration: none; }
        }

        strong {
            width: 16%;
            min-width: 100px;
            padding-right: 10px;
            font-weight: 700;
            font-size: 14px;
            color: #373F47;
        }

        span {
            width: 72%;
            padding-right: 10px;
            font-weight: 400;
            font-size: 14px;
            color: #373F47;
        }

        em {
            width: 12%;
            min-width: 60px;
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            color: #373F47;
        }

        &:hover {
            cursor: default;
            border-radius: 4px;
            background-color: #DFECF1;
        }
    }
}

@media screen and (max-width: 767px) {
    .componentAgenda__boxAgenda { max-height: none; }
}
@media screen and (max-width: 450px) {
    .componentAgenda__header { display: block; }
    .componentAgenda__title { margin-bottom: 5px; }
    .boxAgenda__list {
        li {
            a { flex-wrap: wrap; }
    
            strong {
                width: auto;
                min-width: auto;
                padding-bottom: 10px;
                order: 1;
            }
    
            span {
                width: 100%;
                padding-right: 0;
                order: 3;
            }
    
            em { order: 2; }
        }
    }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .componentAgenda__header { display: block; }
    .componentAgenda__title { margin-bottom: 5px; }
    .boxAgenda__list {
        li {
            a { flex-wrap: wrap; }
    
            strong {
                width: auto;
                min-width: auto;
                margin-bottom: 5px;
                order: 1;
            }
    
            span {
                width: 100%;
                padding-right: 0;
                order: 3;
            }
    
            em { order: 2; }
        }
    }

    .componentAgenda__boxAgenda {
        height: 269px;
        max-height: 269px;
    }

}

/* Container dos Eventos com as datas e o calendário*/
.eventos {
    //grid-row: 1/2;
    border-radius: 7px;
    padding: 20px 20px 40px 20px;
    height: 100%;

    .headetEvt {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;

        div:last-child {
            color: #4F71A6;
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    .eventos_bloco {
        display: flex;
        justify-content: space-around;
        padding-top: 10px;
        height: 90%;
    }

    .eventos_datas_bloco {
        width: 100%;
        color: #5C5D5D;
        font-size: 12px;
        padding: 0px 0px 0px 15px;

        .eventos_datas {
            display: flex;
            padding: 8px;
            font-weight: 600;
            justify-content: space-between;
            border-bottom: 1px solid #00000017;
            margin-bottom: 5px;

        }
    }
}

.controleErro {
    height: 80%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.boxMensagem__diario {
    padding: 25px 20px;
    color:  #777777;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: 1px solid red;
    img{
        width: 40%;
        min-width: 190px;
        max-width: 250px;
    }
    p {
      margin-bottom: 30px;
      max-width: 400px;
    }
}