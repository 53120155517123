.bodyContainer {
    position: relative;
}

.bodyWrapper {
    width: 100%;
    display: flex;
    overflow-x: hidden;
    background-color: #FBFBFB;
}

.boxForm__title {
    color: #4F5B67;
    font-size: 24px;
    margin-bottom: 26px;
}

.container {
    width: 100%;
    border: 1px solid #EAECEE;
    padding: 30px 20px;
    border-radius: 12px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 880px) {
    .container {
        display: block;
        padding: 20px;
    }

    .boxForm {
        width: 100vw;
        background: #fff;

        .boxForm__title {
            display: none;
        }
    }
}

/* FORMULÁRIO */

.boxForm {
    width: 100%;
    padding: 8px 20px;
}

@media screen and (max-width: 1038px) {
    .boxForm {
        padding: 8px 0;
    }

    .boxForm__info {
        flex-direction: column;
    }

    .boxForm__imgWrapper {
        margin: 0 0 20px 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.boxForm__info {
    display: flex;
}

.boxForm__imgWrapper {
    margin-right: 30px;
    position: relative;
    height: 92px;

    // overflow: hidden;
    & img {
        width: 92px;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;

        .boxForm {
            width: 100%;
            border-radius: 6px;
        }
    }
}

.boxForm__data {
    width: 100%;
    // border: 1px solid green;
    max-width: 590px;

    p {
        margin-bottom: 35px;
    }
}


.boxForm__info {
    &>svg {
        width: 50px;
    }

    :global(.react-international-phone-country-selector-button) {
        height: 48px;
        border: 1px solid #0000004D;
        background-color: #fff;
        padding: 0 8px;

        // &:focus,
        // &:hover { border: 1px solid #5D7ABA; }
    }

    input[type=tel] {
        width: 100%;
        height: 48px;
        font-size: 14px;
        border: 1px solid #0000004D;
        background-color: #fff;

        // &:focus,
        // &:hover { border: 1px solid #5D7ABA; }
    }
}

.boxPrimeiroAcesso__label {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    padding-bottom: 5px;
    // position: absolute;
    // top: 13px;
    // left: 10px;
    transition: font-size 200ms ease-in-out, top 200ms ease-in-out;
}

.fixarLabel {
    font-size: 12px;
    top: 0px;
    position: absolute;
}

.boxPrimeiroAcesso__form {
    .formRowSelect {
        border-radius: 4px;
        background: url("./img/svg/vector22.svg"), #F2F2F2;
        background-repeat: no-repeat;
        background-position: 97% 50%;

        &:hover {
            background: url("./img/svg/vector24.svg"), #F2F2F2;
            background-repeat: no-repeat;
            background-position: 97% 50%;
            cursor: pointer;
        }

        select {
            color: #000;
        }
    }
}

.erroEmptyCheckbox {
    border: 1px solid #C21700;
    padding: 15px 10px;

    label.boxPrimeiroAcesso__label_checkbox {
        color: #C21700;
    }
}

.boxPrimeiroAcesso__menuMobile {
    display: none;
}

@media screen and (max-width: 880px) {
    .boxPrimeiroAcesso__menuMobile {
        display: block;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        height: 48px;
        position: relative;
        max-width: 590px;
        border: 1px solid #b4b4b4;

        & select {
            position: absolute;
            background: url("./img/svg/vector22.svg"), #F2F2F2;
            background-repeat: no-repeat;
            background-position: 97% 50%;
            color: #4F5B67 !important;
            font-size: 18px !important;
            font-weight: 500;
            background-color: #fff;
        }

        &:hover {
            border: 1px solid #0000004D;
        }

        .formRow {
            & select:hover {
                border: none;
                background: url("./img/svg/vector24.svg"), #F2F2F2;
                background-repeat: no-repeat;
                background-position: 97% 50%;
                background-color: #fff;
                border-bottom: 1px solid;
            }
        }
    }

    .boxForm__imgWrapper {
        margin: 0 0 30px 0;
    }

    .boxPrimeiroAcesso__menuWrapper {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .boxPrimeiroAcesso__menuMobile {
        max-width: 590px;
    }
}

.formRow {
    width: 100%;
    position: relative;

    &+.formRow {
        margin-top: 25px;
    }

    .formRow__text {
        color: #4F5B67;
        font-size: 14px;
        margin-top: 30px;
    }

    .formRow__textTypeB {
        color: #4F5B67;
        font-size: 14px;
        margin-top: 20px;
    }

    span.errorMessage {
        margin: 5px 0 25px;
        margin-top: 10px;
        font-size: 14px;
        color: #C21700;
        display: block;
    }

    select.erroEmpty {
        background-color: #fddcdc;
        border: 1px solid #ff0000a7;
    }

    label.erroEmpty {
        color: #ff0000a7;
    }

    input.erroEmpty {
        background-color: #fddcdc;
        border: 1px solid #ff0000a7;

        &:hover {
            border: 1px solid #ff0000a7;
        }
    }

    .boxPrimeiroAcesso__radio {
        font-size: 12px;
        color: rgba(52, 58, 64, 0.62);
        margin: 0 24px 0 26px;

        &:hover {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 500px) {
        .boxPrimeiroAcesso__radio {
            margin: 0 14px 0 24px;
        }
    }

    select {
        height: 48px;
        width: 100%;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        background-color: rgba(0, 0, 0, 0.0);
        border-radius: 4px;
        border: none;
        padding: 0 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: border 50ms ease-in-out;
        cursor: pointer;

        &:focus,
        &:hover {
            border: 1px solid #0000004D;
        }
    }

    .formRow__genero {
        font-size: 12px;
        color: #00000066;
        display: block;
        margin-bottom: 6px;
    }

    input[type=checkbox] {
        cursor: pointer;
        appearance: none;
        position: relative;
        border: none;
    }

    input[type=checkbox]:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0px;
        left: 0;
        border: 2px solid #b4b4b4;
        background-color: #fff;
        border-radius: 5px;
    }

    input[type=checkbox]:checked:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0px;
        left: 0;
        background: #4040F2;
        border-radius: 5px;
        border: none;
    }

    input[type=checkbox]:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: 1px solid white;
        border-width: 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 7px;
    }

    .boxPrimeiroAcesso__inputText {
        width: 100%;
        height: 48px;
        padding: 0 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 48px;
        color: #00000099;
        border-radius: 4px;
        border: 1px solid #0000004D;
        background-color: #fff;
        transition: border 200ms ease-in-out;

        // &:focus,
        // &:hover { border: 1px solid #5D7ABA; }
    }

    .boxPrimeiroAcesso__row {
        display: flex;
        align-items: flex-start;
    }

    input[type=radio] {
        appearance: none;
        appearance: none;
        position: relative;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

    input[type=radio]:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0px;
        left: 0;
        border: 2px solid #b4b4b4;
        background-color: #fff;
        border-radius: 5px;
    }

    input[type=radio]:checked:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 0px;
        left: 0;
        background: #4040F2;
        border-radius: 5px;
        border: none;
    }

    input[type=radio]:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: 1px solid white;
        border-width: 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 7px;
    }
}

.formRow__date {
    .boxPrimeiroAcesso__inputDate {
        min-width: calc(100% - 20px);
    }
}

.formRow__divSubmit {
    position: relative;
    height: 80px;
    z-index: 4;

    p {
        color: rgba(52, 58, 64, 0.62);
        transform: translateX(-50%);
        position: absolute;
        bottom: 0;
        margin: 0;
        left: 50%;
        text-align: center;
        width: 100%;

        &::first-letter {
            text-transform: lowercase;
        }
    }
}

.boxPrimeiroAcesso__inputSubmit,
.disabledSubmit {
    width: 324px;
    height: 48px;
    z-index: 99;
    position: absolute;
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #fff;
    border-radius: 28px;
    border: 1px solid transparent;
    transition: color 200ms ease-in-out, background 200ms ease-in-out, border 200ms ease-in-out;

    & input {
        border-radius: 4px;
    }

}

.boxPrimeiroAcesso__inputSubmit {
    background-color: #FD541E;

    &:hover {
        color: #FD541E;
        border: 1px solid #FD541E;
        background-color: #fff;
    }
}

.disabledSubmit {
    background-color: #373f473d;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.boxPrimeiroAcesso__login {
    & a {
        transition: color 200ms ease-in-out, background 200ms ease-in-out, border 200ms ease-in-out;
        margin-top: 40px;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        line-height: 120%;
        border-radius: 4px;
        border: 1px solid transparent;
        background-color: #FD541E;
        color: #fff;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 400;
        text-decoration: none;
    }

    &:hover {
        a {
            color: #FD541E;
            cursor: pointer;
            border-radius: 4px;
            border: 1px solid #FD541E;
            background-color: #fff;
        }
    }
}

.fomRowCheckbox {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .boxPrimeiroAcesso__inputSubmit {
        width: 100%;
    }

    .boxForm__data {
        width: 100%;
    }
}

@media screen and (min-width: 670px) {
    .boxPrimeiroAcesso__inputSubmit {
        width: 100%;
    }

    .boxForm__data {
        width: 100%;
    }
}

/* Mensagem de erro */

.errorMessage {
    margin: 5px 0 25px;

    strong {
        font-weight: 700;
        font-size: 14px;
        color: #C21700;
        display: block;
    }
}

.boxPrimeiroAcesso__moreOptions {
    width: 100%;
    margin: 25px 0 26px;
    display: flex;
    justify-content: space-between;

    a,
    button {
        font-weight: 400;
        font-size: 10px;
        text-decoration: underline;
        color: #4F71A6;
        display: inline-block;
        cursor: pointer;
        background: none;

        &:hover {
            text-decoration: none;
            color: #4F71A6;
        }
    }
}

// MENU

.boxPrimeiroAcesso__menuWrapper {
    border-right: 1px solid #9DC9EA80;
    width: 20vw;
    min-width: 212px;
    margin-right: 20px;
}

.boxPrimeiroAcesso__list {
    li {
        margin-bottom: 26px;
    }

    span {
        color: #373F47;
        font-size: 14px;
        padding: 8px 16px;
        border-radius: 8px;
        border: 1px solid transparent;
        white-space: nowrap;
        transition: border 200ms ease-in-out, background-color 200ms ease-in-out, font-weight 200ms ease-in-out, color 200ms ease-in-out;

        &:hover {
            border: 1px solid #4040f23f;
            cursor: pointer;
        }
    }

    & .active {
        span {
            background-color: #F1F1F1;
            color: #4040F2;
            font-weight: 600;
        }
    }
}

.fixarLabel {
    position: absolute;
    top: 2px !important;
    font-size: 12px;
}

.inputText__email {
    padding: 0 10px;
    font-size: 14px;
    line-height: 48px;
    border-radius: 4px;
    background-color: #F2F2F2;
    border: 1px solid transparent;
    color: rgba(0, 0, 0, 0.4);
}


.loginLoading {
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 40%;
        right: 0;
        background-color: #ffffffda;
        transform: translate(0, -40%);
        z-index: 999;
    }

    .loader {
        position: absolute;
        display: block;
        z-index: 1000;
        top: 50%;
        right: 50%;

        .outer,
        .middle,
        .inner {
            border: 3px solid transparent;
            border-top-color: #004273;
            border-right-color: #004273;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
        }

        .outer {
            width: 3.5em;
            height: 3.5em;
            margin-left: -1.75em;
            margin-top: -1.75em;
            animation: spin 2s linear infinite;
        }

        .middle {
            width: 2.1em;
            height: 2.1em;
            margin-left: -1.05em;
            margin-top: -1.05em;
            animation: spin 1.75s linear reverse infinite;
        }

        .inner {
            width: 0.8em;
            height: 0.8em;
            margin-left: -0.4em;
            margin-top: -0.4em;
            animation: spin 1.5s linear infinite;
        }

        @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }

    }
}

.mensagemHidden {
    display: none;
}

// assinatura

.boxAssinatura {
    :global(.accordion) {
        --bs-accordion-border-radius: 0;
        --bs-accordion-inner-border-radius: 0;
        --bs-accordion-active-color: #4F71A6;
        --bs-accordion-btn-focus-border-color: transparent;
        --bs-accordion-btn-focus-box-shadow: none;
        --bs-accordion-bg: transparent;
    }

    :global(.accordion-button) {
        background-color: transparent;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
    }

    :global(.accordion-button:not(.collapsed)) {
        box-shadow: none;
    }

    :global(.accordion-button::after) {
        background-image: url(./img/svg/setaCima.svg);
        background-size: 15px;
        background-position: center;
        position: absolute;
        left: 310px;
    }

    :global(.accordion-item) {
        border: none;
        padding: 0px 0;
    }

    :global(.accordion-body) {
        padding: 14px 20px;
        background-color: #0C3059;
        border-radius: 8px;
        color: #fff;
        font-size: 14px;
        margin-top: 30px;
    }

    :global(.accordion-header) {
        background-color: #1B4677;
    }
}

.boxAssinatura__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    &>div {
        background-color: #FAFAFA;
        border-radius: 4px;
        padding: 8px 10px;

        // span {
        //     color: #373F47;

        //     &:last-child {
        //         color: #1E8A29;
        //         // color: #FFCF0F;
        //         font-weight: 500;
        //         margin-left: 15px;
        //     }
        // }

        .situationText {
            color: #373F47;
        }

        .statusAtivo {
            color: #1E8A29;
            font-weight: 500;
            margin-left: 15px;
        }

        .cancelamentoAgendado {
            color: #DBB10A;
            font-weight: 500;
            margin-left: 15px;
        }
    }
}

.boxAssinatura__mainBlue {
    position: relative;
    padding: 40px 16px 30px;
    background-color: #1B4677;
    border-radius: 8px 8px 0 0;
}

.boxAssinatura__mainGrey {
    position: relative;
    background-color: #F7F7FB;
    border: 1px solid #DFECF1;
    border-radius: 0 0 8px 8px;
    padding: 16px 16px 24px;
    color: #00204B;
    font-size: 14px;

    p {
        margin-bottom: 8px;
    }

    .boxAssinatura__pay {
        font-size: 16px;
    }

    ul {
        li {
            margin: 0 0 0 25px;
            padding: 0 0 0 4px;
            list-style: disc;
        }
    }
}

.boxAssinatura__valor,
.boxAssinatura__data {
    position: absolute;
    top: 12px;
    right: 30px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    align-items: flex-end;

    span {
        padding-bottom: 3px;
        font-size: 18px;

        &:last-child {
            font-size: 12px;
        }
    }

    time {
        padding-top: 10px;
        font-size: 12px;
    }
}

.boxAssinatura__valor {
    color: #fff;
}

.boxAssinatura__ul {
    padding: 0px 0 6px 10px;

    &>li {
        padding-top: 12px;
    }

    ul {
        padding-left: 24px;

        li {
            list-style: disc;
            padding-top: 6px;
        }
    }
}

@media screen and (max-width: 999px) {
    .boxAssinatura {
        :global(.accordion-button) {
            p {
                width: 180px;
            }
        }

        :global(.accordion-button::after) {
            left: 205px;
        }
    }
}

@media screen and (max-width: 450px) {
    .boxAssinatura {
        :global(.accordion-button) {
            padding: 16px;
        }
    }

    .boxAssinatura__valor {
        right: 16px;
    }
}

.boxAssinatura__cupom {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .numeroCartao {
        border: 1px solid #DFECF1;
        color: #343A40;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 190px;
    }

    .aplicarCupom {
        .boxCartoes {
            align-items: center;
            display: flex;
            padding-bottom: 15px;

            span {
                color: #1E8A29;
                font-weight: 500;
                margin-left: 20px;
            }

            .ativarCartao {
                margin-left: 20px;
                width: 105px;
            }
        }

        .cupomDesconto__Text {
            font-size: 14px;
            margin-bottom: 10px;
        }

        span {
            font-size: 14px;
        }

        .cupomAplicado {
            color: #1E8A29;
            font-size: 14px;
            padding-top: 15px;
        }

        .cupomInvalido {
            color: #DB302A;
            font-size: 14px;
            padding-top: 15px;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            gap: 5px;
        }

        label {
            color: #343A40;
            font-size: 15px;
        }

        input {
            background-color: #F7F6F6;
            border: 1px solid #DFECF1;
            padding: 0 12px;
            color: #343A40;
            border-radius: 4px;
            height: 50px;
            width: 190px;

            &::placeholder {
                text-align: center;
            }
        }

        button {
            margin-left: 20px;
            width: 70px;
            height: 30px;
            font-size: 13px;
            border-radius: 28px;
            color: #fafafa;
            background-color: #FD541E;
            border: 1px solid #FD541E;
            transition: background 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;

            &:hover {
                color: #FD541E;
                cursor: pointer;
                border-radius: 28px;
                border: 1px solid #FD541E;
                background-color: #fff;
            }
        }

        .cupomDisabled {
            input::placeholder {
                text-align: left;
            }

            .cupomDisabled__Text {
                color: #7d7d7d;
                padding-top: 10px;
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }

}

@media screen and (max-width: 473px) {
    .boxAssinatura {
        :global(.accordion-button) {
            height: 90px;

            p {
                margin-top: 20px;
                width: 100%;
            }
        }

        :global(.accordion-button::after) {
            left: 130px;
            top: 15px;
        }

        :global(.accordion-item) {
            padding: 0px 0;
        }

        :global(.accordion-body) {
            padding: 14px;
            margin-top: 40px;
        }
    }

    .boxAssinatura__valor,
    .boxAssinatura__data {
        top: 10px;
        left: 16px;
        flex-direction: row;
        align-items: center;

        span {
            font-size: 16px;

            &:last-child {
                margin: 4px 0 0 8px;
            }
        }

        time {
            margin: 4px 0 0 8px;
        }
    }

    .boxAssinatura__mainBlue {
        padding: 45px 16px 40px;
    }

    .boxAssinatura__mainGrey {
        padding: 40px 16px 20px;
    }

    .boxAssinatura__pay {
        padding-bottom: 6px;
    }
}

@media screen and (max-width: 360px) {
    .aplicarCupom {
        .cupomAbled {
            display: flex;
            flex-direction: column;

            button {
                margin: 14px 0 0 0;
            }
        }
    }
}

.boxAssinatura__cartao {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    margin-top: 30px;

    .boxAssinatura__options {
        max-width: 225px;
        flex-direction: column;
    }

    .boxAssinatura__cancel {
        display: flex;
        align-items: flex-end;
    }

    .boxAssinatura__novoCartao {
        background-color: transparent;
        color: #4F71A6;
        margin: 20px 0 0 0;
        text-decoration: none;
    }

    button {
        background-color: transparent;
        color: #4F71A6;
        margin: 20px 0 0 0;
    }
}

@media screen and (max-width: 1194px) {
    .boxAssinatura__cupom {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media screen and (max-width: 481px) {
    .boxAssinatura__cartao {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
    }

    .boxAssinatura__cupom {
        form {
            align-items: flex-start;
        }

        .numeroCartao {
            margin: 0px;
        }
    }
}

/*  MODAL CANCELAR ASSINATURA */
.modalCancelamento {
    max-width: 820px;
}

.header__Cancelamento {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #5D7ABA;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #0000001C;
    padding: 8px 4px 8px 16px;
}

.box__Textarea {
    color: #999999;
    border-radius: 6px;
    border: 1px solid #D6DADE;
    font-size: 12px;
    padding: 15px 0px 0px 15px;
    resize: none;
    width: 550px;
    height: 100%;
    margin: 0px 3px;
}

p.box_Subtitle,
label.box_Label {
    padding-bottom: 6px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    color: #373F47;
    margin-left: 3px;
}

.boxCancelamento__inputText {
    width: 100%;
    height: 35px;
    border: 1px solid #D6DADE;
    border-radius: 4px;
    font-size: 12px;
    padding: 0px 10px;
}

.footer__Cancelamento {
    width: 100%;
    padding: 25px 15px 35px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.button__Cancelamento {
    padding: 8px 35px;
    width: 95%;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    border-radius: 32px;
    border: 1px solid transparent;
    background-color: #FD541E;
    transition: background 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;

    &:hover {
        background-color: #CA451A;
    }

    path {
        transition: fill 200ms ease-in-out, stroke 200ms ease-in-out;
    }
}

.box__erro {
    color: #DB302A;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 14px;
    margin: 12px 0 0 0;
    padding: 0 20px;
}

.box__Textarea_erro {
    border: 1px solid rgba(255, 0, 0, 0.329);
}

.boxAlert__Monitoramento {
    margin: 0px 15px;

    .alertTxt {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 15px;
    }
}

.boxAlert__Cancelamento {
    color: #4F5B67;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 20px 35px;

    .modalConfirmacao__box {
        padding: 55px 35px 0px 35px;
    }

    .modalCancelamento__box {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;

        .cancelarButton {
            background-color: transparent;
            border: 1px solid #FD541E;
            color: #FD541E;
            border-radius: 32px;
            height: 40px;
            width: 162px;
        }
    }
}

@media screen and (max-width: 520px) {
    .boxAlert__Cancelamento {

        .modalConfirmacao__box {
            padding: 15px;
        }

    }
}

.modalBox__motivo {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 0px 20px 20px;
    margin: 0px 20px 20px;
}

@media screen and (max-width: 640px) {
    .boxAlert__Monitoramento {
        margin: 0px;
    }

    .box__Textarea {
        width: 100%;
    }
}

.modalConfirmacao__box {
    display: flex;
    gap: 150px;
    justify-content: space-between;

    .confirmarButton {
        background-color: #FD541E;
        border-radius: 32px;
        color: #FFF;
        height: 40px;
        width: 162px;

        &:hover {
            background-color: #FFF;
            border: 1px solid #FD541E;
            border-radius: 28px;
            color: #FD541E;
            cursor: pointer;
            transition: all 0.5s;
        }
    }

    .cancelarButton {
        background-color: transparent;
        border: 1px solid #FD541E;
        color: #FD541E;
        border-radius: 32px;
        height: 40px;
        width: 162px;
    }
}

@media screen and (max-width: 520px) {
    .modalConfirmacao__box {
        flex-wrap: wrap;
        gap: 25px;
        justify-content: center;
    }
}

/* ABA DE ASSINATURA SUSPENSA */

.boxAssinatura__payment {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    &>div {
        background-color: #FAFAFA;
        border-radius: 4px;
        padding: 8px 10px;

        span {
            color: #373F47;

            &:last-child {
                color: #C21700;
                font-weight: 500;
                margin-left: 15px;
            }
        }
    }
}

.boxAlert__payment {
    background-color: #FFDEDE80;
    border-radius: 4px;
    display: flex;
    gap: 15px;
    max-width: 628px;
    padding: 10px;
    margin-bottom: 45px;
}

.boxAlert__text {
    color: #1B4677;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding-bottom: 10px;
}

.boxAlert__description {
    color: #4F5B67;
    font-size: 16px;
    font-weight: 400px;
    line-height: 24px;
}

.buttonAlert__signature {
    background-color: #FD541E;
    border-radius: 28px;
    color: white;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 10px 24px;
    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

.listAlert {
    margin-top: 30px;
    max-width: 657px;

    // ul {
    //     li {
    //         color: #00204B;
    //         font-size: 14px;
    //         font-weight: 400;
    //         line-height: 150%;
    //         margin: 0 0 0 25px;
    //         padding: 0 0 0 4px;
    //         list-style: disc;
    //     }
    // }

    p {
        color: #00204B;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        // margin: 0 0 0 25px;
        padding: 0 0 0 4px;
        // list-style: disc;
    }
}