.modalBody {
    padding: 10px;


    .modalHeader {
        button {
            background-color: transparent;
            position: absolute;
            right: 12px;
        }

        p {
            color: #1B4677;
            font-weight: 500;
            font-size: 20px;
            text-align: center;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .modalImg {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modalInfo {
        display: flex;
        flex-direction: column;
        align-items: center;

        color: #1B4677;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        padding: 10px 0;

        button {
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            background-color: #FD541E;
            border-radius: 32px;
            color: #fff;
            transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out;
            padding: 13px 16px;
            width: 120px;

            margin-top: 15px;
        }
    }


    @media screen and (max-width: 450px) {
        .modalHeader p {
            font-size: 18px;
            padding-top: 12px;
        }
    }
}

.modalBodyRelatorio {
    .modalHeaderRelatorio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDEDED;

        p {
            color: #5D7ABA;
            font-weight: 300;
            font-size: 18px;
            padding: 15px;
        }
    }

    .modalInfoRelatorio {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid #EDEDED;

        button {
            font-weight: 500;
            font-size: 14px;
            text-align: center;
            background-color: #FD541E;
            border-radius: 32px;
            color: #fff;
            transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out;
            padding: 13px 16px;
            width: 180px;

            margin: 15px 0;
        }
    }

    .archiveTitle {
        font-size: 13px;
        margin: 0 25px;
    }

    .checkBoxAll {
        display: flex;
        margin: 0 15px;

        .boxCheckbox {
            padding: 5px;
            width: 185px;
            display: flex;
            align-items: center;
            margin: 30px 0;

            &>label {
                position: relative;
                border: none;
                color: #292929;
                font-size: 13px;
                text-align: left;
                justify-content: left;
                left: 30px;

                &:hover {
                    border: none;
                    box-shadow: none;
                }
            }

            input[type=checkbox] {
                cursor: pointer;
                appearance: none;
                position: relative;
                border: none;
                left: 30px;

                &:checked~label {
                    border: none;
                    box-shadow: none;
                }
            }

            input[type=checkbox]:before {
                content: "";
                display: block;
                position: absolute;
                width: 18px;
                height: 18px;
                top: 50%;
                transform: translateY(-50%);
                left: -25px;
                border: 1.5px solid #b4b4b4;
                background-color: #fff;
                border-radius: 3px;
            }

            input[type=checkbox]:checked:before {
                content: "";
                display: block;
                position: absolute;
                width: 18px;
                height: 18px;
                top: 50%;
                transform: translateY(-50%);
                left: -25px;
                background: #4040F2;
                border-radius: 3px;
                border: none;
            }

            input[type=checkbox]:checked:after {
                content: "";
                display: block;
                width: 5px;
                height: 10px;
                border: 1px solid white;
                border-width: 0 1.5px 1.5px 0;
                rotate: 45deg;
                -webkit-transform: translateY(calc(-50% - 2px));
                -ms-transform: translateY(calc(-50% - 2px));
                transform: translateY(calc(-50% - 2px));
                position: absolute;
                top: calc(50% - 2px);
                left: -23px;
            }
        }
    }
}

@media screen and (max-width: 667px) {
    .modalBodyRelatorio {
        .checkBoxAll {
            flex-wrap: wrap;

            .boxCheckbox {
                padding: 5px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }
}

.repassesContainer {
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    border-radius: 8px;
    background-color: #62D0E3A3;
    // background-image: url(./img/mascara.png);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 20px 0px 0 0;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1215px) {
        flex-direction: column;
        gap: 20px;
    }

    .repassesAside {
        background-color: #ffffff;
        border-radius: 8px;
        padding: 28px 22px;
        width: 247px;
        height: 611px;
        position: absolute;
        top: 40%;

    }

    @media screen and (max-width: 992px) {
        .repassesAside {
            width: 612px;
            height: auto;
            align-items: baseline;
        }
    }

    .repassesAsideInfo {
        color: #373F47;
        display: flex;
        flex-direction: column;
        align-items: baseline;

        .repassesAsideBox {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-bottom: 14px;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .repassesAsideTitle {
            font-size: 22px;
            font-weight: 500;
            padding-bottom: 16px;
        }
    }

    .repassesList {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        li {
            border-radius: 8px;
            background-color: #E2F1F8;
            height: 83px;
            width: 200px;
            padding: 8px;

            display: flex;
            align-items: center;
            gap: 9px;

            figcaption {
                color: #000000;

                span {
                    font-weight: 500;
                    font-size: 18px;
                }

                p {
                    font-size: 12px;
                    width: 140px;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        .repassesList {
            display: grid;
            grid-template-columns: 1fr 1fr;

            li {
                width: 100%;
            }
        }

    }


    @media screen and (max-width: 942px) {
        .repassesAside {
            width: auto;
        }

        .repassesList {
            li {
                width: auto;

                figcaption {
                    span {
                        font-size: 16px;
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }

        }
    }

    // @media screen and (max-width: 504px) {
    //     .repassesList {
    //         gap: 16px;

    //         li {
    //             width: 175px;

    //             img {
    //                 height: 40;
    //                 width: 40;
    //             }

    //             figcaption {
    //                 span {
    //                     font-size: 15px;
    //                 }
    //             }
    //         }
    //     }
    // }

    // @media screen and (max-width: 492px) {
    //     .repassesList {
    //         gap: 12px;

    //         li {
    //             img {
    //                 height: 35;
    //                 width: 35;
    //             }

    //             figcaption {
    //                 span {
    //                     font-size: 14px;
    //                 }

    //                 p {
    //                     font-size: 12px;
    //                 }
    //             }
    //         }
    //     }
    // }

    @media screen and (max-width: 544px) {
        .repassesList {
            gap: 12px;

            display: flex;
            flex-direction: column;

            li {
                width: 100%;

                img {
                    height: 35;
                    width: 35;
                }
            }
        }
    }
}

.repassesBottom {
    color: #184479;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
}

.repassesRightBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    right: 0;
    bottom: 0;
    align-items: flex-end;
    // border: 2px solid red;

    .repassesSelectRanking {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: 0;

        select {
            background-color: #FFFFFF;
            border: none;
            border-radius: 5px;
            color: #1B4677;
            cursor: pointer;
            font-size: 14px;
            padding: 8px 28px 8px 18px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            transition: border 300ms ease-in-out;
            // background: url(${setaIcon}) no-repeat calc(100% - 8px) #ffffff;
        }
    }

    .congressistasRanking {
        display: flex;
        flex-direction: column;
        justify-content: center;

        background-color: #FFFFFF;
        border-radius: 8px;
        height: 180px;
        width: 612px;
        padding: 10px 15px;

        button {
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            background-color: #FD541E;
            border-radius: 4px;
            color: #fff;
            transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out;
            padding: 5px 10px;
            width: 150px;

            &:hover {
                background-color: #e64b03;
            }
        }
    }

    .congressistasTitle {
        color: #1B4677;
        font-weight: 600;
        padding: 0 0 15px 0;
    }

    .congressistasList {
        display: flex;
        align-items: center;
        gap: 28px;

        li {
            display: flex;
            gap: 10px;

            figure {
                width: 60px;
                height: 60px;
                margin: 0 auto 18px;
                border-radius: 50%;
                border: 1px solid #9dc9ea94;
                overflow: hidden;
                background-color: #f6f6f6ed;

                img {
                    width: 100%;
                }
            }

            figureCaption {
                p:first-child {
                    background-color: #4040F2;
                    border-radius: 3px;
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 500;
                    width: 28px;
                    text-align: center;
                    margin-bottom: 0.15rem;
                }

                strong {
                    color: #1B4677;
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 0.15rem;
                }

                p:last-child {
                    color: #1B4677;
                    font-size: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 942px) {
        flex-direction: row;

        .congressistasRanking {
            width: auto;
            height: auto;
        }

        .congressistasList {
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
    }
}

.blurred {
    filter: blur(5px);
}

.repassesCharts {
	display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: opacity 300ms ease-in-out;
	
	@media screen and (max-width: 953px) {
    	flex-direction: column;
	}

    .chartBox {
        width: calc(50% - 10px);
        // width: calc(100%);
        margin-bottom: 20px;
        border: 1px solid #EAECEE;
        border-radius: 8px;
        background-color: #fff;
        margin: 20px 0;

        @media screen and (max-width: 1089px) {
            width: calc(50% - 5px);
            margin: 20px 0 0 0;
        }
        
        @media screen and (max-width: 953px) {
            width: 100%;
            margin-top: 20px;
        }
        
        @media screen and (max-width: 422px) {
            margin: 10px 0 20px 0;
        }
    }

    .chartHeader {
        header {
            width: 100%;
            margin-bottom: 12px;
            padding: 20px 0 0 20px;
            display: flex;
            font-size: 16px;
            justify-content: space-between;
    
            h3 {
                font-weight: 500;
                font-size: 18px;
                color: #373F47;
                display: block;
                padding-right: 10px;
            }
        }
    }

    .echartsBox {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .resizeChart {
        width: 100%;
        overflow-x: auto;
        white-space: nowrap; 
    }

    @media screen and (max-width: 820px) {
        .resizeChart {
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
        }
    }
}

// Container com mapa de mesorregião
.mesorregiaoContainer {
	width: 100%;
    padding: 20px 0 20px 20px;
    margin-bottom: 20px;
    border: 1px solid #EAECEE;
    border-radius: 8px;
    background: #fff;
    margin: 20px 0px 0 0;
    border: 2px solid red;

	header {
		width: 100%;
		margin-bottom: 12px;
		padding: 10px 20px 10px 0px;
		display: flex;
		font-size: 16px;
		justify-content: space-between;

		h3 {
			font-weight: 500;
			font-size: 18px;
			color: #373F47;
			display: block;
			padding-right: 10px;
		}
	}

    .mesorregiaoBox {
        display: flex;
        align-items: centers;
        justify-content: space-between;
        gap: 5px;
    
        @media screen and (max-width: 1100px) {
            flex-wrap: wrap;
            /* border: 6px solid blue; */
            width: 100%;
        }
    }
    
    .mesorregiaoChild {
        display: flex;
        align-items: center;
        gap: 45px;
        width: 50%;

        img {
           svg {
            width: 200px;
            height: 200px;
        }
          
        svg path, svg circle, svg rect {
            stroke-width: 1; /* Ajusta a espessura do traço */
            stroke: black; /* Cor do traço */
            fill: none; /* Remove preenchimento */
        }}
        
        ul {
            display: flex;
            flex-direction: column;
            gap: 10px;
    
            li {
                span {
                    color: #181894;
                    font-weight: 400;
                }
    
                p {
                    font-size: 12px;
                }
            }
        }
    }

    // Box com o gráfico de donut
    .donutChart {
        width: 50%;
    
        header {
            width: 100%;
            margin-bottom: 12px;
            padding: 10px 20px 10px 0px;
            display: flex;
            font-size: 16px;
            justify-content: space-between;
    
            h3 {
                font-weight: 500;
                font-size: 18px;
                color: #373F47;
                display: block;
                padding-right: 10px;
            }
        }
    }
}

.repassesFooter {
    p {
        color: #717D8A;
        font-size: 14px;
        padding-top: 20px;
    }
}