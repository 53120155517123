.link {
    text-decoration: none !important;
}

.container_InformacoesEleicao {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: opacity 300ms ease-in-out;

    .informacoesEleicao__buttons {
        margin: -6px;
        display: flex;
        flex-wrap: wrap;

        .informacoesEleicao__btn {
            padding: 6px;
        }
    }
}

@media screen and (max-width: 953px) {
    .container_InformacoesEleicao {
        flex-direction: column;
    }
}

.informacoesEleicao__buttons {
    width: 100%;
    padding: 20px 0 0 0;

    button {
        background-color: transparent;
        margin-bottom: 10px;
        border: 1px solid #9DC9EA;
        color: #4F71A6;
        font-size: 12px;
        border-radius: 4px;
        padding: 4px 16px;
        margin-right: 15px;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            background-color: #FD541E;
            color: #fff;
            border: 1px solid #FD541E;
        }
    }
}

.componentNoticias__boxNews {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.boxNews__new {
    width: calc(50% - 30px);
    display: inline-block;
    // display: flex;
    position: relative;
    padding: 30px 20px 25px;
    margin: 0 20px 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    border: 1px solid #EAECEE;
    background: #fff;
    border-radius: 8px;
}

.boxNews__newInfo {
    display: flex;
    padding: 10px 0 0 10px;
    text-decoration: none;
}

.boxNews__image {
    width: 240px;
    padding-right: 20px;
    display: block;
    flex-basis: 240px;
    flex-grow: 0;
    flex-shrink: 0;

    img {
        width: 100%;
        display: block;
    }
}

.boxNews__text {
    margin-top: -8px;
    width: 100%;

    span {
        font-weight: 400;
        font-size: 14px;
        color: #5C5D5D;
        line-height: 110%;

        &+span {
            margin-top: 15px;
        }
    }

    p {
        color: #5D7ABA;
        margin: 3px 0px 3px 0px;
        font-weight: 400;
        font-size: 12px;
    }

    .headerBox {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .listPosts__identificador {
        background-color: #fd451e;
        padding: 5px;
        width: 69px;
        margin: 0 0 0 20px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #fff;
        display: block;
        border-radius: 4px;
    }
}

.boxNews__tag {
    font-weight: 400;
    font-size: 12px;
    color: #FD541E;
    text-transform: uppercase;

    &:hover {
        color: #FD541E;
    }
}

.boxNews__title {
    margin: 6px 0 7px;
    font-weight: 500;
    font-size: 16px;
    color: #00204B;
    display: block;
}

.boxNews_btn {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    button {
        background-color: #fff;
        border: 1px solid #FD541E;
        color: #FD541E;
        border-radius: 4px;
        padding: 6px 20px;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

        &:hover {
            background-color: #FD541E;
            color: #fff;
        }
    }
}

.boxNews_Message {
    display: flex;
    justify-content: center;
    padding: 5px 0;

    span {
        color: #FD541E;
        font-weight: 4t00;
    }
}

@media screen and (max-width: 450px) {
    .boxNews__new {
        display: block;
    }

    .boxNews__image {
        width: 100%;
        margin-bottom: 24px;
        padding-right: 0;
    }
}

@media screen and (max-width: 530px) {
    .boxNews__new {
        width: 100%;
        margin: 0 0 20px 0;
    }
}

@media screen and (max-width: 1199px) {
    .boxNews__newInfo {
        display: block;
    }

    .boxNews__image {
        width: 100%;
        margin-bottom: 24px;
        padding-right: 0;
    }
}

/* ESQUELETO */

@keyframes load {
    0% {
        background-color: #dbdbdbb3;
    }

    50% {
        background-color: #efefefb3;
    }

    100% {
        background-color: #f6f6f6;
    }
}

.boxEsqueleto {
    padding: 20px 10px 10px 10px;
    overflow: hidden;
    margin: 0px auto;
    transition: all 1s 100ms ease-out;
    height: 703px;
}

.boxEsqueleto__blocoNoticias {
    padding: 10px;
    width: 100%;
}

.blocoNoticias__news {
    display: flex;
    margin: 0 0 20px 0;
}

.boxEsqueleto__blocoNoticiasHidden {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
}

@media screen and (max-width: 460px) {
    .blocoNoticias__news {
        flex-direction: column;

        .blocoNoticias__Img {
            height: 170px;
            width: 100%;
            max-width: 460px;
        }
    }

    .blocoNoticias__newsColumn {
        margin: 20px 0px 0px 0px !important;
    }

    .blocoNoticias__Txt70 {
        margin: 0 0 4px 0 !important;
    }

    .boxEsqueleto__blocoNoticiasHidden {
        max-height: 620px;
        overflow: hidden;
    }
}

.blocoNoticias__Img {
    height: 160px;
    width: 100%;
    min-width: 220px;
    max-width: 250px;
    border-radius: 4px;
    animation: load 700ms linear infinite alternate;
}

.blocoNoticias__newsColumn {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 10px;
    width: 100%;
}

.blocoNoticias__Txt {
    animation: load 700ms linear infinite alternate;
    border-radius: 3px;
}

.blocoNoticias__Txt100 {
    height: 12px;
    width: 100%;
    margin: 0 0 10px 0;
}

.blocoNoticias__Txt50 {
    height: 10px;
    width: 50%;
    margin: 0 0 20px 0;
}

.blocoNoticias__Txt20 {
    height: 10px;
    width: 20%;
    min-width: 100px;
    margin: 0 0 15px 0;
}

.blocoNoticias__Txt70 {
    height: 12px;
    width: 70%;
    margin: 0 0 20px 0;
}

.blocoNoticias__newsB {
    margin: 30px 0 40px 0;
}

// Agências de Notícias
.blocoDia {
    margin-top: 30px;
    display: grid;
    gap: 15px;
    font-size: 20px;
    color: #1B4677;
}

.listPosts2 {
    width: 80%;

    &+.listPosts2 {
        margin-top: 15px;
    }

    &>li {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #EAECEE;
        border-bottom: none;
        background: #fff;
        transition: border 200ms ease-in-out;

        &+li {
            margin-top: 15px;
        }

        &>a {
            text-decoration: none;
        }

        .listPosts__activeItem {
            border: 1px solid #1991EB;
            border-radius: 8px;
        }
    }

    &>div {
        &+div {
            margin-top: 15px;
        }
    }
}