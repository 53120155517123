/* INFORMAÇÕES GERAIS */
.boxInfos {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    &>li {
        width: 100%;
        border-radius: 8px;
        min-width: 290px;
        border: 1px solid #EAECEE;
        background: #fff;
    }
}

.boxInfos__header {
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #EAECEE;
}

.boxInfos__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}


/* INFORMAÇÕES */
.boxInfos__listInfo {
    padding: 20px;

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.logo {
    display: block;
    display: flex;
    justify-content: center;
    margin-right: 20px;

    img {
        max-width: 120px;
        max-height: 50px;
        display: block;
    }
}

/* PARTIDO */

.listInfos__Partido {
    display: block;

    .listInfos__PartidoDetails {
        font-size: 14px;
        color: black;

        .separador {
            display: flex;

            .partidoDadosTitle {
                display: block;
                font-weight: 600;
                width: 150px;
                padding-bottom: 10px;
            }

            .partidoDados {
                display: block;
                margin-right: 150px;
            }
        }
    }
}

/* CARDS*/

.boxCards {
    padding: 20px;
}

.boxCards__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;

    &>li {
        padding: 7px;
    }
}

.boxCards__card {
    width: 100%;
    min-width: 250px;
    padding: 24px 20px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    max-width: 400px;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: pink;
    }

}

.boxOpcoes__filtro {
    margin: -5px;
    display: flex;
    align-items: center;

    span {
        margin: 0 10px 0 5px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #000;
        display: block;
    }

    button {
        margin: 5px;
        padding: 4px 6px 3px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #7A7A7A;
        border-radius: 4px;
        border: 1px solid #9DC9EA;
        background-color: #fff;
        transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out;

        &:hover,
        &.active {
            color: #fff;
            border: 1px solid #FD541E;
            background-color: #FD541E;
        }
    }
}

.buttonPaginacao {
    padding: 0 20px 30px 20px;
}

// @media screen and (max-width:1218px) {
//     .separador {
//         flex-direction: column;
//     }
// }

@media screen and (max-width:931px) {
    .listInfos__PartidoDetails {
        flex-wrap: wrap;
    }

    .boxCards__card {
        width: 100%;
        max-width: 450px;
    }

    .boxCards__list {
        justify-items: center;
    }
}

@media screen and (max-width:471px) {
    .separador {
        margin-top: 10px;
        flex-direction: column;
    }
}

.boxCards__cardA:after {
    background-color: #005B9E;
}

.boxCards__cardB:after {
    background-color: #004A2F;
}

.card__avatar {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 18px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(157, 201, 234, 0.5803921569);
    background-color: #C4C4C4;

    img {
        width: 100%;
        display: block;
    }
}

.card__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__data {
    padding: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F5B67;
    display: block;
}

.card__link {
    margin: 12px 0 24px 0;
    display: flex;
    justify-content: center;

    a {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        color: #0C1116;
        display: inline-block;
        border-radius: 32px;
        border: 1px solid #EAECEE;
        background-color: #fff;
        transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: #fff;
            border: 1px solid #0C1116;
            background-color: #0C1116;
        }
    }
}