.componentPagination {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.componentPagination__text {
  padding: 0 30px;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #6E6EF7;
  display: block;
}
.componentPagination__button {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  border: none;
  background-color: #B4D6E4;

  &.disabled { 
    position: relative;
    background-color: #D9D9D9;
    cursor: default;
    // &::after{
    //   content: '';
    //   z-index: 9999;
    //   top: 0;
    //   left: 0;
    //   position: absolute;
    //   height: 36px;
    //   width: 36px;
    //  // background-color: rgba(255, 0, 0, 0.173);
    // }
  }

  & + .componentPagination__button { margin-left: 10px; }
}
.componentPagination__buttonRotate { transform: rotate(180deg); }