.boxPlanos__cardTypeB {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    max-width: 355px;

    .boxPlanos__list {
        margin: 14px 0 0 18px;

        li {
            padding-left: 6px;
            color: #1B4677;
            font-size: 16px;
            margin-bottom: 20px;
            line-height: 130%; 
        }
    }

    .boxPlanos__list {
        li {
           list-style: url(./img/check.svg);
        }
    }

    .boxPlanos__precoTypeB {
        align-items: center;
        color: #FD541E;
        font-weight: 400;
        display: flex;
        flex-direction: column;
    
        &>div {
            display: flex;
            align-items: center;
            padding: 18px 0 14px 0;
        }

        p {
            color: #FD541E;
            font-size: 16px;
            font-weight: 400;
            padding-right: 8px;

            &:last-child {
                padding-right: 0;
            }
        }
        .boxPlanos__number {
            font-size: 56px;
            line-height: 100%;
        }
    }

    .boxPlanos__contentB {
        font-size: 14px;
        color: #717D8A;
        padding: 16px 10px;
        line-height: 120%;

        strong {
            font-weight: 600;
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .boxPlanos__contentB {
        margin-bottom: 15px;
        border: 1px solid;
        border-color: #0000002c transparent #0000002c transparent;
    }

    .boxPlanos__contentTitle {
        margin-bottom: 12px;
        font-size: 16px;
        color: #1B4677;
    }

    .boxPlanos__btn,
    .boxPlanos__btnTypeB {
        background-color: #FD6633;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        width: 100%;
        display: block;
        text-align: center;
        padding: 8px;
        border-radius: 6px;
        margin-bottom: 18px;

        &:hover {
            text-decoration: none;
            background-color: #FD6633;
        }
    }

    .boxPlanos__btn {
        width: 100%;
    }

    .boxPlanos__btnTypeB {
        width: 220px;
        transition: background-color 300ms ease-in-out;

        &:hover {
            background-color: #e05526;
        }
    }

    .boxPlanos__link {
        color: #5D7ABA;
        font-size: 15px;
        font-weight: 300;
        display: block;
        text-align: center;
        margin-top: 20px;
        text-decoration: underline;
    }

    .boxPlanos__linkTypeB {
        padding: 10px 0 10px 0;
    }
}

.modalPro {
    position: relative;
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.modalPro__title {
    color: #FD541E !important;
    font-size: 32px !important;
    font-weight: 400;
    text-align: center;
    margin-top: 25px;
}

.modalProForm{
    p{
        line-height: 110%;
        font-weight: 500;
        color: #4f5b67;
        margin-top: 10px;
        text-align: center;
    }
}

.modalPro__close {
    position: sticky;
    padding-top: 20px;
    top: 0px;
    padding-right: 20px;
    z-index: 2;
    display: flex;
    justify-content: flex-end;

    svg:hover {
        cursor: pointer;
    }
}

@media screen and (max-width: 423px) {
    .modalPro {
        padding: 0 20px 20px 20px;
        margin-top: 0;
    }
    .modalPro__close {
        background-color: #fff;
        padding-top: 16px;
    }
}

.modalSucess {
    padding: 25px;
    
    text-align: center;
}
.modalPro__upgradeTitle {
    color: #1B4677;
    font-weight: 700;
    font-size: 24px;
    padding-top: 12px;
    padding-bottom: 25px;
}

.modalPro__upgradeInfo {
    color: #1B4677;
    font-size: 16px;
    padding-bottom: 15px;
}

.modalPro__erro {
    color: #1B4677;
}

.modalErro__title {
    color: #C21700;
    font-size: 20px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
}
.modalConfirmacao__box {
    display: flex;
    justify-content: space-around;
    .modalConfirmacao__button {
        font-size: 14px;
        background-color: #FD541E;
        border: 1px solid transparent;
        border-radius: 32px;
        color: #FFFFFF;
        padding: 13px 45px;
    }
}

.modalPro__upgradeText {
    color: #1B4677;
    font-size: 16px;
    padding-bottom: 35px;
}

.cancelarText {
    color: #1B4677;
    font-size: 15px;
}