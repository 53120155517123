.listPosts {
    margin: 30px 0 0;
    max-width: 80%;

    .listPosts__Box {
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;
        margin-top: 16px;

        &>a {
            text-decoration: none;
        }

        // +.listPosts__Box {
        //     margin-top: 16px;
        // }
    }
}

.listPosts__header {
    padding: 10px;
    border-bottom: 1px solid #EAECEE;
}

.listPosts__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}

.listPosts__btn {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    button {
        background-color: #fff;
        border: 1px solid #FD541E;
        color: #FD541E;
        border-radius: 4px;
        padding: 6px 20px;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

        &:hover {
            background-color: #FD541E;
            color: #fff;
        }
    }
}

/* IDENTIFICADOR TRAMITAÇÃO */
.identificador {
    padding: 4px 7px 3px;
    font-weight: 400;
    line-height: 100%;
    font-size: 12px;
    height: 35px;
    align-items: center;
    color: #fff;
    display: flex;
    border-radius: 4px;
}

.identificadorA {
    //Aprovado
    background-color: #44C13C;
}

.identificadorB {
    //Rejeitado
    background-color: #FF0000;
}

.identificadorC {
    //Senado
    background-color: #005B9E;
}

.identificadorD {
    //Camara
    background-color: #004A2F;
}

.identificadorE {
    //Em Tramitação
    background-color: #F89406;
}

.identificadorF {
    //Tramitação Encerrada
    background-color: #B02E05;
}

.identificadorG {
    //Congresso Nacional
    background-color: #203E7B;
}

.listPosts__main {
    width: 100%;
    padding: 0px 35px 10px 25px;
    position: relative;

    &.limited:after {
        content: "";
        width: 100%;
        height: 60px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(0deg, #FFFFFF 45%, rgba(239, 242, 247, 0) 100%);
        transition: opacity 400ms ease-in-out, z-index 0s 400ms ease-in-out;
    }

    a:hover {
        text-decoration: none;
    }

    &.opened {
        padding: 24px 20px 60px;

        &:after {
            height: 0;
            transition: height 400ms ease-in-out;
        }
    }

    .votacao__List {
        display: flex;
        position: relative;
        justify-content: space-between;
        border-bottom: 1px solid #EAECEE;

        &:last-child {
            margin-bottom: 28px;
        }

    }
}


.listPosts__listInfo {
    li {
        width: 100%;
        display: flex;

        &+li {
            margin-top: 10px;
        }

        strong {
            width: 135px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }

        p {
            width: 100%;
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }
    }
}

.listPosts__listInfoB {

    a {
        color: #2D9CDB !important;
    }

    &>ul {
        width: 100%;
        display: flex;
        flex-direction: column;

        &+li {
            margin-top: 10px;
        }

        &>li>strong {
            min-width: 120px;
            width: 120px;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }

        &>li>span {
            width: 100%;
            margin: 0;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }

    }

    & .listInfoB {
        display: flex;
        padding: 20px 0 10px 0;
        height: 100%;

        .listInfoB_Block {
            position: relative;
        }

        .iconPro {
            position: absolute;
            width: 39px;
            top: -9px;
            left: 30px;
            border: 1px solid #5d7abad7;
            border-radius: 15px;
            background: #5d7abad7;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
        }

        .listInfoB__AutoresName {
            display: flex;
            flex-direction: column;

            &>span {
                font-weight: 400;
                font-size: 12px;
                line-height: 150%;

                color: #A8B0B9;
            }

            &>strong {
                width: 135px;
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #4F5B67;
                display: block;
            }

        }

        .autores {
            &>span {
                display: block;
            }
        }

        &.identificadorVotoA {
            color: #689F38;
            position: absolute;
            top: 0;
            right: 0;
        }

        &.identificadorVotoB {
            color: #FD541E;
            position: absolute;
            top: 0;
            right: 0;
        }

        .descricaoVotoB {
            width: 100%;
        }


    }

    .listInfoB__space {
        padding: 5px 0 5px 0;
    }

    & .listInfoB__Border {
        border-bottom: 1px solid #9DC9EA80;
    }
}

.container__balao {
    width: 90%;
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    border-left: 1px solid #9dcadf;
    border-image: linear-gradient(to bottom, #9dcadf00, rgb(157, 202, 223) 150px, rgb(157, 202, 223) 55%, rgb(157, 202, 223), #9dcadf00) 60;
}

.balao {
    padding: 20px;
    border: 1px solid #9dc9ea8e;
    border-radius: 10px;
    margin-top: 18px;
    margin-left: 30px;
    margin-bottom: 40px;
    position: relative;
}

.balao::after {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    left: -8px;
    top: 25px;
    background-color: white;
    border: 1px solid;
    border-color: transparent transparent #9dc9ea8e #9dc9ea8e;
    transform: rotate(45deg);
}

.ball__check {
    width: 25px;
    height: 25px;
    top: 22px;
    left: -44px;
    position: absolute;
    background-color: #06BE68;
    border-radius: 100%;
    z-index: 1;
}

.ball__check::after {
    content: "";
    width: 13px;
    height: 8px;
    top: 6px;
    left: 6px;
    display: block;
    position: absolute;
    align-items: center;
    border: 2px solid;
    border-color: #FFFFFF #FFFFFF transparent transparent;
    transform: rotate(140deg);
}


@media screen and (max-width: 970px) {
    .listPosts {
        width: 100%;
    }

    .listPosts__listInfoB {
        .boxSwiper {
            width: 70%;
        }
    }

}

@media screen and (max-width: 800px) {
    .listPosts__listInfo {
        li {
            display: block;

            +li {
                margin-top: 20px;
            }

            &>strong {
                width: 100%;
            }

            &>span {
                width: 100%;
            }
        }
    }

    .listPosts__header {
        display: block;

        .listPosts__title {
            width: 100%;
        }
    }

    .listPosts__identificador {
        margin-top: 6px;
        display: inline-block;
    }

    .listPosts {
        max-width: 100%;
    }

    .listPosts__listInfoB {
        &>ul>li {
            flex-wrap: wrap;

            &>strong {
                margin-bottom: 10px;
            }

            .boxSwiper {
                width: 100%;
            }
        }
    }
}

/* Swiper */

.boxSwiper {
    display: flex;
    justify-content: flex-start;
    text-align: center;
    width: calc(100% - 140px);
    height: 100%;
    gap: 20px;
}

.swiperBtnNext,
.swiperBtnPrev {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 999;
    top: 0;
    padding: 5px;

    svg {
        width: 15px;
        position: absolute;
        top: 0;
    }
}

.swiperBtnPrev {
    left: 0;

    svg {
        left: 0;
        cursor: pointer;
    }
}

.swiperBtnNext {
    right: 0;

    svg {
        right: 0;
        cursor: pointer;
    }
}

.bold {
    font-weight: 600 !important;
}

.listInfoB__space {
    margin-bottom: 12px;
}

.listInfoC {
    display: flex;
    flex-direction: column;

    span {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 8px;
        color: #4F5B67;
    }

    a {
        margin-top: 8px;
    }
}