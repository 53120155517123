/* LISTA DE POSTS */

.listPosts {
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > li {
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;

        & > a { text-decoration: none; }
    }
}

@media screen and (max-width: 1199px) {
    .listPosts { grid-template-columns: 1fr; }
}


.listPosts__header {
    padding: 18px 20px 14px;
    border-bottom: 1px solid #EAECEE;

    a {
        display: flex;
        justify-content: space-between;

        &:hover { text-decoration: none; }
    }
}

.listPosts__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}

.listPosts__identificador {
    padding: 4px 7px 3px;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    color: #fff;
    display: block;
    border-radius: 4px;
    background-color: #004A2F;
}

.listPosts__identificadorA { background-color: #5D7ABA; }
.listPosts__identificadorB { background-color: #005B9E; }
.listPosts__identificadorC { background-color: #6E6EF7; }
.listPosts__identificadorD { background-color: #44C13C; }
.listPosts__identificadorE { background-color: #FF0000; }

.buttonFix {
    padding-right: 25px;
    font-weight: 700;
    font-size: 10px;
    line-height: 21px;
    text-align: right;
    text-transform: uppercase;
    color: #5C5D5D;
    position: absolute;
    top: 5px;
    right: 150px;
    border: none;
    background: none;
    transition: color 200ms ease-in-out;

    svg {
        position: absolute;
        top: 3px;
        right: 0;

        path { transition: fill 200ms ease-in-out;  }
    }

    &.active {
        color: #1991EB;

        path { fill: #1991EB; }

        .buttonFix__backgroundPin { fill: #fff; }
    }
}

@media screen and (max-width: 767px) {
    .listPosts__header {
        a { display: block; }
    }

    .listPosts__identificador {
        margin-top: 5px;
        display: inline-block;
    }
}

.listPosts__main {
    width: 100%;
    padding: 30px 20px 25px;
    position: relative;

    &.limited:after {
        content: "";
        width: 100%;
        height: 60px;
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(0deg, #FFFFFF 45%, rgba(239, 242, 247, 0) 100%);
        transition: opacity 400ms ease-in-out, z-index 0s 400ms ease-in-out;
    }

    a:hover {
        text-decoration: none;
    }

    &.opened {
        padding: 24px 20px 60px;

        &:after {
            height: 0;
            transition: height 400ms ease-in-out;
        }
    }
}

/* BOTÃO ACOMPANHAR */

.formFollow {
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: 2;

    label {
        padding: 0 35px 0 0;
        font-weight: 700;
        font-size: 12px;
        text-align: right;
        text-transform: uppercase;
        color: #5C5D5D;
        cursor: pointer;
        display: block;
        position: relative;

        &:after {
            content: "";
            width: 9px;
            height: 9px;
            display: block;
            position: absolute;
            top: 4px;
            right: 10px;
            border-radius: 50%;
            background-color: #fff;
            z-index: 4;
            transition: transform 400ms ease-in-out;
        }

        &:before {
            content: "";
            width: 20px;
            height: 11px;
            display: block;
            position: absolute;
            top: 3px;
            right: 0;
            border-radius: 10px;
            background-color: #A8B0B9;
            z-index: 2;
            transition: background 400ms ease-in-out;
        }
    }

    input[type=checkbox] {
        position: absolute;
        left: -99999px;
        z-index: 2;

        &:checked~label {
            &:after {
                transform: translate(9px, 0);
            }

            &:before {
                background-color: #1991EB;
            }
        }
    }
}

.listPosts__moreInfo {
    padding: 5px 15px;
    font-weight: 400;
    font-size: 12px;
    color: #FAFAFA;
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translate(-50%, 0);
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: #FD541E;
    z-index: 4;
    transition: color 200ms ease-in-out, border 200ms ease-in-out, background 200ms ease-in-out;

    &:hover {
        background-color: #CA451A;
    }
}

.listPosts__listInfo {
    li {
        width: 100%;
        display: flex;

        &+li { margin-top: 10px; }

        strong {
            width: 100px;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }

        p {
            width: calc(100% - 100px);
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }
    }
}

@media screen and (max-width: 767px) {
    .listPosts__listInfo {
        li {
            display: block;

            +li { margin-top: 20px; }

            strong {
                width: 100%;
                margin-bottom: 10px;
            }

            p { width: 100%; }
        }
    }
}

.listPosts__footer {
    padding: 18px 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #EAECEE;
}

@media screen and (max-width: 767px) {
    .listPosts__footer { display: block; }
}

.listPosts__listTags {
    margin: -5px 5px -5px -5px;
    display: flex;
    flex-wrap: wrap;

    li {
        padding: 5px;
    }

    a {
        padding: 3px 5px 2px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #FD541E;
        display: block;
        border-radius: 4px;
        border: 1px solid #FD541E;
        background-color: #fff;
        transition: color 200ms ease-in-out, background 200ms ease-in-out;

        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #FD541E;
        }
    }
}

.listPosts__download {
    margin: 0 -5px;
    display: flex;

    a {
        margin: 0 5px;
        padding: 5px 20px 5px 7px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #fff;
        display: block;
        position: relative;
        border-radius: 4px;
        background-color: #FD541E;
        transition: color 200ms ease-in-out, background 200ms ease-in-out;

        &:after {
            content: "";
            width: 10px;
            height: 9px;
            display: block;
            position: absolute;
            top: 8px;
            right: 6px;
            //background: url(./img/ico/seta_baixo.svg) no-repeat;
        }

        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #CA451A;
        }
    }
}

@media screen and (max-width: 767px) {
    .listPosts__download {
        margin: 10px -5px 0 -5px;
    }
}

.boxFormFilter {
    width: 100%;
    margin-top: 20px;

    label {
        margin-right: 10px;
        display: inline-block;
    }
}

.boxFilter{
    display: flex;
    justify-content: space-between;
}