.boxSingle__boxTitle {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(157, 202, 223, 0.5);
}

.boxSingle__title {
    padding-right: 10px;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    color: #1B4677;
    flex-direction: column;
}

.poderDrive__calendar {
    position: relative;
    z-index: 4;
}

.poderDrive__content {
    position: relative;
    z-index: 2;
    background-color: #f6f6f6;
    border-left: 1px solid #EAECEE;
    border-right: 1px solid #EAECEE;
    border-bottom: 1px solid #EAECEE;
    border-radius: 0px 0px 10px 10px;
}

@media screen and (max-width: 890px) {
    .poderDrive__content {
        background-color: #fff;
    }
}

.calendario {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.calendarioBox {
    background: #FFFFFF;
    border: 0.949664px solid #DFE3E9;
    box-shadow: 0px 0.949664px 3.79866px rgba(0, 0, 0, 0.08);
    height: 283px;
    width: 281.9px;
    text-align: center;
}

.parent__container,
.parent__containerModal,
.parent__containerModal_hidden {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #F7F7FB;
    border-bottom: 1px solid #EAECEE;
}

.parent__containerSelect {
    width: 100%;
    display: flex;
    padding: 16px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-top: 1px solid #EAECEE;
    border-bottom: 1px solid #EAECEE;
}

.poderDrive__contentModal {
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
}

@media screen and (max-width: 396px) {
    .poderDrive__contentModal {
        scale: (0.85);
        width: 110%;
        ;
        margin: -45px 0 0 -20px;
        max-height: 110vh;
    }
}

@media screen and (max-width: 356px) {
    .poderDrive__contentModal {
        scale: (0.75);
        width: 125%;
        margin: -70px 0 0 -45px;
    }
}

.parent__containerModal {
    top: 0px;
    position: sticky;
    z-index: 9999999;
    transition: translate ease-in-out 600ms;
}

.poderDrive_contentTop {
    top: -52px;
}

.poderDrive_contentDefault {
    top: 40px;
}

.scrollHidden {
    translate: 0 -200%;
}

.scrollShow {
    position: absolute;
    translate: 0;
}

.section__btn {
    font-weight: 400;
    font-size: 13.2953px;
    line-height: 20px;
    text-align: center;
    color: #5D7ABA;
    border: none;
    background: none;
}

.section__btn:disabled {
    pointer-events: none;
}

.section__btn__esqueleto:hover {
    transform: translateY(0px);
    box-shadow: rgba(0, 0, 0, 0) 0 0px 0px;
    cursor: default;
}

.section__btn:active {
    box-shadow: none;
    transform: translateY(0);
}

.child__container {
    width: 100%;

    button {
        width: 100%;
        padding: 10px;
        // flex-shrink: 1;
        white-space: nowrap;
    }

    .active {
        background-color: #D0E1E9;
        border-bottom: 1px solid #FD541E;
        transition: background-color ease-in-out 200ms, border-bottom ease-in-out 200ms;
    }
}

.child__containerDisable {
    button {
        color: #cacaca;
    }
}

.calendarBox {
    font-size: 22px;
}

.child__containerButtons {
    width: 100%;
    max-width: 600px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    text-align: center;
}

@media screen and (max-width: 400px) {

    .parent__container {
        display: block;

        .child__container {
            width: 100%;

            button {
                width: 100%;
            }
        }
    }
}

/* CARREGAMENTO */
.postCarregando {
    text-align: center;
    color: #1B4677;

    .postH1 {
        padding-bottom: 40px;
    }
}

.poderDrive__wrapper {
    width: 100%;
    overflow-x: auto;
    max-height: 72vh;
    overflow-y: auto;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.poderDrive__wrapperModal {
    width: 100%;
    position: relative;
}

.poderDrive__wrapper {
    @media screen and (max-width: 902px) {
        margin: -6.7vh 0 -60px -5vw;
        width: 115%;
        scale: (0.8);
        padding-bottom: 0px;
    }

    @media screen and (max-width: 818px) {
        margin: -8.7vh 0 -90px -9.5vw;
        width: 130%;
        scale: (0.74);
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        scale: (1);
        padding-bottom: 0px;
        margin: 0 auto;
    }

    @media screen and (max-width: 646px) {
        width: 120%;
        scale: (0.8);
        margin: -6.7vh 0 -65px -9.5vw;
    }

    @media screen and (max-width: 385px) {
        width: 140%;
        scale: (0.7);
        margin: -10.2vh 0 -100px -18vw;
    }

    @media screen and (max-width: 345px) {
        width: 147%;
        scale: (0.65);
        margin: -13.5vh 0 -150px -20.5vw;
        max-height: 80vh;
    }
}

/* BOX DE TEXTO DE CALENDÁRIO */

.boxCalendar {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: #fff;
    padding: 15px 0;
    border-radius: 10px 10px 0px 0px;
    border-top: 1px solid #EAECEE;
    border-left: 1px solid #EAECEE;
    border-right: 1px solid #EAECEE;
}

.boxCalendarText {
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: default;
    border: none;
    background: none;

    span {
        padding: 3px 15px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F71A6;
        cursor: pointer;
        display: block;
        border: 2px solid #EFF1F4;

        &.boxCalendarText__month {
            padding: 3px 40px;
        }
    }
}

/* NOVO CALENDÁRIO */

.boxNewCalendarBackground {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 9;
}

.boxNewCalendar {
    padding: 20px;
    display: none !important;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background-color: #fff;
    z-index: 99;

    ul {
        li {
            height: 40px;
            text-align: center;

            button {
                height: 100%;
                padding: 8px 20px;
                font-weight: 400;
                font-size: 14px;
                color: #A8B0B9;
                cursor: pointer;
                background: none;
                transition: font 200ms ease-in-out, color 200ms ease-in-out;
            }

            &.active {
                button {
                    font-weight: 700;
                    font-size: 16px;
                    color: #4F71A6;
                }
            }
        }
    }
}

.boxNewCalendar__boxes {
    display: flex;
    align-items: center;
}

.boxNewCalendar__box {
    position: relative;
    border-radius: 12px;
    background-color: #fff;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        border-radius: 12px;
        background: linear-gradient(0deg, rgba(0, 0, 0, .05) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.05) 100%);
    }

    &+.boxNewCalendar__box {
        margin-left: 10px;
    }
}

.boxNewCalendar__scroll {
    height: 200px;
    padding: 80px 0;
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;
    border-radius: 12px;
    border: 2px solid #EFF1F4;

    &::-webkit-scrollbar {
        display: none;
    }
}

.boxNewCalendar__block1,
.boxNewCalendar__block2 {
    width: 92%;
    height: 2px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4F71A6;
    z-index: 2;
}

.boxNewCalendar__block1 {
    top: calc(50% - 19px);
}

.boxNewCalendar__block2 {
    top: calc(50% + 23px);
}

.boxNewCalendar__date ul {
    width: 62px;
}

.boxNewCalendar__month ul {
    width: 120px;
}

.boxNewCalendar__year ul {
    width: 84px;
}

.boxNewCalendar__scrollMonth {
    position: relative;
}

.boxNewCalendar__submit {
    margin-left: 10px;

    input[type=submit] {
        padding: 9px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        border: 1px solid #FD541E;
        border-radius: 34px;
        background-color: #FD541E;
        transition: color 200ms ease-in-out, background 200ms ease-in-out;

        &:hover {
            color: #FD541E;
            background-color: #fff;
        }
    }
}

@media screen and (max-width: 450px) {
    .boxNewCalendar {
        display: block;
    }

    .boxNewCalendar__submit {
        margin: 0;

        input[type=submit] {
            width: 100%;
            margin-top: 10px;

            &:hover {
                color: #fff;
                background-color: #FD541E;
            }

            &:active {
                color: #FD541E;
                background-color: #fff;
            }
        }
    }
}

.boxMensagem__diario {
    padding: 25px 20px;
    color: #777777;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 40%;
        min-width: 190px;
        max-width: 250px;
    }

    p {
        margin-bottom: 30px;
        max-width: 700px;
    }
}

.poderDrive__expandir,
.poderDrive__expandido {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #4F71A6;
    color: #fff;
    font-size: 16px;
    box-shadow: 0px 3px 8px #00000030;

    & img {
        transform: scale(0.8);
    }
}

.poderDrive__expandir {
    display: none;
    position: fixed;
    bottom: 90px;
    z-index: 9999;
    right: 20px;
}

.poderDrive__expandido {
    position: sticky;
    bottom: 150px;
    left: 85%;
    opacity: 0.7;
}

@media screen and (max-width: 767px) {
    .poderDrive__expandir {
        display: block;
    }
}

.child__containerDesktopB {
    display: none;
}

.child__containerResponsive {
    display: none;

    select {
        height: 48px;
        width: 100%;
        font-size: 14px;
        color: #5d7aba;
        border: 1px solid #EAECEE;
        border-radius: 4px;
        padding: 0 32px 0 12px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: border 300ms ease-in-out;
        margin: 0 auto;
        background: url(./img/ico/seta.svg) no-repeat calc(100% - 8px) #fff;

        &:focus,
        &:hover {
            border: 1px solid #00000033;
        }
    }
}

.child__containerResponsiveB {
    display: flex;
}