.button1 {
	padding: 10px 20px;
	font-weight: 500;
	font-size: 14px;
	color: #FFFFFF;
	display: inline-block;
	border-radius: 32px;
    white-space: nowrap;
	border: 1px solid transparent;
	background-color: #FD541E;
	transition: background 200ms ease-in-out, color 200ms ease-in-out, border 200ms ease-in-out;

    &:hover { background-color: #CA451A; }

    path { transition: fill 200ms ease-in-out, stroke 200ms ease-in-out; }

    &.button1TipoSeta {
        padding: 10px 35px 10px 20px;
		position: relative;
        
		svg {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translate(0,-50%);
		}

        &:hover {
            path { stroke: #FD541E; }
        }
    }

    &.button1TipoSetaEsquerda {
        padding: 10px 20px 10px 35px;
		position: relative;
        
		svg {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translate(0,-50%) rotate(180deg);
		}
    }

    &.button1TipoMais {
        padding: 10px 35px 10px 20px;
		position: relative;
        
		svg {
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translate(0,-50%);
		}
    }

    &.modalBox__anterior {
        background-color: #A7A7A7;

        &:hover { background-color: #7d7c7c; }
    }

    // &.modalBox__proximo,
    // &.modalBox__anterior { margin-top: 25px; }   
}

@media screen and (max-width: 767px) {
    .boxTitle__button { display: none; }
}

/* SIDEBAR DE MONITORAMENTO */

.filterForm__submitButton { 
    width: 100%;
    max-width: 260px;
}



/* MODAL DE CONFIRMACAO */

.boxModalButton1 {
    padding-left: 50px;
    padding-right: 50px;
}

@media screen and (max-width: 767px){
    .boxTitle__buttonCriar{
        display: none !important;
    }
}

.boxTitle__buttonCriar{
    display: flex;
    height: 43px;
    align-items: center;
}

.button1__load{
    animation: loadEsqueleto 1.2s linear infinite alternate;
    transition: background-color 200ms ease-in-out;

    &:hover{
        background-color: #4d4d4d;
    }
}

@keyframes loadEsqueleto {
    0%{ background-color: #909090d3 }
    50%{ background-color: #8a8a8ae3 }
    100%{ background-color: #cccccc }
}