.componentMonitoramento__header {
    width: 100%;
    margin-bottom: 20px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
}

.componentMonitoramento__title {
    font-weight: 600;
    font-size: 15px;
    color: #373F47;
    display: block;
}
.boxFeed__header{
    border-bottom: 1px solid #EAECEE;
    padding: 6px 10px 16px 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .boxMonitoramento__atualizacoes{
        border: 1px solid #2BA52E;
        font-weight: 500;
        border-radius: 4px;
        color: #2BA52E;
        font-size: 13px;
        text-transform: uppercase;
        padding: 4px 8px;
    }
}
.boxMonitoramento__title {
    font-weight: 400;
    font-size: 15px;
    color: #4F5B67;
    display: block;
}
.componentMonitoramento__titleMore {
    font-weight: 700;
    font-size: 10px;
    color: #FD541E;
    display: block;

    &:hover {
        color: #FD541E;
    }
}
.boxMonitoramento{
    border: 1px solid #EAECEE;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
}

.componentMonitoramento__boxFeed {
    width: calc(100% - 320px);

    .componentMonitoramento__data{
        font-size: 14px;
        font-weight: 400;
        color: #4F5B67;
        margin: 6px 0;
        display: block;
    }
    .componentMonitoramento__data:first-child{
        margin: 25px 0 6px 0;
    }
    a{
        &:hover{
            text-decoration: none;
        }
    }
}

.componentMonitoramento__boxFeed_empty{
    width: 100%;
}

@media screen and (max-width: 970px){
    .componentMonitoramento__boxFeed { width: 100%; }
}

.boxFeed__main{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .boxFeed__mainContent{
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        min-width: 120px;
        border-right: 1px solid #f1f1f1;
        margin: 14px 0;
        padding: 0 10px;
        &:last-child{
            border-right: 0px solid transparent;
        }
        span{
            color: #A8B0B9;
            font-size: 14px;
            font-weight: 400;
          
            &.mainNovo{
                background-color: #2BA52E;
                border-radius: 4px;
                display: block;
                color: #fff;
                padding: 2px 8px;
                font-size: 12px;
                margin-top: 8px;
            }
        }
        strong{
            color: #373F47;
            font-size: 20px;
            font-weight: 400;
        }

        .boxFeed__new{
            color: #2BA52E;
            font-weight: 500;
            font-size: 16px;
        }
    }
}

.boxFeed__footer{
    border-top: 1px solid #EAECEE;
    padding: 16px 0 6px 0px;
}

.listPosts__listTags {
    margin: -4px 4px -4px -4px;
    display: flex;
    flex-wrap: wrap;

    li {
        padding: 3px 8px 2px;
        font-weight: 400;
        font-size: 12px;
        margin: 4px;
        text-align: center;
        color: #4F71A6;
        display: block;
        border-radius: 4px;
        border: 1px solid #9DC9EA;
        background-color: #fff;
        transition: color 200ms ease-in-out, background 200ms ease-in-out;
    
        &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #9DC9EA;
        }
    }
}

@media screen and (max-width: 767px) {
    .componentMonitoramento__boxFeed {
        max-height: none;
    }
}
@media screen and (max-width: 630px) {
    .boxFeed__main{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .boxFeed__mainContent{
            min-width: 120px;
            &:nth-child(even){ 
                border-right: 0px solid transparent;
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .boxFeed__main{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .boxFeed__mainContent{
            min-width: 120px;
            &:nth-child(even){ 
                border-right: 0px solid transparent;
            }
        }
    }
}

//Botão carregar mais
.boxNews_btn {
    display: flex;
    justify-content: center;
    padding: 20px 0;

    button {
        background-color: #fff;
        border: 1px solid #FD541E;
        color: #FD541E;
        border-radius: 4px;
        padding: 6px 20px;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

        &:hover {
            background-color: #FD541E;
            color: #fff;
        }
    }
}