/* BARRA DE BUSCA */
.boxFormSearch {
    width: 100%;
    max-width: 742px;
    margin-top: 10px;
    position: relative;

    input[type=text] {
        width: 100%;
        height: 42px;
        border-radius: 6px;
        font-size: 16px;
        color: #00000099;
        font-weight: 500;
        border: 1px solid #DBDBDB;
    }
}

.searchIcon {
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
}

.buscarHome { padding: 0 44px 0 12px; }

/* LISTA DE POSTS */

.listPosts {
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > li {
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;

        & > a { text-decoration: none; }
    }
}

@media screen and (max-width: 1199px) {
    .listPosts { grid-template-columns: 1fr; }
}