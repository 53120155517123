.boxProfile {
    width: 395px;
    height: calc(100% - 48px);
    padding: 15px 20px 40px;
    position: fixed;
    top: 48px;
    right: 0;
    overflow-y: auto;
    border-top: 1px solid #FD541E;
    background-color: #F7F7FB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    transform: translate(100%,0);
    transition: transform 200ms ease-in-out;

    &.active { transform: translate(0,0); }
}

@media screen and (max-width: 767px) {
    .boxProfile {
        top: 0;
        height: calc(100% - 63px);
    }
}
@media screen and (max-width: 420px) {
    .boxProfile { width: 280px; }
}
.boxProfile__scroll { width: 100%; }
.boxProfile__close {
    padding: 0 0 0 25px;
    font-weight: 400;
    font-size: 16px;
    color: #373F47;
    border: none;
    background: url(img/ico/close.svg) left center no-repeat;
}
.boxProfile__title {
    margin-top: 15px;
    font-weight: 500;
    font-size: 28px;
    color: #373F47;
    display: block;
}

/* FORMULÁRIO */

.formRow {
    width: 100%;
    position: relative;

    & + .formRow { margin-top: 17px; }
}
.formRowTypeB {
    margin-top: 30px;

    strong {
        font-weight: 400;
        font-size: 16px;
        color: #373F47;
        display: block;
    }

    & + .formRow { margin-top: 10px; }
}
.formRowTypeC { margin-top: 37px; }
.boxProfile__boxImage {
    width: 100%;
    margin-top: 19px;

    & + .formRow { margin-top: 7px; }
}
.boxProfile__boxInputFile {
    width: 171px;
    height: 171px;
    margin: 0 auto;
    position: relative;
}
.boxProfile__image {
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid rgba(0, 0, 0, 0.1);

    img {
        width: 100%;
        display: block;
    }    
}
.boxProfile__uploadButton {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: absolute;
    top: 10px;
    right: 17px;
    border-radius: 50%;
    background-color: #9DC9EA;
}
.boxProfile__inputFileLabel {
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50% 30% 50% 50%;
    z-index: 2;
}
.boxProfile__inputFile {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    opacity: 0;
}
.boxProfile__label {
    font-weight: 400;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.6);
    display: block;

    & + .boxProfile__inputText,
    & + .boxProfile__inputPass { margin-top: 10px; }
}
.boxProfile__inputText {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 48px;
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #fff;
}
.boxProfile__inputPass {
    width: 100%;
    height: 48px;
    padding: 0 10px;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 48px;
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #F2F2F2;
}
.boxProfile__inputSubmit {
    width: 100%;
    height: 48px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    color: #fff;
    border-radius: 34px;
    background-color: #FD541E;
}