/* INFORMAÇÕES DO TOPO */

.boxSingle__boxTitle {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(157, 202, 223, 0.5);

    &.boxSingle__boxTitleB {
        padding-bottom: 15px;
        display: block;
    }
}
.boxSingle__title {
    padding-right: 10px;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    color: #1B4677;
    display: block;
}

/* BARRA DE BUSCA */
.boxFormFilter {
    width: 100%;
    max-width: 742px;
    margin-top: 10px;
    position: relative;

    fieldset {
        display: flex;
        align-items: center;
        // flex-direction: column;
    }

    select {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #DBDBDB;
    }
}

/* NAVBAR */
.boxSelectPages {
    border-bottom: 1px solid #CCE2ED;
    padding-top: 25px;
    display: flex;

    button { background: none; }

    .boxSelectPages__link {
        color: #00000066;
        display: flex;
        padding: 6px 20px;
        font-size: 13px;
    }

    .boxSelectPages__linkSelected {
        color: #5D7ABA;
        position: relative;

        &:hover { cursor: pointer; }

        &:after {
            content: '';
            bottom: 0;
            left: 0;
            height: 3px;
            width: 100%;
            position: absolute;
            background-color: #FD541E;

        }
    }
}

/* LISTA DE POSTS */

.listPosts {
    margin: 20px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > li {
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;

        & > a { text-decoration: none; }
    }
}

@media screen and (max-width: 1199px) {
    .listPosts { grid-template-columns: 1fr; }
}

.esqueletoMobile { display: none; }

@media screen and (max-width: 1199px) {
  .esqueletoMobile { display: block; }
  .esqueletoResponsive{ display: none; }
}