/* INFORMAÇÕES GERAIS */
.boxInfos {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 25px;

    &>li {
        width: 100%;
        border-radius: 8px;
        min-width: 290px;
        border: 1px solid #EAECEE;
        background: #fff;
        padding-bottom: 15px;
    }
}

.boxInfos__header {
    padding: 10px;
    border-bottom: 1px solid #EAECEE;
}

.boxInfos__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}

.boxInfos__listInfo {
    padding: 20px;
    width: 100%;
}

.boxInfos__Details {
    display: block;
    font-size: 14px;
    color: black;

}

.separador {
    display: flex;

    .dadosTitle {
        display: block;
        width: 120px;
        margin-right: 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #4F5B67;
    }

    .dados {
        width: 100%;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #4F5B67;
        display: block;
        margin-bottom: 19px;
    }
}

.eventos__container__informacoes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
}

/* ABAS */
.boxSelectPages {
    border-bottom: 1px solid #CCE2ED;
    padding-top: 25px;
    display: flex;

    button {
        background: none;
    }

    .boxSelectPages__link {
        color: #00000066;
        display: flex;
        padding: 6px 20px;
        font-size: 13px;
    }

    .boxSelectPages__linkSelected {
        color: #5D7ABA;
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &:after {
            content: '';
            bottom: 0;
            left: 0;
            height: 3px;
            width: 100%;
            position: absolute;
            background-color: #FD541E;

        }
    }
}

/* CARDS */

.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.boxCards {
    padding: 20px;
}

.boxCards__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;


}

.boxCards__card {
    width: 100%;
    min-width: 250px;
    padding: 24px 20px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    // background-color: rgba(223, 236, 241, 0.5);;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        // background-color: pink;
    }
}

.boxCards__cardA:after {
    background-color: #005B9E;
}

.boxCards__cardB:after {
    background-color: #004A2F;
}

.boxCards__cardC:after {
    background-color: rgba(223, 236, 241, 0.5);
}
.boxCards{
    .boxCards__list{
        .boxCards__cardD{
            background-color: rgba(223, 236, 241, 0.5);
            a{ text-decoration: none; }
        }
    }
}

.boxCards__infos {
    display: flex;
    flex-direction: column;
}

.card__avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto 18px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #C4C4C4;
    border: 1.2px solid #9DCADF;

    &>img {
        width: 100%;
        display: block;
    }
}

.card__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__data {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F5B67;
    display: block;
}

.card__link {
    margin: 12px 0 24px 0;
    display: flex;
    justify-content: center;

    &>a {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        color: #0C1116;
        display: inline-block;
        border-radius: 32px;
        border: 1px solid #EAECEE;
        background-color: #fff;
        transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: #fff;
            border: 1px solid #0C1116;
            background-color: #0C1116;
        }
    }
}

/* EVENTOS */

.boxEventos__info {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EAECEE;
    overflow-x: hidden;
    margin-top: 10px;
    padding: 10px;
}

@media screen and (max-width: 420px) {
    .boxEventos__info {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.boxEventos__hora {
    display: flex;
    color: #5C5D5D;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    width: 130px;
    padding-left: 10px;
}

.boxEventos__descricao {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a {
        display: block;
    }
}

.boxEventos__btn {
    display: flex;
    flex-wrap: wrap;
    background-color: #D8D8FE;
    color: #181894;
    font-size: 14px;
    padding: 4px 12px 4px;
    border-radius: 12px;
    min-width: 180px;
    margin: 0 10px 6px 0;
    transition: background-color 200ms ease-in-out;

    &:hover {
        background-color: #c8c8f3;
    }
}

.eventoBefore {
    &::before {
        content: " ";
        top: 0;
        left: -30px;
        position: absolute;
        background-color: transparent;
        height: 100%;
        width: 100vw;
    }
}

@media screen and (max-width: 530px) {
    .boxSelectPages_nav {
        padding: 0 0 15px 0;
        overflow-x: scroll;
    }


    .boxSelectPages {
        width: 250%;
    }

    .boxSelectPages__link {
        min-width: 142px;
        white-space: nowrap;
    }
}

.textStyle__bold{
    font-weight: 600 !important;
}