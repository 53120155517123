/* BARRA DE BUSCA */
.boxFormSearch {
    width: 100%;
    max-width: 742px;
    position: relative;
    margin-top: 10px;

    input[type=text] {
        width: 100%;
        height: 42px;
        border-radius: 6px;
        font-size: 16px;
        color: #00000099;
        font-weight: 500;
        border: 1px solid #DBDBDB;
    }
}

.searchIcon {
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
}

input::placeholder { color: #A8B0B9; }

.buscarHome {
    color: #A8B0B9;
    padding: 0 44px 0 12px;
    font-size: 14px;
}

.boxCards {
    width: 100%;
    margin-top: 25px;
}

.boxCards__list {
    margin: -7px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 15px;
    & > li { padding: 7px; }
}

.cardsPartidos {
    border-radius: 12px;
    border: 1px solid #EAECEE;    
    padding-top: 10%;
    padding-bottom: 10%;
    text-align: center;
    background-color: #fff
}

.card__avatarContainer{
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card__avatar {
    display: flex;
    justify-content: center;
    img {
        max-width: 120px;
        max-height: 50px;
        display: block;
    }
}

.card__name {
    margin: 12px 0;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__list {
    width: 100%;
    margin-top: 21px;
    display: grid;
    grid-auto-flow: column;
    margin: 0 auto;
    max-width: 400px;

    li {
        & + li { border-left: 1px solid #F1F1F1; }

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #A8B0B9;
            display: block;
        }

        strong {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #373F47;
            display: block;
        }
    }
}

.card__link {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    a {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        color: #0C1116;
        display: inline-block;
        border-radius: 32px;
        border: 1px solid #EAECEE;
        background-color: #fff;
        transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: #fff;
            border: 1px solid #0C1116;
            background-color: #0C1116;
        }
    }
}