.cls_1 {
    fill: #e90089;
}

.cls_2 {
    fill: #dc3179;
}

.cls_3 {
    fill: #184479;
}

.componentNumbers {
    margin: -10px -10px -20px;
    display: flex;
    flex-wrap: wrap;

    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.componentNumbers__collumn {
    width: 25%;
    padding: 10px;
}

@media screen and (min-width: 2000px) {
    .componentNumbers__collumn {
        .componentNumbers__boxNumbers {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 130px;
            padding: 16px;
        }

        .componentNumbers__boxNumbersInfo {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #373F47;
            height: 104px;
            justify-content: space-between;
        }

        .boxNumbersDrive {
            margin-top: 0px;
        }
    }

}

.componentNumbers__boxNumbers {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    padding: 22px 16px 12px 16px;
    height: 140px;

    nav {
        margin-top: 7px;
    }

    p,
    .newsText {
        padding: 4px 0 0 0;
        font-size: 13px;
        line-height: 110%;
        color: #717D8A;
    }
}

.componentNumbers__boxNumbersB {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #1B4677;
    padding: 22px 16px 12px 16px;
    height: 140px;

    nav {
        margin-top: 7px;
    }

    p,
    .newsText {
        padding: 4px 0 0 0;
        font-size: 13px;
        line-height: 110%;
        color: #717D8A;
    }
}

.boxPoderDriveWhite {
    display: flex;
    justify-content: space-between;

    &>.poderDriveBox {
        &>.driveButton {
            height: 25px;
        }
    }
}

.poderDriveBox {
    display: flex;
    margin-top: 15px;

    .newsText {
        color: #ffffff;
        line-height: 120%;
        font-size: 12px;
        font-weight: 500;
        max-width: 145px;
    }

    .driveButton {
        color: #ffffff;
        background-color: #FD541E;
        border-radius: 45px;
        font-size: 12px;
        font-weight: 500;
        margin-left: 10px;
        padding: 6px, 10px, 6px, 10px;
        width: 88px;
    }
}


@media screen and (max-width: 390px) {
    .poderDriveBox {
        flex-direction: column;
        align-items: center;

        .newsText {
            font-size: 11px;
        }

        .driveButton {
            margin-bottom: 8px;
        }
    }
}

@media screen and (max-width: 768px) {
    .poderDriveBox {
        flex-direction: column;
        align-items: center;
    }

    .driveButton {
        margin: 8px;
        height: 20px;
    }
}

@media screen and (max-width: 1300px) {
    .poderDriveBox {
        flex-direction: column;
        align-items: center;

        .driveButton {
            margin: 6px;
            height: 25px;
        }

        .driveText {
            color: red;
        }
    }
}

@media screen and (max-width: 1400px) {
    .boxPoderDriveWhite {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1600px) {
    .poderDriveBox {
        display: flex;
        justify-content: space-between;

        .driveButton {
            margin: 6px;
            height: 25px;
        }

        .driveText {
            color: red;
        }
    }
}

@media screen and (max-width: 1720px) {
    .poderDriveBox {
        display: flex;
        justify-content: space-between;

        .driveButton {
            margin: 6px;
            height: 25px;
        }

        .driveText {
            color: red;
        }
    }
}

@media screen and (max-width: 1800px) {
    .poderDriveBox {
        display: flex;
        justify-content: space-between;

        .driveButton {
            margin: 6px;
            height: 25px;
        }

        .driveText {
            color: red;
        }
    }
}

@media screen and (max-width: 1920px) {
    .poderDriveBox {
        display: flex;
        justify-content: space-between;

        .driveButton {
            margin: 6px;
            height: 25px;
        }

        .driveText {
            color: red;
        }
    }
}

.componentNumbers__boxNumbersInfo {
    display: flex;
    flex-direction: column;
    color: #373F47;
}

.componentNumbers__title {
    padding-right: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #373F47;
    word-break: break-word;
    display: block;
    position: relative;
}

.componentNumbers__number {
    padding-left: 26px;
    font-size: 42px;
    display: block;
    position: relative;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
    }
}

.componentNumbers__list {
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    color: #717D8A;
    display: block;

    &+.componentNumbers__list {
        margin-top: 8px;
    }

    a {
        font-weight: 700;
        color: #4F71A6;
        display: block;
    }
}

.componentNumbers__list__esqueleto {
    color: #717D8A;
}

.componentNumbers__boxNumbersTypeB {
    border: none;
    background: #DFECF1;

    .componentNumbers__number {
        padding-left: 30px;
        color: #4F71A6;

        &:after {
            width: 24px;
            height: 24px;
            background: url(./img/ico/relogio.svg) no-repeat;
        }
    }
}

.componentNumbers__boxNumbersUp {
    .componentNumbers__number {
        padding-left: 28px;
        color: #44C13C;

        &:after {
            width: 24px;
            height: 12px;
            background: url(./img/ico/seta_cima.svg) no-repeat;
        }
    }
}

.componentNumbers__boxNumbersDown {
    .componentNumbers__number {
        padding-left: 28px;
        color: #C21700;

        &:after {
            width: 24px;
            height: 12px;
            background: url(./img/ico/seta_baixo.svg) no-repeat;
        }
    }
}

.componentNumbers__boxNumbersNormal {
    .componentNumbers__number {
        color: #4040F2;

        &:after {
            width: 22px;
            height: 8px;
            background: url(./img/ico/seta_normal.svg) no-repeat;
        }
    }
}

.componentNumbers__boxNumbersPoder {
    .componentNumbers__number {
        padding-left: 32px;
        color: #ED7221;

        &:after {
            width: 28px;
            height: 28px;
            background: url(./img/ico/poder.svg) no-repeat;
        }
    }
}

@media screen and (max-width: 1399px) {

    .componentNumbers__boxNumbers,
    .componentNumbers__boxNumbersTypeB {
        min-height: 100%;
        flex-direction: column;
        justify-content: space-around;
    }

    .componentNumbers__list {
        li+li {
            margin-top: 0;
        }
    }

    .componentNumbers__title {
        padding: 0;
    }
}

@media screen and (max-width: 991px) {
    .componentNumbers__collumn {
        width: 50%;
    }
}

@media screen and (max-width: 400px) {
    .componentNumbers__title {
        font-size: 16px;
    }

    .componentNumbers__number {
        font-size: 32px;
    }
}

@media screen and (max-width: 450px) {
    .componentNumbers__boxNumbers {
        padding: 21px 10px;
    }
}

@media screen and (max-width: 1168px) {
    .boxNumbersDrive {
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;

        .boxNumbersDrive__txt {
            padding-top: 4px;
        }
    }
}

/* ESQUELETO */

@keyframes load {
    0% {
        background-color: #dbdbdbb3;
    }

    50% {
        background-color: #efefefb3;
    }

    100% {
        background-color: #f6f6f6;
    }
}

.boxNumeros {
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
    overflow: hidden;
    margin: 0px auto;
    transition: all 1s 100ms ease-out;
    border: 1px solid #EAECEE;
    height: 140px;
    border-radius: 12px;
}


@media screen and (max-width: 1399px) {
    .boxNumeros {
        .boxNumeros__children {
            display: flex;
            flex-direction: column;

            .boxNumeros__Txt45,
            .boxNumeros__Txt60 {
                margin: 0;
                align-self: baseline;
            }

            .boxNumeros__Txt45 {
                width: 110px;
            }

            .boxNumeros__Txt60 {
                min-width: 130px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .boxNumeros {
        padding: 12px 0;

        .boxNumeros__children {
            .boxNumeros__Txt45 {
                width: 80px;
            }

            .boxNumeros__Txt60 {
                min-width: 100px;
                height: 40px;
            }
        }
    }
}

.boxNumeros__children {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.boxNumeros__Txt45,
.boxNumeros__Txt60 {
    animation: load 700ms linear infinite alternate;
    border-radius: 3px;
}

.boxNumeros__Txt45 {
    width: 45%;
    max-width: 110px;
    margin: 0 15px 0 0;
    height: 12px;
}

.boxNumeros__Txt60 {
    width: 60%;
    max-width: 140px;
    height: 45px;
}