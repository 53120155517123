.boxDiarioHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 1000px){
    .boxDiarioHeader{
        flex-direction: column;
        align-items: flex-start;
        .boxBusca{
            width: 100%;
            margin-top: 12px;
        }
    }
}

.parent__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.section__btn {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    color: #0C1116;
    display: inline-block;
    border-radius: 32px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

    &:hover {
        text-decoration: none;
        color: #fff;
        border: 1px solid #0C1116;
        background-color: #0C1116;
    }
}

.section__btn__esqueleto {
    border: 1px solid #1a1a1a4f;
    color: #3b3b3b4f;
    cursor: default;
}

.section__btn:disabled {
    pointer-events: none;
}

.section__btn__esqueleto:hover {
    color: #3b3b3b4f;
    background-color: transparent;
    border: 1px solid #1a1a1a4f;
    transform: translateY(0px);
    box-shadow: rgba(0, 0, 0, 0) 0 0px 0px;
    cursor: default;
}

.section__btn:active {
    box-shadow: none;
    transform: translateY(0);
}

.child__container {
    padding: 55px;
}

.subtitle {
    color: #3B3B3B;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    align-items: center;
    padding-top: 10px;
}

.title__date {
    text-align: center;
}

.titleAtoContainer {
    display: flex;
    justify-content: flex-start;
}

.atoContainer {
    margin: 20px auto;
}

.atoBloco {
    display: flex;
    flex-direction: column;
    background: #E7E7E7;
    border-radius: 8px;
    margin-bottom: 60px;
    width: 100%;

    // height: 40vh;
    .atoSubtitles {
        font-size: 15px;
        font-weight: 500;
    }

    .atoHeader {
        padding: 30px;
        font-size: 20px;
        font-weight: 500;
        background: #ffffff5b;
    }

    .atoBody {
        padding: 30px;
    }

    .atoEdicao {
        padding-top: 5px;
        font-size: 12px;
    }

    .atoBloco:hover {
        color: #1A1A1A;
        background-color: #d5d5d5c1;
        transition: all 0.4s ease 0s;
        cursor: pointer;
    }
}

.carregando {
    display: flex;
    justify-content: center;
    align-items: center;
    //border: 1px solid red;
    min-height: 70vh;
}

.calendar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.calendarTitle {
    text-align: center;
    font-weight: bold;
}

.calendarBox {
    align-items: center;
    width: 200px;
    margin-bottom: 50px;
    border: 2px solid #bebebe;
    border-radius: 6px;
    padding: 10px;
}

.boxMensagem__diario {
    padding-top: 45px;
    font-weight: 400;
    color: #777777;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        margin-bottom: 30px;
        max-width: 400px;
    }
}

.listPosts {
    width: 100%;

    &>li {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;

        &+li {
            margin-top: 30px;
        }

        &>a {
            text-decoration: none;
        }
    }
}

.child__containerButtons {
    width: 100%;
    max-width: 700px;
    display: grid;
    //  border: 1px solid red;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    text-align: center;

    button {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        padding: 6px 0;
        color: #5D7ABA;
        font-size: 14px;
        justify-content: center;
        // border: 1px solid red;
        align-items: center;
        transition: background-color 400ms ease-in-out;
        border-bottom: 2px solid transparent;

        &.active {
            background-color: #D0E1E9;
            border-bottom: 1px solid #FD541E;
        }

        .containerButtons__section {
            font-weight: 500;
        }
    }

    div {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 580px) {
    .child__containerButtons {
        grid-template-columns: 1fr;
    }
}

.parent__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: #F7F7FB;
    border: 1px solid #EAECEE;
    border-radius: 0 0 12px 12px;
    border-top: 0px;
}

@media screen and (max-width: 767px) {
    .parent__container {
        display: none;
    }
    div.boxCalendario {
        border-bottom: 1px solid #EAECEE;
        border-radius: 8px 8px 8px 8px;
    }
}


// .boxCalendario {
//     border: 1px solid #EAECEE;
//     border-bottom: 0px;
//     padding: 14px 20px;
//     border-radius: 12px 12px 0 0;
//     background-color: #fff;
//     display: flex;
//     justify-content: space-between;
// }

.btnFiltroData{
    transition: background-color 400ms ease-in-out;
    button{
        background-color: transparent;
        padding: 6px 12px;
        border-radius: 4px;
        color: #4F71A6;
    }
    button.active{
        background-color: #D3E0E8;
    }
}

.boxCalendario {
    border: 1px solid #EAECEE;
    border-bottom: 0px;
    padding: 14px 0;
    border-radius: 12px 12px 0 0;
    background-color: #fff;
}

/* FILTROS */
nav.boxDiario__optionsMobile{
    display: none;
    margin-top: 20px;
}

.boxDiario__btnFiltro, .boxDiario__btnFiltroDisabled{
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    & button, .boxDiario__btn{
        background-color: #6E6EF7;
        color: #fff;
        font-size: 14px;
        padding: 8px 14px;
        border-radius: 40px;
    }

    & button.active{
        svg{
            transform: rotate(180deg);
        }
    }
    & svg{
        transition: transform 300ms ease-in-out;
        margin-left: 8px;
    }
}

.boxDiario__btnFiltroDisabled{
    position: relative;
    .boxDiario__btn{
        opacity: 0.4;
        background-color: #3B3B3B;
        svg{
            path{
                stroke: #fff;
            }
        }
    }

    .btnPro{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        max-width: 40px;
        height: 22px;
        top: -12px;
        right: -5px;
        border-radius: 15px;
        background: #5d7aba;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
    }
}

@media screen and (max-width: 767px){
    nav.boxDiario__optionsMobile{
        display: block;
    }
}

.boxDiario__optionsFiltro{
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin: 14px 0 0;
    max-height: 0px;
    overflow: hidden;
    height: 120px;
    padding: 0 20px;
    transition: max-height 400ms ease-in-out, background-color 400ms ease-in-out;
}

div.filtroActive{
    transition: max-height 400ms ease-in-out, background-color 400ms ease-in-out;
    max-height: 100px;
    background-color: #144fb40e;
}

@media screen and (max-width: 1015px) {
    .boxDiario__optionsFiltro { height: 170px; }

    div.filtroActive { max-height: 150px; }
}
@media screen and (max-width: 570px) {
    .boxDiario__optionsFiltro { height: 210px; }

    div.filtroActive { max-height: 210px; }
}

.boxDiario__options {
    display: grid;
    grid: "select1 select2 select3" / 1fr 1fr 1fr;
    gap: 15px;
    width: 100%;

    select.disabled {
        background-color: #3b3b3b0a;
        color: #9a9a9aa6;

        &:hover {
            border: 1px solid transparent;
        }
    }

    select {
        height: 48px;
        width: 100%;
        font-size: 14px;
        color: #4f4f4f;
        border: 1px solid #EAECEE;
        border-radius: 4px;
        padding: 0 32px 0 12px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        transition: border 300ms ease-in-out;
        background: url(./detalhamento/seta.svg) no-repeat calc(100% - 8px) #fff;

        &:focus,
        &:hover {
            border: 1px solid #00000033;
        }
    }

    select.select1 {
        grid-area: select1;
    }

    select.select2 {
        grid-area: select2;
    }

    select.select3 {
        grid-area: select3;
    }
}

@media screen and (max-width: 1015px) {
    .boxDiario__options {
        grid: "select1 select2"
            "select3 select3";
    }
}

@media screen and (max-width: 570px) {
    .boxDiario__options {
        grid: "select1 select1"
            "select2 select2"
            "select3 select3";
    }
}

.boxBusca {
    width: 460px;
    position: relative;

    input::placeholder {
        font-size: 14px;
        font-weight: 400;
    }

    input[type=text] {
        width: 100%;
        height: 42px;
        padding: 0 38px 0 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        color: #00000099;
        border-radius: 6px;
        border: 1px solid #D6DADE;
        background-color: #fff;
    }

    button[type=submit] {
        width: 40px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1px;
        border-radius: 6px;
        background-color: transparent;
        transition: background-color 200ms ease-in-out;

        path {
            transition: stroke 200ms ease-in-out;
        }
    }
}