.boxTitle{
    display: flex;
    gap: 10px 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* IDENTIFICADOR APROVAÇÃO */
.identificadorVotacoes {
    display: flex;
    gap: 10px;
    align-items: center;
}

.identificador {
    padding: 0px 10px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #fff;
    line-height: 100%;
    height: 30px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #004A2F;
}

.identificadorA {
    background-color: #44C13C;
}

.identificadorB {
    background-color: #FF0000;
}

.identificadorC {
    background-color: #FB5303;
}

.identificadorD {
    background-color: #184479;
}

/* INFORMAÇÕES */
.listPosts {
    margin: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &>.listPosts__container {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;
    }

}

.list__objetos {
    padding: 30px 20px 25px;
    position: relative;
}

.listPosts__header {
    padding: 15px 20px;
    border-bottom: 1px solid #EAECEE;
}

.listPosts__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}

.listPosts__titleB{
    padding: 10px 20px;
}

.listPosts__main {
    padding: 20px;
    position: relative;
}

.listPosts__mainProposicoes {
    display: grid;
    gap: 20px;
}

.listPosts__listInfo {
    &>.listPosts__itens {
        width: 100%;
        display: flex;

        &+li {
            margin-top: 10px;
        }

        &>.listPosts__itensTitle {
            width: 100px;
            min-width: 100px;
            font-weight: 600;
            font-size: 12px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }

        &>.listPosts__itensDados {
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }
    }
}


.listPosts__listInfoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px;
    background: rgba(223, 236, 241, 0.5);
    border-radius: 5px;
    
    .button_saibaMais {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #FD541E;
        font-size: 14px;
        width: 92px;
        margin-left: 99px;
        height: 29px;
        padding: 4px 10px;
        background: #FFFFFF;
        border-radius: 2px;
    }
}
        
.listInfoBox_objetos {
    height: 100%;
}

@media screen and (max-width: 767px) {
    .listPosts__ementa{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .listPosts__listInfoBox {
        .button_saibaMais { margin-left: 0px; }
    }
    .listPosts__listInfoB{
        li{
            flex-direction: column;
            gap: 4px;
            .listPosts__itensTitle{
                width: 100% !important;
            }
        }
    }
}
            
.parentVotacao {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 14px;
    align-items: center;
    padding: 0;
    height: 100%;

    &>li>div>span {
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #4F5B67;
        display: block;
    }

}

.listPosts__listInfoBoxDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &>span {
        margin-top: 8px;
        padding: 5px;
    }
}

/* ORIENTAÇÕES */
.listPosts__listInfoOrientações {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    gap: 15px;

    &>li {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        background: #fff;
        border: 1px solid rgba(157, 202, 223, 0.5);
        border-radius: 8px;

        &>strong {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #4F5B67;
            padding: 0 15px 0 0;
            text-align: left;
        }

        &>span {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #4F5B67;
        }
    }

    .identificadorVotoA {
        color: #44C13C;
    }

    .identificadorVotoB {
        color: #FF0000;
    }

    .identificadorVotoC {
        color: #777777;
    }
}

@media screen and (max-width: 1021px) {
    .listPosts__listInfoOrientações {
        &>li {
            width: 100%;
        }
    }
}

/* VOTOS */

.boxCards {
    padding: 20px 0;
}

.boxCards__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 20px;
}

.boxCards__card {
    width: 100%;
    padding: 17px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    max-width: 250px;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: pink;
    }

}

/* PROPOSIÇÕES AFETADAS & OBJETOS POSSÍVEIS */
.listPosts2 {
    margin: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &>article {
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;
    }
}

@media screen and (max-width:1400px) {
    .listPosts2 {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width:462px) {
    .listPosts,
    .listPosts2 {
        display: flex;
        flex-direction: column;
    }

    .boxCards__card {
        max-width: 100%;
    }
}

.boxCards__cardB:after {
    background-color: #004A2F;
}

.boxCards__voto {
    display: flex;
    justify-content: center;

    &>.identificador {
        max-width: 90px;
        margin: 12px 0 0 0;
        text-align: center;
    }

    &>.identificadorVotoA {
        background-color: #44C13C;
    }

    &>.identificadorVotoB {
        background-color: #FF0000;
    }

    &>.identificadorVotoC {
        background-color: #777777;
    }
}

.card__avatar {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 18px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #C4C4C4;
    border: 1px solid #9DCADF;

    &>img {
        width: 100%;
        display: block;
    }
}

.card__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__data {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F5B67;
    display: block;
}

// INFOGRÁFICO

.boxInfo {
    background-color: #ECEFF1;
    padding: 20px;
}

.boxInfo__title {
    max-width: 400px;
    color: #1B4677;
    font-size: 36px;
}

@media screen and (min-width: 1800px) {
    .boxInfo__title {
        max-width: none;
    }
}

.boxInfo__aprove,
.boxInfo__denied,
.boxInfo__abs {
    border-radius: 8px;
    padding: 10px;
    display: flex;
    width: 103px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    strong {
        font-size: 32px;
        color: #fff;
    }

    span {
        font-size: 20px;
        color: #fff;
    }
}

.boxInfo__denied {
    background-color: #FD541E;
}

.boxInfo__aprove {
    background-color: #1B4677;
}

.boxInfo__votos {
    display: flex;
    margin-top: 40px;
    max-width: 220px;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .boxInfo__votos {
        margin-top: 0px;
    }

    .boxInfo {
        padding: 40px 30px 30px;
    }
}

@media screen and (max-width: 767px) {
    .boxInfo {
        padding: 20px 15px;
    }
}

.boxInfo__votosWrapper {
    padding-right: 20px;
}

@media screen and (max-width: 1079px) {
    .boxInfo__votosBox {
        flex-direction: column;
        align-items: center;
    }

    .boxInfo__votosWrapper {
        padding: 0 0 40px 0;
    }
}

.boxInfo__abs {
    flex-direction: row;
    width: 221px;
    background-color: #78909C;
    margin-top: 10px;

    span {
        font-size: 14px;
    }

    strong {
        font-size: 24px;
        margin-right: 10px;
    }
}

.boxInfo__table {
    // border: 3px solid green;
    min-width: 930px;
}

.boxInfo__tableBox {
    margin-top: 30px;
    border-top: 1px solid #CFD8DC;
    padding: 15px 0 20px 0;
    overflow-x: auto;

    table {
        width: 100%;

        thead {
            th {
                font-weight: 400;
                padding: 0 15px 15px;
            }

            th.boxInfo__absTable {
                padding-left: 40px;
            }

            th.boxInfo__sim {
                padding-left: 30px;
            }

            .boxInfo__partido {
                width: 24%;
                min-width: 240px;
            }

            .boxInfo__bancada {
                width: 85px;
            }
        }

        tbody {

            td.boxInfo__partido,
            .boxInfo__bancada {
                background-color: #fff;
            }

            td.boxInfo__bancada {
                padding-left: 40px;
                color: #717D8A;
                font-weight: 700;
            }

            td.boxInfo__absTable,
            .boxInfo__sim,
            .boxInfo__nao,
            .boxInfo__bar {
                background-color: #F4F4F4;
                font-weight: 700;
            }

            td.boxInfo__bar {
                width: 300px;
                max-width: 300px;
                height: 30px;
            }

            td.boxInfo__absTable {
                color: #717D8A;
                padding-left: 40px;
            }

            td.boxInfo__sim {
                color: #1B4677;
                padding-left: 30px;
            }

            td.boxInfo__simTypeB {
                color: #1B4677;
                font-weight: 700;
            }

            td.boxInfo__naoTypeB {
                color: #FD541E;
                font-weight: 700;
            }

            td.boxInfo__pie {
                padding: 10px;
                height: 30px;
                width: 30px;
            }

            td.boxInfo__nao {
                color: #FD541E;
            }

            td {
                background-color: #fff;
                font-size: 18px;
                padding: 0px 16px;
                border-bottom: 1px solid #A8B0B9;

                img {
                    width: 40px;
                    margin-right: 22px;
                }
            }
        }
    }
}

.boxInfo__votosBox {
    display: flex;
    justify-content: space-between;
    max-width: 1530px;
    margin: 0 auto;

    .boxInfo__grafico {
        width: 100%;
    }
}

.outrosInfografico {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
}

@media screen and (max-width: 1483px) {
    .boxInfo__tableBox {
        width: 70vw;
    }
}

@media screen and (max-width: 1300px) {
    .boxInfo__tableBox {
        width: 65vw;
    }
}

@media screen and (max-width: 1100px) {
    .boxInfo__tableBox {
        width: 60vw;
    }
}

@media screen and (max-width: 970px) {
    .boxInfo__tableBox {
        width: 55vw;
    }
}

@media screen and (max-width: 875px) {
    .boxInfo__tableBox {
        width: 51vw;
    }
}

@media screen and (max-width: 764px) {
    .boxInfo__tableBox {
        width: 80vw;
    }
}

@media screen and (max-width: 690px) {
    .boxInfo__tableBox {
        width: 75vw;
    }
}

@media screen and (max-width: 480px) {
    .boxInfo__tableBox {
        width: 70vw;
    }
}

@media screen and (min-width: 1650px) {

    .boxInfo__aprove,
    .boxInfo__denied,
    .boxInfo__abs {
        width: 203px;
    }

    .boxInfo__votos {
        max-width: 520px;
    }

    .boxInfo__abs {
        width: 441px;
        margin-top: 15px;
    }
}

@media screen and (min-width: 1800px) {
    .boxInfo__tableBox {
        margin-top: 30px;
        padding: 15px 0 0 0;

        table {
            thead {
                th {
                    padding: 0 15px 15px;
                }

                th.boxInfo__absTable {
                    padding-left: 0px;
                }

                th.boxInfo__sim {
                    padding-left: 70px;
                }

                th.boxInfo__simTypeB {
                    padding-left: 40px;
                }

                th.boxInfo__naoTypeB {
                    padding-left: 40px;
                }

                .boxInfo__partido {
                    width: 24%;
                }

                .boxInfo__bancada {
                    width: 85px;
                }
            }

            tbody {
                td.boxInfo__bar {
                    width: 230px;
                }

                td.boxInfo__absTable {
                    padding-left: 0px;
                }

                td.boxInfo__sim {
                    padding-left: 70px;
                }

                td {
                    padding: 0px 16px;

                    img {
                        width: 50px;
                        margin-right: 22px;
                    }
                }

                td.boxInfo__simTypeB {
                    padding: 0 40px;
                    width: 80px;
                }

                td.boxInfo__naoTypeB {
                    padding: 0 40px;
                    width: 80px;
                }
            }
        }
    }
}

@media screen and (min-width: 2000px) {
    .boxInfo__tableBox {
        table {
            thead {
                th.boxInfo__sim {
                    padding-left: 100px;
                    width: 200px;
                }

                th.boxInfo__simTypeB {
                    padding-left: 60px;
                }

                th.boxInfo__naoTypeB {
                    padding-left: 40px;
                }

                .boxInfo__partido {
                    width: 24%;
                }

                .boxInfo__bancada {
                    width: 85px;
                }
            }

            tbody {
                td.boxInfo__bar {
                    width: 80px;
                    padding-right: 40px;
                }

                td.boxInfo__absTable {
                    width: 90px;
                }

                td.boxInfo__sim {
                    padding-left: 100px;
                }

                td.boxInfo__nao {
                    width: 130px;
                }

                td {
                    padding: 0px 16px;

                    img {
                        width: 55px;
                        margin-right: 38px;
                    }
                }

                td.boxInfo__simTypeB {
                    padding: 0 0 0 60px;
                    width: 50px;
                }

                td.boxInfo__naoTypeB {
                    padding: 0 0 0 40px;
                    width: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 1360px) {
    .boxInfo__grafico {
        width: 60%;
        display: flex;
        justify-content: flex-end;

        img {
            width: 100%;
        }
    }
}

@media screen and (max-width: 1079px) {

    .boxInfo__aprove,
    .boxInfo__denied,
    .boxInfo__abs {
        width: 203px;
    }

    .boxInfo__votos {
        max-width: 440px;
    }

    .boxInfo__abs {
        width: 441px;
        margin-top: 15px;
    }

    .boxInfo__title {
        max-width: none;
        font-size: 30px;
    }
}

@media screen and (max-width: 850px) {

    .boxInfo__aprove,
    .boxInfo__denied,
    .boxInfo__abs {
        width: 45%;
    }

    .boxInfo__votos {
        max-width: none;
    }

    .boxInfo__abs {
        width: 100%;
        min-width: 270px;
    }
}

@media screen and (max-width: 400px) {

    .boxInfo__aprove,
    .boxInfo__denied,
    .boxInfo__abs {
        width: 47%;
    }

    .boxInfo__aprove,
    .boxInfo__denied {
        strong {
            font-size: 26px;
        }
    }

    .boxInfo__abs {
        min-width: 220px;

        strong {
            font-size: 22px;
        }
    }
}

@media screen and (min-width: 765px) {
    .boxInfo__grafico {
        :global(.recharts-pie-label-text) {
            font-size: 24px !important;
            font-weight: 700;
        }
    }
}

@media screen and (max-width: 764px) {
    .boxInfo__grafico {
        :global(.recharts-pie-label-text) {
            font-size: 20px !important;
            font-weight: 700;
        }
    }
}

.textStyle__bold {
    font-weight: 600 !important;
}