/* INFORMAÇÕES DO TOPO */

.boxSingle__boxTitle {
    padding-bottom: 15px;
    display: flex;
}

/* INFORMAÇÕES GERAIS */
.boxInfos__wrapper{
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background: #fff;
}
.boxInfos {
    margin: 30px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &>article {
        width: 100%;
        min-width: 290px;
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;
    }
}

.boxInfos__header {
    padding: 10px;
    border-bottom: 1px solid #EAECEE;
}

.boxInfos__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}

.boxInfos__main {
    padding: 30px 20px 0 24px;
}

/* INFORMAÇÕES SOBRE A FRENTE */
.boxInfos__listInfo {

    &>li {
        width: 100%;
        display: flex;
        margin-bottom: 8px;

        &>strong {
            width: 100px;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
        }

        &>strong+span {
            margin-bottom: 0;
        }

        &>ul,
        &>ul>li,
        &>span {
            width: 100%;
            margin: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            display: block;
            margin-bottom: 19px;

            &>span {
                display: block;
                margin-bottom: 0;
            }
        }
    }
}

/* COORDENADOR */
.boxInfos__Coordenador {
    display: flex;

    .avatar {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 1px solid rgba(157, 201, 234, 0.5803921569);
        overflow: hidden;
        background-color: #C4C4C4;

        &>img {
            width: 100%;
            display: block;
        }
    }

    .listInfos__Coordenador {

        .coordenador__Name {
            font-weight: 700;
            font-size: 18px;
            line-height: 240%;
            margin: 0 0 0 51px;
        }

        .listInfos__CoordenadorDetails {
            gap: 13px;
            margin: 0 0 35px 51px;

            .listInfos__CoordenadorDetailsBloco {
                width: 100%;
                column-count: 2;
                gap: 20px;
            }

            .separador {
                display: flex;
                break-inside: avoid;
                align-items: flex-start;

                .coordenadorDadosTitle {
                    display: block;
                    width: 135px;
                }

                .coordenadorDados {
                    display: block;
                    word-break: break-word;

                    svg {
                        margin-right: 20px;
                    }
                }

            }

            .link__saibaMais {
                font-weight: 400;
                font-size: 14px;
                line-height: 150%;
                color: #FD541E;
                margin: 17px 0 35px 0;
            }

        }
    }
}

@media screen and (max-width:1273px) {
    .boxInfos__Coordenador {
        .listInfos__Coordenador {
            .listInfos__CoordenadorDetails {
                .listInfos__CoordenadorDetailsBloco {
                    column-count: 1;
                }
            }
        }
    }
}

@media screen and (max-width:931px) {
    .boxInfos__listInfo {

        &>ul,
        &>ul>li,
        &>span {
            width: calc(100% - 100px);
        }
    }

    .boxInfos__Coordenador {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .listInfos__Coordenador {
            .listInfos__CoordenadorDetails {
                margin: 0;

                .separador {
                    flex-wrap: wrap;

                    .coordenadorDadosTitle,
                    .coordenadorDados {
                        margin: 0 0 0 51px;
                    }
                }

                .link__saibaMais {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .boxCards__card {
        width: 100%;
        max-width: 450px;
    }

    .boxCards__list {
        justify-items: center;
    }
}

@media screen and (max-width:351px) {
    .boxInfos__listInfo {

        &>ul,
        &>ul>li,
        &>span {
            width: calc(100% - 100px);
        }
    }

    .boxInfos__Coordenador {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .listInfos__Coordenador {
            .listInfos__CoordenadorDetails {
                margin: 0;

                .separador {
                    flex-wrap: wrap;
                }
            }
        }
    }
}

/* CARDS */

.boxCards {
    padding: 20px;
}

.boxCards__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.boxCards__card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px 20px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    max-width: 400px;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: pink;
    }

}

.boxCards__cardB:after {
    background-color: #004A2F;
}

.card__avatar {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 18px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #9dc9ea94;
    background-color: #C4C4C4;

    &>img {
        width: 100%;
        display: block;
    }
}

.card__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__data {
    padding: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F5B67;
    display: block;
}

.card__link {
    margin: 12px 0 24px 0;
    display: flex;
    justify-content: center;

    &>a {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        color: #0C1116;
        display: inline-block;
        border-radius: 32px;
        border: 1px solid #EAECEE;
        background-color: #fff;
        transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: #fff;
            border: 1px solid #0C1116;
            background-color: #0C1116;
        }
    }
}