.componentPronunciamentos {
    width: 100%;
    padding: 20px 0;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
}

/* CABEÇALHO */

.componentPronunciamentos__header {
    width: 100%;
    margin-bottom: 16px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.componentPronunciamentos__title {
    font-weight: 600;
    font-size: 15px;
    color: #373F47;
    display: block;
}

.componentPronunciamentos__titleMore {
    font-weight: 700;
    font-size: 10px;
    color: #4F71A6;
    display: block;

    &:hover {
        color: #4F71A6;
    }
}

.componentPronunciamentos__boxPronunciamentos {
    width: 100%;
    height: 250px;
    max-height: 250px;
    padding: 0 20px;
    overflow-y: auto;
    position: relative;
    display: flex;
}

.swiperButtons{
    position: absolute;
    background-color: #fff;
    z-index: 99;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
}

.boxPronunciamentos__list {
    margin-top: 6px;

    li+li {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #EAECEE;
    }
}

.boxPronunciamentos__profile {
    display: flex;
    align-items: center;
}

.boxPronunciamentos__profileImageBox {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    position: relative;
}

.boxPronunciamentos__profileImage {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        display: block;
    }
}

.boxPronunciamentos__profileImageParty {
    width: 13px;
    height: 13px;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
        display: block;
    }
}

.boxPronunciamentos__profileTitle {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    display: block;
}

.boxPronunciamentos__profileParty {
    font-weight: 400;
    font-size: 11px;
    color: #ABABAB;
    display: block;
}

.boxPronunciamentos__pronunciamento {
    margin-top: 20px;
    padding: 18px 15px 15px;
    position: relative;
    border-radius: 4px;
    background-color: #F7F7F7;

    &:after {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: 0;
        left: 15px;
        background-color: #F7F7F7;
        transform: rotate(45deg);
        z-index: 2;
    }

    a {
        position: relative;
        z-index: 4;

        &:hover {
            text-decoration: none;
        }
    }

    time {
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 124x;
        line-height: 14px;
        color: #000000;
        display: block;
    }

    p {
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        color: #4F5B67;
    }
}

.boxProposicoes__text {
    font-size: 17px;
    line-height: 150%;
    color: #242D35;
    display: flex;
    width: calc(100% - 150px);

    p {
        margin-right: 25px;
    }
}

.boxProposicoes__li {
    position: relative;
}

.boxPronunciamentos__li {
    position: relative;
}

.boxPronunciamentos__link {
    &:hover {
        text-decoration: none;
    }
    div,
    .boxProposicoes__text {
        display: flex;
        padding-bottom: 10px;

        em {
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: #4F5B67;
            font-style: normal;
            min-width: 80px;
        }

        span {
            color: #4F5B67;
        }
    }

    .bold {
        font-weight: 600;
    }

    @media screen and (max-width: 620px) {
        div {
            flex-direction: column;

            em {
                margin-bottom: 6px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .componentPronunciamentos__boxPronunciamentos {
        max-height: none;
        height: auto;
    }

}

.componentPronunciamentos__boxAgenda {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
}

.boxAgenda__list {
    padding: 0 30px 0 15px;

    li {
        width: 100%;
        padding: 9px 10px 8px;
        position: relative;

        &+li {
            margin-top: 18px;

            &:after {
                content: "";
                width: 100%;
                height: 1px;
                display: block;
                position: absolute;
                top: -10px;
                left: 0;
                background-color: #EAECEE;
            }
        }

        a {
            display: flex;
            align-items: flex-start;

            &:hover {
                text-decoration: none;
            }
        }

        strong {
            width: 16%;
            min-width: 90px;
            padding-right: 10px;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            color: #373F47;
        }

        span {
            width: 72%;
            padding-right: 10px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #373F47;
        }

        em {
            width: 12%;
            min-width: 60px;
            font-weight: 400;
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            color: #373F47;
        }

        &:hover {
            cursor: default;
            border-radius: 4px;
            background-color: #DFECF1;
        }
    }
}

@media screen and (max-width: 767px) {
    .componentPronunciamentos__boxAgenda {
        max-height: none;
    }
}

@media screen and (max-width: 450px) {
    .componentPronunciamentos__header {
        display: block;
    }

    .componentPronunciamentos__title {
        margin-bottom: 5px;
    }

    .boxAgenda__list {
        li {
            a {
                flex-wrap: wrap;
            }

            strong {
                width: auto;
                min-width: auto;
                padding-bottom: 10px;
                order: 1;
            }

            span {
                width: 100%;
                padding-right: 0;
                order: 3;
            }

            em {
                order: 2;
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
    .componentPronunciamentos__header {
        display: block;
    }

    .componentPronunciamentos__title {
        margin-bottom: 5px;
    }

    .boxAgenda__list {
        li {
            a {
                flex-wrap: wrap;
            }

            strong {
                width: auto;
                min-width: auto;
                margin-bottom: 5px;
                order: 1;
            }

            span {
                width: 100%;
                padding-right: 0;
                order: 3;
            }

            em {
                order: 2;
            }
        }
    }
}

/* Container dos Eventos com as datas e o calendário*/
.eventos {
    // background: #E7E7E7;
    //grid-row: 1/2;
    border-radius: 7px;
    padding: 20px 20px 40px 20px;
    height: 100%;

    .headetEvt {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;

        div:last-child {
            color: #4F71A6;
            font-size: 10px;
            text-transform: uppercase;
        }
    }

    .eventos_bloco {
        display: flex;
        justify-content: space-around;
        padding-top: 10px;
        height: 90%;
    }

    .eventos_datas_bloco {
        width: 100%;
        color: #5C5D5D;
        font-size: 12px;
        padding: 0px 0px 0px 15px;

        .eventos_datas {
            display: flex;
            padding: 8px;
            font-weight: 600;
            justify-content: space-between;
            border-bottom: 1px solid #00000017;
            margin-bottom: 5px;
        }
    }
}

.controleErro {
    height: 80%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

/* BOTÃO ACOMPANHAR */

.formFollow {
    top: 4px;
    right: 10px;
    z-index: 2;
    position: absolute;

    label {
        padding: 0 35px 0 0;
        font-weight: 700;
        font-size: 12px;
        text-align: right;
        text-transform: uppercase;
        color: #5C5D5D;
        cursor: pointer;
        display: block;
        position: relative;

        &:after {
            content: "";
            width: 9px;
            height: 9px;
            display: block;
            position: absolute;
            top: 4px;
            right: 10px;
            border-radius: 50%;
            background-color: #fff;
            z-index: 4;
            transition: transform 400ms ease-in-out;
        }

        &:before {
            content: "";
            width: 20px;
            height: 11px;
            display: block;
            position: absolute;
            top: 3px;
            right: 0;
            border-radius: 10px;
            background-color: #A8B0B9;
            z-index: 2;
            transition: background 400ms ease-in-out;
        }
    }

    input[type=checkbox] {
        position: absolute;
        left: -99999px;
        z-index: 2;

        &:checked~label {
            &:after {
                transform: translate(9px, 0);
            }

            &:before {
                background-color: #1991EB;
            }
        }
    }
}

.boxMensagem__lupa {
    padding: 0px 20px;
    color: #777777;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        width: 100%;
    }

    p {
        margin-top: 16px;
        max-width: 230px;
        line-height: 130%;
        color: #4F5B67;
        font-weight: 500;
    }
}
