.boxDiarioOficial {
    width: calc(100% - 320px);
    margin-top: 20px;
    padding: 30px;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    overflow-x: hidden;
    position: relative;

    &::after{
        content: "";
        width: 25px;
        height: 100%;
        right: 0;
        top: 0;
        background-color: #Fff;
        position: absolute;
    }

    &::before{
        content: "";
        height: 100%;
        background-color: #fff;
        z-index: 2;
        width: 25px;
        left: 0;
        top: 0;
        position: absolute;
    }
}

.boxTitle__diario,
.boxDiario__title {
    text-transform: lowercase;

    &::first-letter { text-transform: uppercase; }
}

.boxDiario__content {
    p { margin: 22px 0; }
}

.boxDiario__autor { font-size: 14px; }

.boxDiario__txt { line-height: 24px; }

.boxDiario__title {
    font-weight: 600;
    color: #373F47;
}

.alert__container {
    color: #A8B0B9;
    font-size: 14px;
    margin: 14px 0 0 0;
}

.diarioOficial__wrapper{
    transition: rotate 400ms ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #4F5B67;
    max-width: 1400px;

    .contentActive{
        height: auto;
        transition: rotate 400ms ease;
    }
    .contentDisabled{
        button { 
            svg { rotate: 180deg; }
        }
    }
}

.boxAside__main{
    display: flex;
    flex-direction: column;
}

.boxAside {
    background-color: #1450B40D;
    border-radius: 12px;
    width: 300px;
    padding: 30px 25px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: width 300ms ease-in-out, height 300ms ease-in-out, rotate 300ms ease-in-out !important;

    .boxAside__header{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    button{
        background-color: transparent;
        width: 100%;
        display: flex;
        align-items: center;

        svg { 
            display: none; 
            margin-top: -12px;
        }
    }

    .asideLink {
        color: #2D9CDB;

        &:hover { text-decoration: none; }
    }

    strong {
        font-weight: 600;
        margin: 4px 0;
    }

    span {
        font-weight: 400;
        word-break: break-word;
        margin-bottom: 12px;
    }

    span {
        &:first-child {
            color: #fff;
            background-color: #004EB7;
            border-radius: 4px;
            padding: 3px 6px;
            font-size: 14px;
            margin-bottom: 14px;
        }
    }
}
.boxDiario__tableWrapper{
    padding: 16px 0;
    margin-bottom: 20px;
    width: 100%;
}

.boxDiarioOficial__table {
    border: 1px solid #dedede;
    font-size: 14px;
    width: 100%;

    &:hover{ cursor: grab;  }

    tr {
        background-color: #fff;
        width: 100%;
        &:nth-child(2) {
            background-color: #F7F7F7;
            border: 1px solid #e6e6e6;
            color: #4F5B67;
        }
    }

    td {
        padding: 0px 20px;
        min-width: 170px;
        border: 1px solid #e6e6e6;
        color: #000000;
       
        p {
            margin: 0; 
            padding: 4px 0;
        }
    }
}

@media screen and (max-width: 1190px) {
    .boxDiarioOficial { width: 100%; }

    .diarioOficial__wrapper {
        flex-direction: column-reverse;
        align-items: flex-end;
        width: 100%;

        .contentDisabled { width: 200px; }
        .contentActive {
            .boxAside__main{ padding: 0 20px 20px; }
        }
    }

    .boxAside{
        height: 58px;
        overflow: hidden;
        padding: 0px;
        margin-top: 4px;

        button {
            justify-content: space-between;
            padding: 16px 16px 0px;

            svg { display: block; }
        }
    }
    .contentDisabled {
        .boxAside__header { margin-bottom: 0px; }
    }

    .box__Diario{
        display:none;
    }

    .box__DiarioMobile{
        display: block;
    }
        
}

@media screen and (max-width: 767px) {
    .diarioOficial__wrapper{
        .contentDisabled, .contentActive{
            width: 100%;
        }
    }
    .boxDiarioOficial {
        padding: 20px;

        &::after{ width: 20px; }
        &::before{ width: 20px; }
    }
}

@media screen and (min-width: 1190px) {
    .box__DiarioMobile{
        display: none;
    }
}