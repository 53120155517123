/* CONTEÚDO */

.singleContainer {
    width: 100%;
    padding-top: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.singleContainer__content {
    width: 100%;
}

.content {
    max-width: 80%;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background: #fff;
    transition: border 200ms ease-in-out;
}

.content__header {
    padding: 10px;
    border-bottom: 1px solid #EAECEE;

}

.content__title {
    font-weight: 500;
    font-size: 18px;
    color: #4F5B67;
    display: block;
}

.partidoUfDataHora {
    display: flex;
    padding: 0 24px;
    font-size: 14px;
    color: #4F5B67;
    padding-top: 20px;

    .partidoSeparador {
        display: flex;
        padding-right: 20px;

        .partidoDados {
            display: block;
            width: 90px;
            margin-right: 10px;
        }

        &+.partidoSeparador {
            margin-left: 20px;
        }
    }

}

// AVATAR

.avatar {
    width: 80px;
    height: 80px;
    margin: 13px 30px 0 24px;
    border-radius: 50%;
    border: 1px solid #9DC9EA;
    overflow: hidden;
    background-color: #C4C4C4;
    flex-shrink: 0;
    flex-grow: 0;

    img {
        width: 100%;
        display: block;
    }
}

// BALÃO

.container__balao {
    display: flex;
}

.balao {
    margin: 18px;
    display: inline-block;
    width: 100%;
    padding: 20px;
    border: 1px solid #9DC9EA;
    border-radius: 10px;
    margin-bottom: 40px;
    position: relative;

    &>.balaoTitle {
        font-size: 12px;
        color: #767676;
    }

    &>.balaoTxt {
        padding-top: 15px;
        font-size: 14px;
    }
}

.balao::after {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    left: -8px;
    top: 25px;
    background-color: white;
    border: 1px solid;
    border-color: transparent transparent #9DC9EA #9DC9EA;
    transform: rotate(45deg);
}

//Responsividade

@media screen and (max-width:1023px) {
    .content {
        max-width: 100%;
    }
}

@media screen and (max-width: 953px) {
    .container__balao {
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .balao {
        width: calc(100% - 40px);
        padding-top: 50px;
        margin-top: 50px;
        margin-bottom: 20px;
        word-break: break-word;
    }

    .balao::after {
        background-color: transparent;
        border-color: transparent transparent transparent transparent;
    }

    .avatar {
        z-index: 1;
        position: absolute;
        top: 13px;
        left: 50%;
        transform: translate(-50%, 0);
        margin: 0;
    }
}

@media screen and (max-width:424px) {
    .partidoUfDataHora {
        display: block;

        .partidoSeparador+.partidoSeparador {
            padding-top: 10px;
            margin: 0;
        }
    }
}

.discursoDescription{
    margin-bottom: 18px;
  }