.container {
    // display: flex;
    // flex-direction: column;
    align-items: center;
    padding: 20px 0;
}

.containerFilho1 {
    margin-bottom: 30px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    background: #fff;
    color: #4F5B67;
    width: calc(100% - 320px);
    .containerFilho1__header{
        border-bottom: 1px solid #EAECEE;
        padding: 16px 20px 12px;
        .headerTitle{
            font-weight: 500;
            font-size: 18px;
        }
    }
    .containerFilho1__main, .containerFilho1__mainTypeB{
        padding: 20px;
        & > div {
            width: 100%;
            display: flex;
    
            & + div { margin-top: 10px; }
        }
    }

    .containerFilho1__mainTypeB{
        margin: 30px 20px;
        border-radius: 6px;
        background-color: rgba(223, 236, 241, 0.5);
        a{
            background-color: #fff;
            border-radius: 4px;
            color: #FD541E;
            font-size: 14px;
            padding: 6px;
            margin: 14px 0 0 98px;
            display: block;
            width: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }


    .bold {
        width: 100px;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        display: block;
    }

    span {
        width: calc(100% - 100px);
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        display: block;
    }
}

@media screen and (max-width: 1400px) {
    .containerFilho1 {
        width: calc(100% - 160px);
    }
}

@media screen and (max-width: 830px) {
    .containerFilho1 {
        width: 100%;
    }
}

.containerFilho2 {
    background-color: #eeeeee;
    // display: inline-block;
    padding: 2%;
    margin: 1rem;
    border-radius: 0.5rem;
}

.bold {
    width: 80px;
    font-weight: 600 !important;
    font-size: 12px;
    line-height: 150%;
    color: #4F5B67;
    display: block;
}

.deputadosTitle {
    padding: 15px;
}

.deputadoContainer {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

.deputadoCard {
    display: flex;
    padding: 15px;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(213, 210, 210);
    border-radius: 6px;
}

.deputadoNome {
    text-align: center;
    font-weight: 500;
}

.deputadoPartido,
.deputadoEmail {
    text-align: center;
}

.imgMembro {
    margin: 0 auto;
    width: 60px;
    height: 60px;
    margin-bottom: 25px;
}

/* LISTA DE CONGRESSISTAS */

.boxCards {
    width: 100%;
    margin-top: 25px;
}

.boxCards__list {
    margin: -7px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

    &>li {
        padding: 7px;
    }
}

.boxCards__card {
    width: 100%;
    min-width: 276px;
    padding: 24px 20px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    max-width: 400px;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background-color: pink;
    }
}

.boxCards__cardA:after {
    background-color: #005B9E;
}

.boxCards__cardB:after {
    background-color: #004A2F;
}

.card__formFavorite {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: none;

    label {
        cursor: pointer;

        span {
            position: absolute;
            left: -99999px;
        }

        path {
            fill: #fff;
            transition: fill 200ms ease-in-out, stroke 200ms ease-in-out;
        }

        &:hover {
            path {
                fill: #FFF9CF;
            }
        }
    }

    input[type=checkbox] {
        position: absolute;
        left: -99999px;

        &:checked~label {
            path {
                fill: #FFCF0F;
                stroke: #FFCF0F;
            }
        }
    }
}

.formRow {
    width: 100%;
    position: relative;
}

.card__avatar {
    width: 80px;
    height: 80px;
    margin: 0 auto 18px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgba(157, 201, 234, 0.5803921569);
    background-color: #C4C4C4;

    img {
        width: 100%;
        display: block;
    }
}

.card__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__data {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #4F5B67;
    display: block;
}

.card__list {
    width: 100%;
    margin-top: 21px;
    display: grid;
    grid-auto-flow: column;
    margin: 21px auto 0;
    max-width: 400px;
    grid-auto-columns: minmax(0, 1fr);

    li {
        &+li {
            border-left: 1px solid #F1F1F1;
        }

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #A8B0B9;
            display: block;
        }

        strong {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #373F47;
            display: block;
        }
    }
}

.card__link {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    a {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        color: #0C1116;
        display: inline-block;
        border-radius: 32px;
        border: 1px solid #EAECEE;
        background-color: #fff;
        transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: #fff;
            border: 1px solid #0C1116;
            background-color: #0C1116;
        }
    }
}