.boxEventos{
    margin-bottom: 20px;
    border: 1px solid #EAECEE;
    width: calc(100% - 320px);
    border-radius: 4px;
    background-color: #fff;
    color: #4F5B67;
    &:hover{ cursor: pointer; }
}

.boxEventos__header{
    font-size: 18px;
    padding: 14px 20px 12px;
    border-bottom: 1px solid #EAECEE;
}

.boxEventos__main{
    padding: 20px;
    & > div{
        display: flex;
        margin-bottom: 6px;
    }
    strong{
        font-weight: 600;
        min-width: 120px;
        width: 120px;
        font-size: 14px;
    }

    span{ line-height: 160%; }
}

.boxEventos__title{
    font-size: 20px;
}

