.buttonImage {
    color: #1B4677;
    // width: 80px;
    height: 40px;
    //padding: 18px 10px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    background: none;
    transition: background 200ms ease-in-out;

    path { transition: stroke 200ms ease-in-out; }

    .lapisMargin {
        margin: 10px 12px 10px 4px;
        transition: color 200ms ease-in-out;
    }

    &.boxOptionsMonitoramento__button {
        font-size: 14px;
        display: flex;
        align-items: center;  
        justify-content: center;    
        // padding: 12px;
     
        &:hover {
            background-color: #A8B0B9;
            color: #fff;
            path { stroke: #fff; }
        }
        &.active {
            background-color: #1B4677;
            color: #fff;
            path { stroke: #fff; }
        }
    }
}

.buttonLapis{
    padding: 6px 6px 8px 10px;
}