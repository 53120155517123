/***
REGRAS GERAIS
***/

.bodyContainer {
    position: relative;
    width: 100%;
}

.bodyWrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #FBFBFB;
}

.container {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
}

/***
BOX CONHEÇA O NOSSO SISTEMA
***/

.boxPrimeiroAcesso {
    width: 40%;
    max-width: 500px; 
    position: relative;
    display: flex;
    align-items: flex-start;
    background-color: #D0E1E9;
    &:after {
        content: "";
        width: 5000px;
        height: 100vh;
        min-height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        background-color: #D0E1E9;
        transform: translate(0, -50%);
        z-index: 0;
    }
}

.boxPrimeiroAcesso__data {
    width: 100%;
    max-width: 380px;
    margin-top: 25vh;
    z-index: 2;
    & img {
        width: 15vw;
        max-width: 220px;
        min-width: 190px;
    }
}

@media screen and (max-width: 3560px){
    .boxPrimeiroAcesso__data { margin-top: 20vh; }
    .boxForm{
        .boxPrimeiroAcesso__dataFaq { margin-top: 20vh; }
    }
}
@media screen and (max-width: 2560px){
    .boxPrimeiroAcesso__data { margin-top: 15vh; }
    .boxForm{
        .boxPrimeiroAcesso__dataFaq { margin-top: 15vh; }
    }
}

.boxPrimeiroAcesso__dataFaq {
    width: 100%;
    max-width: 640px;
    position: relative;
    z-index: 2;
    padding: 0 20px 20px;
    margin-top: 25vh;
}

.boxFaq{
    :global(.accordion) {
        --bs-accordion-active-color: #4F71A6 !important;
        --bs-accordion-btn-focus-border-color: transparent !important;
        --bs-accordion-btn-focus-box-shadow: none !important;
    }
    li{   
        margin-bottom: 14px;
        &:last-child{ margin-bottom: 0; }
        p{
           color: #4F5B67;
           font-size: 14px;
        }

        :global(.accordion-button:not(.collapsed)){
            background-color: #fff;
            box-shadow: 0px 0px 0px #fff;
            padding: 20px 20px 0px 20px;
            background-repeat: no-repeat;
        }
        :global(.accordion-button:not(.collapsed)::after){
            background-image: url(./img/ico/arrow.svg);
            margin-top: -18px;
        }
        :global(.accordion-button::after){
            background-image: url(./img/ico/arrow.svg);
        }
        :global(.accordion-item){
            border: 1px solid #9DCADF80;
        }

        ul.boxFaq__list{
            padding-left: 15px;
            p{
                margin-left: -15px;
            }
            li{
                list-style: disc;
                margin-bottom: 4px;
                color: #4F5B67;
                font-size: 14px;
            }
        }
        ol.boxFaq__listB{
            padding-left: 17px;
            a{
                color: #5D7ABA;
                &:hover{ text-decoration: none;}
            }
            p{
                margin-left: -15px;
            }
            li{
                list-style: number;
                margin-bottom: 4px;
                color: #4F5B67;
                font-size: 14px;
            }
        }
    }
    .boxFaq__title{
        color: #1B4677;
        font-weight: 500;
        font-size: 18px;
    }
}

@media screen and (max-width: 1024px) {
    .boxPrimeiroAcesso__data {
        width: 100%;
        padding: 0;
    }
}

@media screen and (max-width: 1284px) {
    .boxPrimeiroAcesso__data {
        padding: 0px 20px 20px 40px;
        max-width: 420px;
    }

    .boxPrimeiroAcesso__dataFaq {
        max-width: 680px;
        padding: 0 40px 20px;
    }    
}

@media screen and (max-width: 767px) {
    .boxPrimeiroAcesso {
        width: 100%;
        max-width: none;
        padding: 20px 8px 0px;

        &:after { display: none; }
    }

    .boxPrimeiroAcesso__data {
        max-width: none;
        margin-top: 0;
        padding: 0px 0px 20px 0px;
    }
    .boxForm{
        .boxPrimeiroAcesso__dataFaq { padding: 0; margin-top: 0; }
    }
}

/* TÍTULO */

.boxPrimeiroAcesso__title {
    font-weight: 600;
    margin-top: 8px;
    font-size: 36px;
    line-height: 120%;
    color: #1B4677;
    text-align: left;
    max-width: 300px;
    margin-top: 0px;
}

@media screen and (min-width: 769px) and (max-width: 935px) {
    .boxPrimeiroAcesso__title { font-size: 37px;  }
}

@media screen and (max-width: 767px) {
    .bodyWrapper { background-color: #D0E1E9; }
    .boxPrimeiroAcesso__title {
        max-width: none;
    }
}

@media screen and (max-width: 333px) {
    .boxPrimeiroAcesso__title { font-size: 28px; }
}

.boxForm {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .bodyWrapper{
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            justify-content: start;

            .boxForm {
                width: 100%;
                margin-top: 20px;
                padding: 20px 20px 20px 30px;
                border-radius: 6px;
                background-color: #fff;
            }
        }
        .formRow{
            .formRow__footer{
                display: none;
            }
        }

        .formRow__footerB{
            display: block;
            margin-top: 30px;
        }
    }
}

.boxForm__data {
    width: 80%;
    display: flex;
    align-items: center;

    p { margin-bottom: 35px; }   
}

.boxPrimeiroAcesso__form {
    width: 100%;
}

.formRow__login {
    font-size: 14px;
    color: #4F5B67;
    & a {
        font-size: 14px;
        margin-bottom: 8px;
        display: block;
        color: #4F71A6;
        text-decoration: underline;
    }
}

.boxPrimeiroAcesso__subTitle{ margin-bottom: 20px; }

.formRow {
    width: 100%;
    position: relative;

    &+.formRow { margin-top: 25px; }

    .formRow__text {
        color: #4F5B67;
        font-size: 14px;
        margin-top: 16px;
    }
}

/***
FOOTER
***/

.formRow__footer{
    color: #1B4677;
    font-size: 14px;
    display: block;
    margin-top: 250px;
}

.formRow__footerB{
    display: none;
    color: #1B4677;
    font-size: 14px;
}