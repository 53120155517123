.selectItens { padding-top: 11px; }

.select { margin: 5px; }

.boxCards {   
    width: 100%;
    margin-top: 25px;
}

.boxCards__list {
    margin: -7px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 15px;
    & > li { padding: 7px; }
}

.boxCards__card {
    width: 100%;
    min-width: 276px;
    padding: 24px 20px;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #EAECEE;
    background-color: #fff;
    max-width: 400px;

    &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;        
    }
}

.card__name {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #242D35;
    display: block;
}

.card__list {
    width: 100%;
    margin-top: 21px;
    display: grid;
    grid-auto-flow: column;
    margin: 21px auto 0;
    max-width: 400px;
    grid-auto-columns: minmax(0, 1fr);

    li {
        & + li { border-left: 1px solid #F1F1F1; }

        span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #A8B0B9;
            display: block;
        }

        strong {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #373F47;
            display: block;
        }
    }
}

.card__link {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    a {
        padding: 10px 20px;
        font-weight: 500;
        font-size: 14px;
        color: #0C1116;
        display: inline-block;
        border-radius: 32px;
        border: 1px solid #EAECEE;
        background-color: #fff;
        transition: color 200ms ease-in-out, border 200ms ease-in-out, background-color 200ms ease-in-out;

        &:hover {
            text-decoration: none;
            color: #fff;
            border: 1px solid #0C1116;
            background-color: #0C1116;
        }
    }
}