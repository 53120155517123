/* BARRA DE BUSCA */
.boxFormSearch {
    width: 100%;
    max-width: 382px;
    position: relative;
    margin-top: 10px;

    input[type=text] {
        width: 100%;
        height: 42px;
        border-radius: 6px;
        font-size: 16px;
        color: #00000099;
        font-weight: 500;
        border: 1px solid #DBDBDB;
    }
}

.searchIcon {
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
}

input::placeholder {
    color: #A8B0B9;
}

.buscarHome {
    color: #A8B0B9;
    padding: 0 44px 0 12px;
    font-size: 14px;
}

.boxCards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    gap: 18px;
    width: 100%;
    a{
        &:hover{ text-decoration: none; }
    }
}

.boxCards__list {
    background: #FFF;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #EAECEE;
    display: flex;
    justify-items: center;
    align-items: center;
}

.orgao {
    color: #4F5B67;
    display: block;
    font-size: 14px;
    padding: 6px 16px 6px 16px;
}