/* BOX DE TEXTO DE CALENDÁRIO */

.boxCalendar {
    display: flex;
    justify-content: center;
    position: relative;
}

.boxCalendarText {
    display: flex;
    justify-content: center;
    gap: 10px;
    cursor: default;
    border: none;
    background: none;
    
    span {
        padding: 3px 15px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #4F71A6;
        background-color: #fff;
        cursor: pointer;
        display: block;
        border: 2px solid #EFF1F4;
        
        &.boxCalendarText__month {
            padding: 3px 40px;
        }
    }
    
    @media screen and (max-width: 375px) {
        span {
            &.boxCalendarText__month {
                padding: 3px 16px;
            }
        }
    }
}

.boxCalendar__load{
    span {
        color: #5c5d5d7b;
        border: 2px solid #5c5d5d0f;
        background-color: #5c5d5d04;
        &:hover{ cursor: default; }
    }
}

/* NOVO CALENDÁRIO */

.boxNewCalendarBackground {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 9;
}

.boxNewCalendar {
    padding: 20px;
    display: none !important;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    background-color: #fff;
    z-index: 99;

    ul {
        li {
            height: 40px;
            text-align: center;

            button {
                height: 100%;
                padding: 8px 20px;
                font-weight: 400;
                font-size: 14px;
                color: #A8B0B9;
                cursor: pointer;
                background: none;
                transition: font 200ms ease-in-out, color 200ms ease-in-out;
            }

            &.active {
                button {
                    font-weight: 700;
                    font-size: 16px;
                    color: #4F71A6;
                }
            }
        }
    }

    @media screen and (max-width: 410px){
        transform: translate(-50%, -50%) scale(0.85);
    }

    @media screen and (max-width: 352px){
        transform: translate(-50%, -50%) scale(0.75);
    }
}

.boxNewCalendar__boxes {
    display: flex;
    align-items: center;
}

.boxNewCalendar__box {
    position: relative;
    border-radius: 12px;
    background-color: #fff;

    &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        border-radius: 12px;
        background: linear-gradient(0deg, rgba(0, 0, 0, .05) 0%, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.05) 100%);
    }

    &+.boxNewCalendar__box {
        margin-left: 10px;
    }
}

.boxNewCalendar__scroll {
    height: 200px;
    padding: 80px 0;
    overflow-y: auto;
    scrollbar-width: none;
    position: relative;
    border-radius: 12px;
    border: 2px solid #EFF1F4;

    &::-webkit-scrollbar {
        display: none;
    }
}

.boxNewCalendar__block1,
.boxNewCalendar__block2 {
    width: 92%;
    height: 2px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #4F71A6;
    z-index: 2;
}

.boxNewCalendar__block1 {
    top: calc(50% - 19px);
}

.boxNewCalendar__block2 {
    top: calc(50% + 23px);
}

.boxNewCalendar__date ul {
    width: 62px;
}

.boxNewCalendar__month ul {
    width: 120px;
}

.boxNewCalendar__year ul {
    width: 84px;
}

.boxNewCalendar__scrollMonth {
    position: relative;
}

.boxNewCalendar__submit {
    margin-left: 10px;

    input[type=submit] {
        padding: 9px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        border: 1px solid #FD541E;
        border-radius: 34px;
        background-color: #FD541E;
        transition: color 200ms ease-in-out, background 200ms ease-in-out;

        &:hover {
            color: #FD541E;
            background-color: #fff;
        }
    }
}

.boxNewCalendar__submitDisabled {
    margin-left: 10px;

    input[type=submit] {
        padding: 9px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #fff;
        border: 1px solid #5c5d5d;
        border-radius: 34px;
        background-color: #5c5d5d;
    }
}

@media screen and (max-width: 450px) {
    .boxNewCalendar {
        display: block;
    }

    .boxNewCalendar__submit {

        input[type=submit] {
            width: 100%;

            &:hover {
                color: #fff;
                background-color: #FD541E;
            }

            &:active {
                color: #FD541E;
                background-color: #fff;
            }
        }
    }
}