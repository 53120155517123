.boxFormSearchParent {
    display: flex;
    align-items: baseline;
}

.boxFormSearch {
    margin-top: 10px;
    position: relative;

    .boxFormSearch__btn {
        background-color: transparent;
        margin-left: 5px;
    }
}

.inputFormSearch {
    display: flex;
    width: calc(100% - 320px);
    border: 1px solid #DBDBDB;
    width: 100%;
    flex-wrap: wrap;
    padding: 8px 40px 5px 12px;
    border-radius: 6px;
    background-color: #fff;

    input[type=text] {
        color: #373F47;
        font-size: 16px;
        height: 41px;
    }

    .boxFormSearch__span {
        background-color: #E0EFF680;
        display: flex;
        padding: 6px 8px;
        line-height: 200%;
        border-radius: 4px;
        margin: 0 4px 4px 0;
        font-size: 16px;
        color: #373F47;
    }

    .buscarHome {
        flex-grow: 1;
        height: 32px;
        margin-left: 4px;
    }
}

.searchIcon {
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    padding-bottom: 4px;
}

/* BOTÕES DE FILTRO DA LISTAGEM */

.boxOpcoes__filtro {
    margin: -5px;
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    flex-wrap: wrap;

    span {
        margin: 0 10px 0 5px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #000;
        display: block;
    }

    button {
        margin: 5px;
        padding: 4px 6px 3px;
        font-weight: 400;
        font-size: 12px;
        text-align: center;
        color: #7A7A7A;
        border-radius: 4px;
        border: 1px solid #9DC9EA;
        background-color: #fff;
        transition: color 200ms ease-in-out, background-color 200ms ease-in-out, border 200ms ease-in-out;

        &:hover,
        &.active {
            color: #fff;
            border: 1px solid #FD541E;
            background-color: #FD541E;
        }
    }
}

.boxSingle__title {
    padding-right: 10px;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    color: #1B4677;
    display: block;
}

.boxSingle__boxTitle {
    width: 100%;
    padding-bottom: 10px;
    align-items: center;
}

.textEnter {
    padding: 20px 0 14px 0;
    font-size: 14px;
    line-height: 130%;
    display: block;
    color: #717D8A;
}

.borderBusca__bottom {
    border-bottom: 1px solid rgba(157, 202, 223, 0.5);
    display: block;
    position: relative;
    top: 18px;
}

.borderBusca {
    .borderBusca__bottom {
        border-bottom: 1px solid transparent;
    }
}

.singleContainer {
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.filterButton {
    background-color: #6E6EF7;
    color: #ffffff;
    border-radius: 32px;
    font-size: 14px;
    padding-left: 3px;
    text-align: center;
    height: 38px;
    width: 97px;
}

.filterButtonText {
    padding-right: 6px;
}

.boxFormFilter {
    background-color: #DFECF180;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
    gap: 20px;
    padding: 20px;
    margin-top: 18px;
    width: 100%;
}

.boxFormFilterButton {
    svg {
        transition: transform ease-in-out 300ms;
    }
}

.rotacionaIcone {
    svg {
        transform: rotate(180deg);
    }
}

.inputTitle {
    color: #767676;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 8px;
}

.inputContent {
    input::placeholder {
        color: #A8B0B9;
        // padding-left: 6px;
    }
}

.inputSize {
    color: #A8B0B9;
    border: 1px solid #DBDBDB;
    border-radius: 6px;
    font-size: 14px;
    padding-left: 6px;
    height: 41px;
    width: 100%;
}


@media screen and (max-width: 1199px) {
    .boxFormSearch {
        width: 100%;
    }

    .singleContainer {
        flex-direction: column-reverse;
    }
}

.singleContainer__sidebar {
    width: 300px;
    margin-top: 34px;
    border-radius: 8px;
    padding: 20px 8px 20px 20px;
    background: rgba(20, 80, 180, 0.05);
    transition: max-height 400ms ease-in-out, width 400ms ease-in-out;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }
}

@media screen and (max-width: 1199px) {
    .singleContainer__sidebar {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        max-height: 0px;
        width: 0;
        padding: 0;
        overflow: hidden;

        &.active {
            max-height: 420px;
            width: 100%;
        }

        .filterForm__label {
            &.filterForm__labelTypeB {
                margin: 0 0 10px 15px;
            }
        }
    }

    .formRowList {
        width: calc(100% - 30px);
        margin: 0 auto;
        max-height: 290px;
        overflow: auto;
        margin-bottom: 20px;
    }
}


.filterform__simpleFilter,
.filterform__advancedFilter {
    margin-top: 20px;
    display: none;

    &.active {
        display: block;
    }
}

.filterButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
        font-weight: 400;
        font-size: 14px;
        color: #A8B0B9;
        cursor: pointer;
        border: none;
        background: none;

        &.active {
            color: #373F47;
        }
    }
}

@media screen and (max-width: 1199px) {
    .filterButtons {
        display: none;
    }
}

.formRow {
    width: 100%;
    position: relative;

    &+.formRow {
        margin-top: 30px;
    }
}

.formRowCheckbox {
    width: 100%;
    position: relative;

    &+.formRowCheckbox {
        margin-top: 17px;
    }

    &+.formRow {
        margin-top: 30px;
    }
}

.filterForm__inputCheckbox {
    position: absolute;
    left: -99999px;

    &:checked~label {
        &:after {
            border: 2px solid #6E6EF7;
            background-color: #6E6EF7;
        }

        &:before {
            opacity: 1;
        }
    }
}

.filterForm__labelCheckbox {
    padding: 0 0 0 30px;
    font-weight: 400;
    font-size: 14px;
    color: #292929;
    text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
    cursor: pointer;
    display: block;
    position: relative;

    &:after {
        content: "";
        width: 18px;
        height: 18px;
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        border-radius: 4px;
        border: 1px solid #D6DADE;
        background-color: #fff;
        transition: background 200ms ease-in-out, border 200ms ease-in-out;
    }

    &:before {
        content: "";
        width: 14px;
        height: 11px;
        display: block;
        position: absolute;
        top: 5px;
        left: 2px;
        opacity: 0;
        background: url(ve.svg) no-repeat;
        z-index: 2;
        transition: z-index 200ms ease-in-out;
    }
}

.filterForm__label {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #292929;
    display: block;

    &.filterForm__labelTypeB {
        margin: 0 0 10px 0px;
    }
}

.filterForm__inputText {
    width: 100%;
    height: 41px;
    padding: 0 10px;
    font-weight: 500;
    font-size: 14px;
    color: #999;
    line-height: 41px;
    border-radius: 6px;
    border: 1px solid #D6DADE;
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .formRow {
        &+.formRow {
            margin-top: 10px;
        }
    }

    .filterForm__label {
        margin-bottom: 0;
    }
}

.singleContainer {
    width: 100%;
    margin-top: 0px;
    justify-content: space-between;
    align-items: flex-start;
}

@media screen and (max-width: 1199px) {
    .singleContainer {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 767px) {
    .container {
        display: block;
    }
}


.boxSingle__boxInfo {
    width: 100%;
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
}

.boxInfo__time {
    font-weight: 400;
    font-size: 12px;
    color: #A8B0B9;
    display: block;
}

.boxInfo__filterButton {
    font-weight: 400;
    font-size: 14px;
    color: #FD541E;
    display: none;
    border: none;
    background: none;

    & svg {
        margin-left: 10px;
        transition: rotate 200ms ease-in-out;
    }

    & svg.filterActive {
        rotate: 180deg;
    }
}

.formRow__submit {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

@media screen and (max-width: 1199px) {
    .boxInfo__filterButton {
        display: block;
    }

    .formRow__submit {
        margin-bottom: 20px;
        margin-top: 0;
    }

}

@media screen and (max-width: 767px) {
    .boxSingle__boxInfo {
        display: block;
    }
}


/***
 COLUNA PRINCIPAL
 ***/

.sectionResultadoFiltro {
    width: calc(100% - 320px);
}

@media screen and (max-width: 1199px) {
    .sectionResultadoFiltro {
        width: 100%;
    }
}

/* LISTA DE POSTS */

.listPosts {
    width: 100%;

    &>li {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;

        &+li {
            margin-top: 30px;
        }

        &>a {
            text-decoration: none;
        }
    }
}

.blocoDia {
    margin-top: 30px;
    display: grid;
    gap: 15px;
    font-size: 20px;
    color: #1B4677;
    background: none !important;
    border: none !important;
}

.listPosts2 {
    width: 80%;

    &+.listPosts2 {
        margin-top: 15px;
    }

    &>li {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #EAECEE;
        border-bottom: none;
        background: #fff;
        transition: border 200ms ease-in-out;

        &+li {
            margin-top: 15px;
        }

        &>a {
            text-decoration: none;
        }

        .listPosts__activeItem {
            border: 1px solid #1991EB;
            border-radius: 8px;
        }
    }

    &>div {
        &+div {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .listPosts2 {
        width: 85%;
    }
}

@media screen and (max-width: 1070px) {
    .listPosts2 {
        width: 100%;
    }
}

@media screen and (max-width: 425px) {
    .listPosts2 {
        width: 100%;
    }
}

.boxMensagem__busca {
    padding: 25px 20px;
    width: 100%;
    color: #777777;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 40%;
        min-width: 120px;
        max-width: 200px;

    }

    p {
        margin-bottom: 30px;
        max-width: 700px;
    }
}

.mainFeed {
    width: 100%;
    margin-top: -35.5px;
}

.mainFeed__responsive {
    display: none;
}

.mainFeed__responsiveB {
    width: calc(100% - 318px);
    display: flex;
    margin-top: 34px;
    justify-content: space-between;

    div:last-child {
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 1199px) {
    .mainFeed__responsive {
        display: block;
    }

    .mainFeed__responsiveB {
        display: none;
    }
}

@keyframes load {
    0% {
        background-color: #dbdbdbb3;
    }

    50% {
        background-color: #efefefb3;
    }

    100% {
        background-color: #f6f6f6;
    }
}

.feedHeader {
    border-bottom: 1px solid #9dcadf80;
    padding: 20px 0;

    .boxHeader__title {
        display: flex;
        justify-content: space-between;

        .boxHeader__title30,
        .boxHeader__title35 {
            animation: load 700ms linear infinite alternate;
            height: 30px;
            border-radius: 4px;
        }

        .boxHeader__title30 {
            width: 30%;
            min-width: 250px;
        }

        .boxHeader__title35 {
            width: 35px;
        }
    }
}

.esqueletoFeed {
    display: flex;
    justify-content: space-between;
}

.esqueletoFeed__lists {
    width: 100%;
}

.esqueletoFeed__txt110,
.esqueletoFeed__txt70 {
    animation: load 700ms linear infinite alternate;
    border-radius: 3px;
}

.esqueletoFeed__txt110 {
    margin-top: 0px;
    height: 8px;
    width: 110px;
}

.esqueletoFeed__filter {
    animation: load 700ms linear infinite alternate;
    border: 1px solid #EAECEE;
    width: 420px;
    height: 260px;
    border-radius: 8px;
    margin: 15px 0 0 10px;
    //background-color: #Fff;
}

@media screen and (max-width: 1199px) {
    .esqueletoFeed__filter {
        display: none;
    }

    .boxFiltro {
        margin-top: 0px;
    }
}

.boxFiltro {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 5px;
}


.boxFiltro__select {
    height: 29px;
    background: #9DCADF1A;
    border: none;
    border-radius: 4px;
    padding: 4px, 10px, 4px, 10px;
    font-size: 14px;
    color: #717D8A;
}

.boxFiltro__label {
    padding-right: 22px;
    font-size: 14px;
    color: #717D8A;
}

.boxFiltro__form {
    display: flex;
    align-items: center;
    flex-direction: row;

    select {
        padding: 5px 10px;
    }
}

@media screen and (max-width: 471px) {
    .boxFiltro {
        align-items: center;
    }

    .boxFiltro__form {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .boxFiltro__label {
        display: none;
    }
}

//////////
.modalBox__radio {
    input[type=radio]:before {
        content: "";
        top: -15px;
        left: 0;
    }

    input[type=radio]:checked:before {
        content: "";
        top: -15px;
        left: 0;
    }

    input[type=radio]:checked:after {
        content: "";
        top: -12px;
        left: 6.5px;
    }
}

.modalBox__radioRows {
    display: flex;
    flex-wrap: wrap;
}

.modalBox__radioRow {
    margin-right: 16px;

    label {
        padding-left: 28px;
    }
}

.radioRow__wrapper {
    display: flex;
}

.label_PlanoBasico {
    position: relative;
    color: #00000048;
}

// Aba de notícias relacionadas

.container_InformacoesEleicao {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: opacity 300ms ease-in-out;

    .informacoesEleicao__buttons {
        margin: -6px;
        display: flex;
        flex-wrap: wrap;

        .informacoesEleicao__btn {
            padding: 6px;
        }
    }
}

.containerFonteDados {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    transition: opacity 300ms ease-in-out;

    .fonteDados__buttons {
        margin-bottom: -50px;
    }
}

@media screen and (max-width: 953px) {
    .container_InformacoesEleicao {
        flex-direction: column;
    }
}

.informacoesEleicao__buttons {
    width: 100%;
    padding: 0 0 20px 0;

    button {
        background-color: transparent;
        margin-bottom: 10px;
        border: 1px solid #9DC9EA;
        color: #4F71A6;
        font-size: 12px;
        border-radius: 4px;
        padding: 4px 16px;
        margin-right: 15px;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

        &:last-child {
            margin-right: 0;
        }

        &.active {
            background-color: #FD541E;
            color: #fff;
            border: 1px solid #FD541E;
        }
    }
}