/* INFORMAÇÕES DO TOPO */

.boxSingle__boxTitle {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(157, 202, 223, 0.5);

    &.boxSingle__boxTitleB {
        padding-bottom: 15px;
        display: block;
    }
}
.boxSingle__title {
    padding-right: 10px;
    font-weight: 500;
    font-size: 36px;
    line-height: 120%;
    color: #1B4677;
    display: block;
}

/* BARRA DE BUSCA */
.boxFormFilter {
    width: 100%;
    max-width: 742px;
    margin-top: 10px;
    position: relative;

    fieldset {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    select {
        width: 100%;
        height: 38px;
        border-radius: 4px;
        border: 1px solid #DBDBDB;
    }
}
.formRow {
    width: 100%;
    position: relative;
}
.formRowCollumns {
    margin: 10px 0 10px -7px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    input[type=checkbox] { margin-right: 5px; }
}
.formCollumn { margin: 0 7px; }


/* LISTA DE POSTS */

.listPosts {
    margin: 24px 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > li {
        border-radius: 8px;
        border: 1px solid #EAECEE;
        background: #fff;

        & > a { text-decoration: none; }
    }
}

@media screen and (max-width: 1199px) {
    .listPosts { grid-template-columns: 1fr; }
}

// Modal



.modalBox__list {
    width: 100%;
    // border: 1px solid rgb(9, 255, 0);
    margin: -7px;
    ul {
        // margin: -7px;
        display: flex;
        flex-wrap: wrap;
        // border: 1px solid rgb(9, 255, 0);
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(135px, 1fr));
        &>li { padding: 7px; }

        li {
            // margin: 14px 9px;
            // border: 1px solid red;

            label {
                // width: 160px;
                height: 48px;
                font-weight: 500;
                font-size: 14px;
                padding: 0 16px;
                line-height: 100%;
                text-align: center;
                color: #373F47;
                text-align: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #9DC9EA;
                background-color: #fff;
                transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;

                &:hover {
                    border: 1px solid #FD541E;
                    box-shadow: 1px 1px #FD541E inset, -1px -1px #FD541E inset;
                }
            }

            input[type=checkbox] {
                position: absolute;
                left: -99999px;

                &:checked ~ label {
                    border: 1px solid #FD541E;
                    box-shadow: 1px 1px #FD541E inset, -1px -1px #FD541E inset;
                }
            }
        }
    }
}

.boxAlert__Monitoramento {
    margin: 0 20px 20px;
    text-align: center;
}

.alertTxt {
    align-items: center;
    color: #FD541E;
    font-weight: 400;
    text-align: center;
}

.boxButton__cancelar{
    display: flex;
    align-items: center;
    height: 43px;
    background-color:  transparent;
    color: #ff0000;
    font-size: 14px;
}