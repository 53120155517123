.modalBackground {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .25);
    z-index: 999999;
}

.modalBox,
.modalBoxApple {
    width: calc(100% - 40px);
    max-width: 1120px;
    max-height: calc(100% - 40px);
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow-y: auto;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%);
    z-index: 9999999;
    transform: translate(-50%, -50%);

    &>div {
        width: 100%;
        padding: 0;
        overflow: hidden;
    }
}

@media screen and (max-width: 375px) {
    .modalBox__assuntos {
        .modalBox__title { font-size: 32px; }
    }
}

.modalBox__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 120%;
    text-align: center;
    color: #1B4677;
    display: block;
}

.modalBox__title_b{
    padding: 20px;
}

@media screen and (max-width: 400px) {
    .modalBox__title_b { font-size: 28px; }
}

.modalBox__subTitle {
    margin-top: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #4F5B67;
    display: block;

    strong {
        color: #FD541E;
    }
}

.modalBox__maisRegras,
.modalBox__radioRow {
    position: relative;

    button { background-color: transparent; }

    .boxCategoriaOptions__btnPro {
        top: -17px;
        right: -20px;
    }
}

.subTitle__tooltip {
    max-width: 820px;
}

.modalBox__buttonBox_typeB {
    margin-left: 25px;
    display: flex;
    align-items: center;
}

.modalBox__finalBox_wrapper{
    overflow: auto;
    max-height: calc(100vh - 250px);
    padding: 37px 35px 0px 35px;
}

.modalBox__buttonBox_typeC {
    display: flex;
    left: -35px;
    width: calc(100% + 70px);
    position: relative;
    padding: 12px 65px;
    flex-direction: row-reverse;
    justify-content: space-between;
    box-shadow: 0px -8px 9px rgba(85, 85, 85, 0.04);
    z-index: 6;

    &::after{
        content: "";
        position: absolute;
        background-color: #fff;
        height: 300%;
        width: 150%;
        top: 0px;
        left: -36px;
        z-index: -1;
    }
}

@media screen and (max-width: 365px) {
    .modalBox__typeCResponsive {
        display: flex;
        flex-direction: column;

        & button:first-child {
            margin-bottom: 12px;
        }
    }
}

.modalBox__responsiveButtons{
   box-shadow: 0px -8px 9px rgba(85, 85, 85, 0.044);
   position: relative;
   padding: 12px 32px;
   width: 100%;

   &::after{
    content: "";
    position: absolute;
    background-color: #fff;
    height: 300%;
    width: 200%;
    top: 0px;
    left: -70px;
    z-index: -1;
   }
}

.modalBox__assuntosMain{
    padding: 35px 35px 30px 35px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
}

.modalBox__assuntosMain_categoria{
    padding: 35px 25px 20px 35px;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: hidden;
    .modalBox__title, .modalBox__subTitle{
        padding-right: 20px;
    }
}

.modalBox__buttonBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
}

@media screen and (max-width: 644px) {
    .modalBox__assuntosMain_categoria{
        padding: 35px 25px 20px 40px;
    }
}

@media screen and (max-width: 767px) {
    .modalBox__form {
        .modalBox__radio { margin-right: 0; }
    }
}

@media screen and (max-width: 375px) {
    .modalBox__assuntosMain { padding: 20px; }

    .modalBox__assuntosMain_categoria{
        padding: 30px 5px 20px 20px;
        .modalBox__title, .modalBox__subTitle{
            padding-right: 15px;
        }
    }
}

@media screen and (max-width: 355px) {
    .modalBox__buttonBox {
        flex-direction: column-reverse;

        .buttonCancelar__modalB { margin: 0 0 15px 0; }
    }
}

/* LISTA DO PRIMEIRO PASSO DA MODAL */
.modalBox__listA {
    width: 100%;
    margin-top: 24px;

    ul {
        margin: -8px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));

        li {
            padding: 8px;

            label {
                width: 100%;
                height: 100%;
                min-height: 81px;
                padding: 42px 10px 10px;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                text-align: center;
                color: #373F47;
                text-align: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                border-radius: 8px;
                border: 1px solid #9DC9EA;
                background-color: #fff;
                transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;

                span {
                    height: 100%;
                    min-height: 27px;
                    display: block;
                }

                &:hover {
                    border: 1px solid #FFCF0F;
                    box-shadow: 1px 1px #FFCF0F inset, -1px -1px #FFCF0F inset;
                }
            }

            input[type=checkbox] {
                position: absolute;
                left: -99999px;

                &:checked~label {
                    border: 1px solid #FD541E;
                    box-shadow: 1px 1px #FD541E inset, -1px -1px #FD541E inset;
                }
            }
        }
    }
}

.listA__icon1 label {  background: #fff url(./img/ico/agricultura.svg) center 9px no-repeat; }
.listA__icon2 label { background: #fff url(./img/ico/assistencia_social.svg) center 13px no-repeat; }
.listA__icon3 label { background: #fff url(./img/ico/cargas_transportes.svg) center 13px no-repeat; }
.listA__icon4 label { background: #fff url(./img/ico/ciencia.svg) center 9px no-repeat; }
.listA__icon5 label { background: #fff url(./img/ico/consultas.svg) center 11px no-repeat; }
.listA__icon6 label { background: #fff url(./img/ico/controle.svg) center 12px no-repeat; }
.listA__icon7 label { background: #fff url(./img/ico/cultura.svg) center 13px no-repeat; }
.listA__icon8 label { background: #fff url(./img/ico/direitos_humanos.svg) center 12px no-repeat; }
.listA__icon9 label { background: #fff url(./img/ico/economia.svg) center 15px no-repeat; }
.listA__icon10 label { background: #fff url(./img/ico/educacao.svg) center 13px no-repeat; }
.listA__icon11 label { background: #fff url(./img/ico/energia.svg) center 12px no-repeat; }
.listA__icon12 label { background: #fff url(./img/ico/infraestrutura.svg) center 13px no-repeat; }
.listA__icon13 label { background: #fff url(./img/ico/licitacoes.svg) center 9px no-repeat; }
.listA__icon14 label { background: #fff url(./img/ico/meio_ambiente.svg) center 13px no-repeat; }
.listA__icon15 label { background: #fff url(./img/ico/orcamento.svg) center 10px no-repeat; }
.listA__icon16 label { background: #fff url(./img/ico/relacoes_internacionais.svg) center 10px no-repeat; }
.listA__icon17 label { background: #fff url(./img/ico/saude.svg) center 10px no-repeat; }
.listA__icon18 label { background: #fff url(./img/ico/seguranca.svg) center 10px no-repeat; }

@media screen and (max-width: 767px) {
    .modalBox__listA {
        ul {
            li {
                label {
                    &:hover {
                        border: 1px solid #9DC9EA;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

/* LISTA DO SEGUNDO PASSO DA MODAL */
.ball__check {
    width: 15px;
    min-width: 15px;
    height: 15px;
    // top: 20px;
    left: 8px;
    position: relative;
    background-color: #06BE68;
    border-radius: 100%;
    z-index: 0;
}

.ball__check::after {
    content: "";
    width: 10px;
    height: 5px;
    top: 3.5px;
    left: 2.5px;
    display: block;
    position: absolute;
    align-items: center;
    border: 1.5px solid;
    border-color: #FFFFFF #FFFFFF transparent transparent;
    transform: rotate(140deg);
}

.modalBox__listB,
.modalBox__listC {
    width: 100%;
    margin-top: 28px;

    ul {
        li {
            padding: 0 8px;

            label {
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                text-align: center;
                color: #373F47;
                text-align: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                border: 1px solid #9DC9EA;
                background-color: #fff;
                transition: border 200ms ease-in-out, box-shadow 200ms ease-in-out;
            }

            input[type=checkbox] {
                position: absolute;
                left: -99999px;
            }
        }
    }
}

.modalBox__listB {
    ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(172px, 1fr));
        margin: -8px;

        li {
            label {
                &:hover {
                    border: 1px solid #FFCF0F;
                    box-shadow: 1px 1px #FFCF0F inset, -1px -1px #FFCF0F inset;
                }

                input[type=checkbox] {
                    &:checked~label {
                        border: 1px solid #FD541E;
                        box-shadow: 1px 1px #FD541E inset, -1px -1px #FD541E inset;
                    }
                }
            }
        }
    }
}

.modalBox__listC_label {
    min-height: 60px;
    padding: 0 10px;
}

.modalBox__listC_labelActive {
    border: 2px solid #9DC9EA !important;
}

.modalBox__listC_labelBlock {
    border: 1px solid #9dc9ea54 !important;
    color: #373f4754 !important;
}

.modalBox__listC_labelBlockIconPro {
    position: absolute;
    width: 39px;
    top: 0;
    right: 0;
    border: 1px solid #5d7abad7;
    border-radius: 15px;
    background: #5d7abad7;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
}

.ministerios__li {
    ul {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

        li {
            label {
                min-height: 60px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .modalBox__listB {
        ul {
            li {
                label {
                    &:hover {
                        border: 1px solid #9DC9EA;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

.modalBox__loading {
    min-height: 200px;
    display: none;

    &.active {
        display: block;
    }

    .loader {
        position: absolute;
        display: block;
        z-index: 1000;
        top: 50%;
        right: 50%;

        .outer,
        .middle,
        .inner {
            border: 3px solid transparent;
            border-top-color: #004273;
            border-right-color: #004273;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
        }

        .outer {
            width: 3.5em;
            height: 3.5em;
            margin-left: -1.75em;
            margin-top: -1.75em;
            animation: spin 2s linear infinite;
        }

        .middle {
            width: 2.1em;
            height: 2.1em;
            margin-left: -1.05em;
            margin-top: -1.05em;
            animation: spin 1.75s linear reverse infinite;
        }

        .inner {
            width: 0.8em;
            height: 0.8em;
            margin-left: -0.4em;
            margin-top: -0.4em;
            animation: spin 1.5s linear infinite;
        }

        @keyframes spin {
            to {
                transform: rotate(360deg);
            }
        }
    }
}

.modalBox__assuntos {
    width: 100%;
    padding: 0;
    display: none;
    background-color: #fff;

    &.active {
        display: block;
    }
}

.modalBox__comissoes {
    width: 100%;
    padding: 0;
    display: none;
    background-color: #fff;

    &.active {
        display: block;
    }
}

.modalBox__categorias {
    width: 100%;
    padding: 0;
    display: none;
    background-color: #fff;

    &.active {
        display: block;
    }
}

.modalBox__parabens {
    width: 100%;
    padding: 0;
    display: none;
    background-color: #fff;

    &.active {
        display: block;
    }
}

/* TELA FINAL */
.finalBox__title {
    margin-top: 18px;

    input[type=text] {
        width: 100%;
        max-width: 380px;
        height: 40px;
        margin: 0 auto;
        padding: 0 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 40px;
        color: #999999;
        display: block;
        border-radius: 6px;
        border: 1px solid #D6DADE;
        background-color: #fff;
    }
}

.finalBox__resumoBtn {
    display: flex;
    align-items: center;
    background-color: transparent;

    svg {
        transition: transform 400ms ease-in-out;
        margin-left: 10px;
        width: 18px;
    }

    svg.finalBox__activeResumo {
        transform: rotate(180deg);
    }
}

.finalBox__main {
    margin-top: 30px;
    padding-top: 24px;
    display: flex;
    border-top: 1px solid #D0E1E9;
}

.finalBox__collumn {
    width: 100%;
}

.finalBox__collumnA {
    width: 100%;
    max-width: 140px;
}

.finalBox__summary {
    font-weight: 500;
    font-size: 14px;
    color: #000;
    display: block;
}

.finalBox__aligner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 0;
}

.finalBox__term {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    padding-top: 6px;
    color: #717D8A;
    display: block;
}

.finalBox__ou {
    font-weight: 500;
    font-size: 14px;
    color: #717D8A;
    display: flex;
    align-items: end;
    height: 27px;
}

.finalBox__list {
    width: 100%;
    margin: 0 -5px;
    display: flex;
    flex-wrap: wrap;

    li {
        margin: 5px;
        padding: 2px 5px;
        font-weight: 400;
        font-size: 12px;
        color: #4F71A6;
        border-radius: 4px;
        border: 1px solid #9DC9EA;
    }
}

.finalBox__assunto {
    display: block;
}

@media screen and (max-width: 767px) {
    .finalBox__aligner {
        display: block;
    }

    .finalBox__list {
        margin: 5px -5px -5px;
    }
}

@media screen and (max-width: 500px) {
    .finalBox__main {
        display: block;
    }
}

/* MENSAGENS DE ERRO */
.modalBox__errorBox {
    width: 100%;
    margin-top: 16px;
    padding: 0 20px;
    text-align: center;
    display: none;

    &.active {
        display: block;
    }
}

.modalBox__errorMessage {
    padding: 5px 10px;
    font-weight: 700;
    font-size: 16px;
    color: #C21700;
    text-align: center;
    display: inline-block;
}

.buttonCancelar__modal,
.buttonCancelar__modalB {
    background-color: transparent;
    align-items: center;
    color: #DB302A;
    height: 20px;
}

.buttonCancelar__modal {
    margin: 25px 0px 0px;
}

.buttonCancelar__modalB {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .buttonCancelar__modal {
        display: none;
    }
}

.inputModal_typeB {
    flex-grow: 1;
    flex-wrap: wrap;
}

.inputModal_typeC {
    margin: 25px 0 0 0;
}

@media screen and (max-width: 815px) {
    .inputModal_typeC {
        margin: 10px 0 0 0;
    }
}

.modalBox__listA_form {
    display: flex;
    flex-direction: column;
}

.focus {
    border: 1px solid #FD541E !important;
    transition: border 200ms ease-in-out;
}

.inputModal {
    border: 1px solid #D6DADE;
    padding: 12px 8px 9px 8px;
    border-radius: 6px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    span {
        background-color: #E0EFF680;
        display: block;
        padding: 3px 8px;
        border-radius: 4px;
        margin: 0 4px 4px 0;
    }

    .inputModal_btn {
        background-color: transparent;
        margin-left: 5px;
    }

    .inputModal__spanBetween {
        background-color: transparent;
        color: #717D8A;
    }
}

.mensagem_planoBasico {
    color: #C21700;
    font-size: 14px;
    display: block;
    margin: 12px 0;
}

.modalBox__txt {
    color: #717D8A;
    font-size: 14px;
    display: block;
    margin: 12px 0;
}

.modalBox__txt_box {
    color: #717D8A;
    display: flex;
    margin: 0px 120px 25px 80px;
    background: #F7F7F7;
    border-radius: 8px;
    padding: 30px 40px 24px 30px;
    gap: 20px;

    .modalBox__txt_list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .modalBox__txt_itens {
            font-size: 14px;

            &>span {
                font-weight: 600;
            }
        }
    }
}

.modalBox__tooptip {
    position: relative;
    display: flex;
    justify-content: center;
}

.iconInfo {
    position: absolute;
    right: 35px;
    bottom: 0;
    display: flex;
    min-height: 25px;
    height: 25px;
    width: 25px;
    padding: 8px, 0px, 8px, 0px;
    border: solid 2px #B0BEC5;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: #B0BEC5;
}

@media screen and (max-width: 1035px) {
    .iconInfo {
        right: 0px;
        top: 50%;
        transform: translateY(-10%);
    }

    .subTitle__tooltip {
        padding: 0 35px;
    }

    .modalBox__assuntos {

        .modalBox__tooltip,
        .modalBox__tooltipActive {
            right: 38px;
        }
    }
}

@media screen and (max-width: 1100px) {
    .modalBox__txt_box {
        display: flex;

        .iconInfo {
            height: 25px;
            width: 25px;
            min-width: 25px;
        }
    }
}

@media screen and (max-width: 767px) {
    .modalBox {
        .modalBox__txt_box {
            margin: 0px;
        }
    }
}

@media screen and (max-width: 520px) {
    .modalBox__txt_box {
        padding: 20px;
    }
}

@media screen and (max-width: 410px) {
    .modalBox__txt_box {
        display: flex;
        flex-direction: column;
        // align-items: center;
    }
}

.modalBox__txtTypeB {
    text-align: center;
    margin-top: 25px;
}

.modalBox__btnAssuntos {
    border-radius: 30px;
    background-color: #4F71A6;
    color: #fff;
    padding: 4px 15px;
    margin: 15px 0;

    svg {
        margin-left: 6px;
    }
}

.modalBox__assuntosWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 767px) {
    .modalBox__radioApple {
        input[type=radio]:before {
            content: "";
            top: 2px;
            left: 0;
        }

        input[type=radio]:checked:before {
            content: "";
            top: 2px;
            left: 0;
        }

        input[type=radio]:checked:after {
            content: "";
            top: 4px;
            left: 6.5px;
        }

        .modalBox__radioRow {
            display: flex;

            label {
                padding: 0 0 8px 10px;
            }
        }
    }
}

.modalBox__radio {
    input[type=radio]:before {
        content: "";
        top: -15px;
        left: 0;
    }

    input[type=radio]:checked:before {
        content: "";
        top: -15px;
        left: 0;
    }

    input[type=radio]:checked:after {
        content: "";
        top: -12px;
        left: 6.5px;
    }
}

@media screen and (max-width: 375px) {
    .modalBox__radioApple {
        margin-right: 0;

        .modalBox__responsiveRadio {
            label {
                padding: 0 0 0 15px;
                margin: 0;
            }
        }
    }
}

.modalBox__radio,
.modalBox__radioApple {
    font-size: 14px;
    color: #373F47;
    display: flex;
    padding: 24px 0 0 0;
    justify-content: space-between;

    p {
        display: flex;
        align-items: center;
        margin-right: 16px;
    }

    input[type=radio] {
        position: absolute;
        cursor: pointer;
        appearance: none;
        position: relative;
        border: none;
    }

    input[type=radio]:before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        border: 2px solid #b4b4b4;
        background-color: #fff;
        border-radius: 50%;
    }

    input[type=radio]:checked:before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        background: #4040F2;
        border-radius: 50%;
        border: none;
    }

    input[type=radio]:checked:after {
        content: "";
        width: 5px;
        height: 10px;
        border: 1px solid white;
        border-width: 0 1.5px 1.5px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;

    }
}

.modalBox__radioRows {
    display: flex;
    flex-wrap: wrap;
}

.modalBox__radioRow {
    margin-right: 16px;

    label {
        padding-left: 28px;
    }
}

.modalBox__regras {
    color: #5D7ABA;
    white-space: nowrap;
    background-color: transparent;
    position: relative;

    &>.iconPro2 {
        position: absolute;
        width: 39px;
        top: -18px;
        right: -22px;
        border: 1px solid #5d7abad7;
        border-radius: 15px;
        background: #5d7abad7;
        color: #FFFFFF;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }
}

.btn_planoBasico {
    background-color: transparent;

    &:hover {
        cursor: pointer;
    }
}

.label_PlanoBasico {
    position: relative;
    color: #00000048;
}

.iconPro1 {
    position: absolute;
    width: 39px;
    top: -17px;
    right: -25px;
    border: 1px solid #5d7abad7;
    border-radius: 15px;
    background: #5d7abad7;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.radioRow__wrapper {
    display: flex;
}

@media screen and (max-width: 815px) {

    .modalBox__radio,
    .modalBox__radioApple {
        display: block;
    }

    .modalBox__regras {
        padding: 8px 0 0 0;
    }

    .modalBox__regras {
        &>.iconPro2 {
            top: 0px;
            right: -42px;
        }
    }

    .iconPro1 {
        top: -18px;
        right: -15px;
    }

    .modalBox__maisRegras {
        .boxCategoriaOptions__btnPro {
            top: 2px;
            left: 82px;
        }
    }
}

@media screen and (max-width: 730px) {

    .modalBox__radio,
    .modalBox__radioApple {
        p {
            padding: 0 0 10px 0;
        }
    }
}

@media screen and (max-width: 767px) {
    .radioRow__wrapper { flex-direction: row; }
}

@media screen and (max-width: 604px) {
    .radioRow__wrapper {
        flex-direction: column;
    }

    .modalBox__radioRows { display: block; }

    .modalBox__radioRow {
        margin-right: 0px;

        label { padding: 0 0 8px 28px; }
    }

    .modalBox__regras {
        &>.iconPro2 {
            top: 0px;
            right: -42px;
        }
    }

    .iconPro1 {
        top: -8px;
        right: -40px;
    }


    .modalBox__radioRow {
        .boxCategoriaOptions__btnPro {
            top: -6px;
            right: -45px;
        }
    }

}

@media screen and (max-width: 520px) {
    .modalBox__radio {
        display: block;
    }
}

.modalBox__tooltip,
.modalBox__tooltipB,
.modalBox__tooltipActive,
.modalBox__tooltipActiveB,
.tooltipActive__right,
.tooltip__right,
.tooltipAutarquia,
.tooltipActive__rightMobile,
.tooltipActive__rightB {
    position: absolute;
    background-color: #fff;
    box-shadow: #32326926 0px 2px 5px 0px, #0000000d 0px 1px 1px 0px;
    border-radius: 6px;
    font-size: 14px;
    color: #4F5B67;
}

.modalBox__tooltip,
.modalBox__tooltipActive {
    right: 72px;
    top: 10px;
    max-width: 400px;
    padding: 16px;
    z-index: 99;

    span { font-weight: 700; }

    p:last-child {
        margin-top: 8px;
    }
}

.modalBox__tooltipB,
.modalBox__tooltipActiveB {
    min-width: 230px;
    width: 100%;
    display: flex;
    padding: 10px;
    left: 100px;
    top: 0px;
    z-index: 999;
}

.modalBox__tooptipB {
    .tooltipAutarquia {
        width: 224px;
        padding: 10px;
        left: 0;
        top: 40px;
        z-index: 999;
    }

    .tooltipActive__right,
    .tooltip__right {
        width: 270px;
        padding: 10px;
        left: 0;
        top: 40px;
        z-index: 999;
    }

    .tooltipActive__rightMobile {
        padding: 10px;
        left: 0;
        top: 40px;
        z-index: 99999;
    }

    .tooltipActive__rightB {
        width: 240px;
        padding: 10px;
        left: -20px;
        top: 40px;
        z-index: 999;
    }
}

@media screen and (max-width: 420px) {
    .modalBox__assuntos {

        .modalBox__tooltip,
        .modalBox__tooltipActive {
            right: 0;
            top: 60px;
        }
    }
}

.modalBox__listApple {
    .boxCategoriaOptions {
        margin: 16px 0 0px 0;

        .boxCheckbox {
            input[type=checkbox]:before {
                left: -15px;
            }

            input[type=checkbox]:checked:before {
                left: -15px;
            }

            input[type=checkbox]:checked:after {
                left: -13px;
            }

            &>div {
                padding-left: 24px;
            }
        }

        .boxCheckbox__list {
            &>li {
                padding: 12px 0 12px 20px !important;
            }
        }

    }

    .activeSubCategoria {
        margin: 0px 0 12px 0;
    }
}

.checkBoxAll {
    .boxCheckbox {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        &>div {
            height: 100%;
            display: flex;
            min-height: 45px;
            align-items: center;
            padding-left: 32px;
            min-width: 160px;
        }

        &>label {
            position: relative;
            border: none;
            color: #292929;
            font-size: 14px;
            text-align: left;
            justify-content: left;
            left: 30px;

            &:hover {
                border: none;
                box-shadow: none;
            }
        }

        input[type=checkbox] {
            cursor: pointer;
            appearance: none;
            position: relative;
            border: none;
            left: 30px;

            &:checked~label {
                border: none;
                box-shadow: none;
            }
        }

        input[type=checkbox]:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: 50%;
            transform: translateY(-50%);
            left: -25px;
            border: 1.5px solid #b4b4b4;
            background-color: #fff;
            border-radius: 3px;
        }

        input[type=checkbox]:checked:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: 50%;
            transform: translateY(-50%);
            left: -25px;
            background: #4040F2;
            border-radius: 3px;
            border: none;
        }

        input[type=checkbox]:checked:after {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: 1px solid white;
            border-width: 0 1.5px 1.5px 0;
            rotate: 45deg;
            -webkit-transform: translateY(calc(-50% - 2px));
            -ms-transform: translateY(calc(-50% - 2px));
            transform: translateY(calc(-50% - 2px));
            position: absolute;
            top: calc(50% - 2px);
            left: -23px;
        }
    }
}

.modalBox__listC {
    overflow-y: hidden;
    padding: 10px 16px 0 0px;
    margin-top: 14px;
    ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 0px -8px -8px -8px;

        li {
            label {
                font-size: 16px;
            }
        }
    }

    .boxCategoriaOptions {
        z-index: 2;

        // .boxCheckbox__listNoticias {
        //     margin: 20px 0 0 0;
        // }

        .boxCheckbox__uncheck {
            label {
                text-decoration: underline;
            }

            input[type=checkbox] {
                opacity: 0;
                margin-right: -27px;
            }
        }

        .boxCheckbox__list {
            display: grid;
            margin: 0 0 20px 4px;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

            &>li {
                align-items: center;
                display: flex;
                padding: 12px 0 12px 27px;
                position: relative;
            }

            label {
                line-height: 140%;
                padding-right: 15px;
                font-weight: 400;
            }
        }

    }

    .boxCategoriaOptions__btn,
    .boxCategoriaOptions__blocked {
        padding: 0px 10px;
        height: 55px;
        color: #373F47;
        font-weight: 500;
        text-align: center;
        display: flex;
        justify-content: center;
        // flex-direction: column;
        align-items: center;
        border-radius: 6px;
        border: 1px solid #9DC9EA;
        background-color: #fff;
        width: 100%;
        transition: border 200ms ease-in-out;
    }

    .boxCategoriaOptions__btnActive {
        border: 2px solid #9DC9EA;
        transition: border 200ms ease-in-out;
    }

    .boxCategoriaOptions__blocked {
        border: 1px solid #9dc9ea61;
        color: #93939390;
        margin-bottom: 18px;
    }
}

@media screen and (max-width: 629px) {
    .modalBox__listC {
        ul {
            grid-template-columns: 1fr;
        }

        .boxCategoriaOptions {
            width: 100%;
            transform: translateX(0);

            &:before {
                left: 50%;
                translate: -50%;
                top: -6px;
            }

            .boxCategoriaOptions__header {
                & button {
                    display: block;
                }
            }
        }

        :global(.accordion-body) {
            width: 100%;
        }
    }
}

@media screen and (max-width: 473px) {
    .modalBox__listC {
        .boxCategoriaOptions {
            .boxCheckbox__uncheck {
                input[type=checkbox] {
                    margin-right: 0px;
                }
            }
        }
    }
}

.boxCategoriaOptions__wrapper {
    position: relative;

    .boxCategoriaOptions__blocked {
        position: relative;
    }

}

.boxCategoriaOptions__btnPro {
    background-color: #768fc5;
    color: #fff;
    border-radius: 40px;
    width: 40px;
    height: 20px;
    font-size: 12px;
    position: absolute;
    right: -8px;
    top: -10px;
    z-index: 4;
}

.boxCategoriaOptions__even {
    transform: translateX(calc(-50% - 7px));

    &::before {
        left: 75%;
        translate: -50%;
    }
}

.boxCategoriaOptions__odd {
    &::before {
        left: 23%;
        translate: -50%;
    }
}

.boxCategoriaOptions {
    padding: 0 20px;
    border: 2px solid #9DC9EA;
    border-radius: 6px;
    background-color: #fff;
    z-index: 2;
    width: calc(200% + 15px);
    position: relative;
    margin: 18px 0 16px 0px;

    // transition: height 400ms ease-in-out, width 400ms ease-in-out !important;
    &::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: -6px;
        background-color: #fff;
        border: 2px solid;
        border-color: transparent transparent #9DC9EA #9DC9EA;
        transform: rotate(133deg);
    }

    .boxCategoriaOptions__header {
        width: 40%;
        position: relative;
        margin: 20px 0 16px 0;
        border-bottom: 1px solid #0000001a;

        & button {
            background-color: transparent;
            font-size: 14px;
            color: #FD541E;
            position: absolute;
            right: 0px;
            top: -6px;
            display: none;
        }
    }

    @media screen and (max-width: 566px) {
        .boxCategoriaOptions__header {
            padding: 16px 0 0 0;
        }
    }

    .boxCheckbox {
        display: flex;
        flex-wrap: wrap;

        &>div {
            height: 100%;
            display: flex;
            min-height: 45px;
            align-items: center;
            padding-left: 32px;
            min-width: 160px;
        }

        label {
            border: none;
            color: #292929;
            font-size: 14px;
            text-align: left;
            justify-content: left;

            &:hover {
                border: none;
                box-shadow: none;
            }
        }

        input[type=checkbox] {
            cursor: pointer;
            appearance: none;
            position: relative;
            border: none;
            left: 0;

            &:checked~label {
                border: none;
                box-shadow: none;
            }
        }

        input[type=checkbox]:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: 50%;
            transform: translateY(-50%);
            left: -25px;
            border: 1.5px solid #b4b4b4;
            background-color: #fff;
            border-radius: 3px;
        }

        input[type=checkbox]:checked:before {
            content: "";
            display: block;
            position: absolute;
            width: 18px;
            height: 18px;
            top: 50%;
            transform: translateY(-50%);
            left: -25px;
            background: #4040F2;
            border-radius: 3px;
            border: none;
        }

        input[type=checkbox]:checked:after {
            content: "";
            display: block;
            width: 5px;
            height: 10px;
            border: 1px solid white;
            border-width: 0 1.5px 1.5px 0;
            rotate: 45deg;
            -webkit-transform: translateY(calc(-50% - 2px));
            -ms-transform: translateY(calc(-50% - 2px));
            transform: translateY(calc(-50% - 2px));
            position: absolute;
            top: calc(50% - 2px);
            left: -23px;
        }
    }
}

@media screen and (max-width: 555px) {
    .boxCategoriaOptions {
        .boxCheckbox {
            &>div {
                min-height: 30px;
                height: 36px;
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .boxCategoriaOptions {
        width: calc(100% - 16px);
    }
}

@media screen and (max-width: 757px) {
    .boxCategoriaOptions {
        .boxCategoriaOptions__header {
            width: 100%;

            .boxCategoriaOptions__headerContent {
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
        }
    }
}

@media screen and (max-width: 1158px) {
    .boxCategoriaOptions {
        .boxCategoriaOptions__header {
            width: 50%;
        }
    }
}

@media screen and (max-width: 958px) {
    .boxCategoriaOptions {
        .boxCategoriaOptions__header {
            width: 66%;
        }
    }
}

@media screen and (max-width: 758px) {
    .boxCategoriaOptions {
        .boxCategoriaOptions__header {
            width: 100%;
        }
    }
}

.boxCategoriaOptions {
    .boxCategoriaOptions__headerContent {
        display: flex;

        &>div {
            width: 50%;
        }
    }
}

.boxCategoriaOptions__background {
    // background-color: #b7121259;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2;
}

.hiddenElement {
    display: none !important;
}

button.iconInfo__categorias {
    margin-left: 16px;
    display: flex;
    min-height: 18px;
    height: 18px;
    width: 18px;
    padding: 8px, 0px, 8px, 0px;
    border: solid 1.5px #B0BEC5;
    background-color: transparent;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: #B0BEC5;
}

.boxCategoriaOptions__body {
    max-height: 0px;
    opacity: 0;
    visibility: hidden;
    transition: max-height 500ms ease-in-out, opacity 500ms ease-in-out, margin 500ms ease-out;
}

.activeSubCategoria {
    transition: max-height 500ms ease-in-out, opacity 500ms ease-in-out, margin 500ms ease-out;
    opacity: 1;
    visibility: visible;
    max-height: 1000px;
    overflow: visible;
}

@media screen and (max-width: 767px) {
    .activeSubCategoria {
        max-height: 5000px;
    }
}

.finalBox__alerts {
    margin-bottom: 20px;

    .boxCheckbox {
        padding-left: 35px;
        margin: 20px 0 16px 0;
    }

    .boxRadio {
        display: flex;
        justify-content: space-between;
        background-color: #F7F7F7;
        border-radius: 6px;
        width: 100%;
        max-width: 400px;
        max-height: 0px;
        overflow: hidden;
        transition: max-height 250ms ease-in-out;

        &>div {
            &:first-child {
                margin-left: 45px;
            }

            margin: 16px 20px;
        }

        input[type=radio]:before {
            border-radius: 50%;
        }

        input[type=radio]:checked:before {
            border-radius: 50%;
        }
    }

    .boxRadioActive {
        max-height: 150px;
    }

    label {
        border: none;
        color: #292929;
        font-size: 14px;
        text-align: left;
        justify-content: left;

        &:hover {
            border: none;
            box-shadow: none;
            cursor: pointer;
        }
    }

    input[type=checkbox],
    input[type=radio] {
        cursor: pointer;
        appearance: none;
        position: relative;
        border: none;
        left: 0;

        &:checked~label {
            border: none;
            box-shadow: none;
        }
    }

    input[type=checkbox]:before,
    input[type=radio]:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 45%;
        transform: translateY(-70%);
        left: -25px;
        border: 1.5px solid #b4b4b4;
        background-color: #fff;
        border-radius: 3px;
    }

    input[type=checkbox]:checked:before,
    input[type=radio]:checked:before {
        content: "";
        display: block;
        position: absolute;
        width: 18px;
        height: 18px;
        top: 45%;
        transform: translateY(-70%);
        left: -25px;
        background: #4040F2;
        border-radius: 3px;
        border: none;
    }

    input[type=checkbox]:checked:after,
    input[type=radio]:checked:after {
        content: "";
        display: block;
        width: 5px;
        height: 10px;
        border: 1px solid white;
        border-width: 0 1.5px 1.5px 0;
        rotate: 45deg;
        -webkit-transform: translateY(calc(-50% - 2px));
        -ms-transform: translateY(calc(-50% - 2px));
        transform: translateY(calc(-50% - 2px));
        position: absolute;
        top: calc(70% - 5px);
        left: -23px;
    }
}

.finalBox__alertApple {

    .boxCheckbox {
        padding-left: 25px !important;

        input[type=checkbox]:before {
            top: 55%;
            transform: translateY(-50%);
            left: -15px;
        }

        input[type=checkbox]:checked:before {
            top: 55%;
            transform: translateY(-50%);
            left: -15px;
        }

        input[type=checkbox]:checked:after {
            transform: translateY(calc(-50% - 2px));
            top: calc(75% - 5px);
            left: -13px;
        }
    }

    .boxRadio {
        &>div {
            &:first-child {
                margin: 20px 10px 16px 26px !important;
            }

            margin: 0px 10px 16px 26px !important;
        }

        input[type=radio] {
            left: 2px;
        }

        input[type=radio]:before {
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
        }

        input[type=radio]:checked:before {
            top: 50%;
            transform: translateY(-50%);
            left: -15px;
        }

        input[type=radio]:checked:after {
            top: calc(70% - 4px);
            left: -13px;
        }


    }
}

@media screen and (max-width: 630px) {
    .finalBox__alerts {
        .boxRadio {
            flex-direction: column;
            align-items: baseline;

            &>div {
                &:last-child {
                    margin: 0px 20px 20px 12px;

                }
            }
        }
    }
}

@media screen and (max-width: 438px) {
    .finalBox__alerts {
        .boxRadio {
            label {
                padding-right: 20px;
            }
        }
    }
}